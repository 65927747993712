import { useState, useEffect } from "react";
import Layout from "Reusable/Tutor/Layout";
import profileClasses from "./profile.module.scss";
import user from "Assets/square-user.jpg";
import { GrMail } from "react-icons/gr";
import { FaPhone } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { useNavigate } from "react-router";
import pdf from "Assets/pdf.png";
import { Services } from "Service/service";
import SkeletonLoad from "./Skeleton";
function Profile() {
    const Navigate = useNavigate();
    const [data, setdata] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    useEffect(() => {
        TutorDetails();
    }, []);
    //Fetching  Earnings
    async function TutorDetails() {
        // setLoading(true);
        try {
            const Details = await Services.getTutorProfile("GET", null, token);
            setTimeout(() => {
                setLoading(false);
            }, 200);
            if (Details.Status == 1) {
                setdata(Details.Tutor);
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    return (
        <Layout Active={""}>
            {loading ? (
                <div className={profileClasses["Container"]}>
                    <div className={profileClasses["wrapper"]}>
                        <SkeletonLoad />
                    </div>
                </div>
            ) : (
                <div className={profileClasses["Container"]}>
                    <div className={profileClasses["wrapper"]}>
                        <h3>
                            {" "}
                            <span></span> TUTOR
                        </h3>
                        <div className={profileClasses["profile"]}>
                            <div className={profileClasses["Contact"]}>
                                <div>
                                    <div
                                        style={{
                                            border: "1px solid #ddd",
                                            padding: "0.2rem",
                                            width: "fit-content",
                                        }}>
                                        <header
                                            className={
                                                profileClasses["initials"]
                                            }>
                                            <img
                                                src={data?.ProfilePicture}
                                                alt={"profile"}
                                                onError={(e) => {
                                                    e.currentTarget.onerror =
                                                        null;
                                                    e.currentTarget.src = user;
                                                }}
                                            />
                                        </header>
                                    </div>
                                    <button
                                        className={profileClasses["action-btn"]}
                                        onClick={() => {
                                            Navigate(`/Tutor/update-profile`);
                                        }}>
                                        <AiFillSetting size={18} />
                                        Update
                                    </button>
                                </div>
                                <div className={profileClasses["card-data"]}>
                                    <h4> Contact</h4>

                                    {data.EmailID?.length > 0 && (
                                        <p>
                                            {" "}
                                            <GrMail color="#016997" size={18} />
                                            <span>{data.EmailID}</span>
                                        </p>
                                    )}
                                    {data.MobileNumber1 && (
                                        <p>
                                            {" "}
                                            <FaPhone
                                                color="#016997"
                                                size={13}
                                            />
                                            <span>{data.MobileNumber1}</span>
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={profileClasses["internal"]}>
                                <h3>Profile</h3>
                                {/* <div>
                        <h3>ID:</h3>
                        <p>{data.TutorID}</p>
                    </div> */}
                                <div>
                                    <h3>Profile Completion:</h3>
                                    <p>{data.ProfileCompletion}%</p>
                                </div>
                                <div>
                                    <h3>Lead RM :</h3>
                                    <p>{data.LeadRMName}</p>
                                </div>
                                <div>
                                    <h3>Buddy RM :</h3>
                                    <p>{data.BuddyRMName}</p>
                                </div>

                                <div>
                                    <h3>SecondShift RM(morning) :</h3>
                                    <p>{data.SecondShiftRM_MorningName}</p>
                                </div>
                                <div>
                                    <h3>SecondShift RM(evening) :</h3>
                                    <p>{data.SecondShiftRM_EveningName}</p>
                                </div>
                                <div>
                                    <h3>Odd hour Fee :</h3>
                                    <p>{data.oddHrsExtraFee}</p>
                                </div>
                                <div>
                                    <h3>Zoom MeetingID :</h3>
                                    <p>{data.ZoomPersonalMeetingID}</p>
                                </div>
                                <div>
                                    <h3>Zoom EmailID :</h3>
                                    <p>{data.ZoomEmailID}</p>
                                </div>
                            </div>
                        </div>
                        <div className={profileClasses["profile-data"]}>
                            <div className={profileClasses["data-wrap-bank"]}>
                                <h3>Account Details</h3>

                                <div>
                                    <h4>Bank Name</h4>
                                    <span>:</span>
                                    <p>{data.BankName}</p>
                                </div>
                                <div>
                                    <h4>Account Number</h4>
                                    <span>:</span>
                                    <p>{data.AccountNumber}</p>
                                </div>
                                <div>
                                    <h4>IFSC </h4>
                                    <span>:</span>
                                    <p>{data.IFSC}</p>
                                </div>
                                <div>
                                    <h4>Pan Number </h4>
                                    <span>:</span>
                                    <p>
                                        <img
                                            src={data.PanNumber}
                                            alt=""
                                            style={{ width: "10rem" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            {data?.Courses?.length > 0 && (
                                <div
                                    className={
                                        profileClasses["data-wrap-courses"]
                                    }>
                                    <h3>Courses :</h3>
                                    <ul>
                                        {data.Courses.length > 0 &&
                                            data.Courses.map((item: any) => (
                                                <li>{item.CourseName}</li>
                                            ))}
                                    </ul>
                                </div>
                            )}

                            <div className={profileClasses["Documents"]}>
                                <div className={profileClasses["data-wrap"]}>
                                    <h3>Qualifications</h3>
                                    {data?.Qualifications?.length > 0 ? (
                                        <div
                                            className={
                                                profileClasses["qualification"]
                                            }>
                                            {data?.Qualifications?.map(
                                                (item: any) => (
                                                    <ul>
                                                        <li>
                                                            <span></span>
                                                            {item.Qualification}
                                                        </li>

                                                        {/* <li>
                                                    <span></span>
                                                    {item.Grade}
                                                </li> */}
                                                    </ul>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        <p>No Qualifications Found</p>
                                    )}
                                </div>
                                <div className={profileClasses["data-wrap"]}>
                                    <h3>Institutions Attended</h3>
                                    {data?.InstitutionsAttended?.length > 0 ? (
                                        <div
                                            className={
                                                profileClasses["qualification"]
                                            }>
                                            <ul>
                                                {data?.InstitutionsAttended?.map(
                                                    (item: any) => (
                                                        <li>{item}</li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    ) : (
                                        <p>No Institutions Found</p>
                                    )}
                                </div>

                                <div className={profileClasses["data-wrap"]}>
                                    <h3>Uploaded Documents</h3>
                                    {data?.Documents?.length > 0 ? (
                                        <div
                                            className={
                                                profileClasses[
                                                    "updateddocuments"
                                                ]
                                            }>
                                            {data.Documents.map((item: any) => {
                                                return item?.Document?.split(
                                                    "."
                                                )[1] === "pdf" ? (
                                                    <img
                                                        src={pdf}
                                                        onClick={() =>
                                                            window.open(
                                                                item.Get_Documents,
                                                                "_blank"
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        src={item.Get_Documents}
                                                        onClick={() =>
                                                            window.open(
                                                                item.Get_Documents,
                                                                "_blank"
                                                            )
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <p>No Documents Found</p>
                                    )}
                                </div>
                                <div className={profileClasses["data-wrap"]}>
                                    <h3>Notes</h3>
                                    {data?.Notes?.length > 0 ? (
                                        <div
                                            className={
                                                profileClasses["files"]
                                            }></div>
                                    ) : (
                                        <p>No Notes Found</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
}

export default Profile;
