import { useState } from "react";

import DemoClasses from "./demo.module.scss";
import useStore from "store";
const Demo = () => {
    const { setDemoForm } = useStore((item: any) => item);
    return (
        <div className={DemoClasses["Container"]}>
            <div>
                <h3>Want to Request a Demo?</h3>
                <p>
                    Click button to request a demo course that you are
                    interested
                </p>
            </div>
            <button onClick={() => setDemoForm(true)}>Request Demo</button>
        </div>
    );
};

export default Demo;
