export const colorGenerator = (type: number) => {
    if (type === 1) {
        return {
            light: "#d5cee7",
            dark: "#9785c2",
        };
    }
    if (type === 2) {
        return {
            dark: "#e68686",
            light: "#f2c0c0",
        };
    }
    if (type === 3) {
        return {
            dark: "#54c3d1",
            light: "#caeaf2",
        };
    } else {
        return {
            dark: "red",
            light: "blue",
        };
    }
};
