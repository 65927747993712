import React from "react";
import { FallingLines } from "react-loader-spinner";
import LoadClasses from "./loader.module.scss";
function Loader() {
    return (
        <div className={LoadClasses["Loader-wrapper"]}>
            <div className={LoadClasses["loader"]}>
                <FallingLines color="#4fa94d" width="100" visible={true} />
            </div>
        </div>
    );
}

export default Loader;
