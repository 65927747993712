import React, { useState } from "react";
import styles from "./details.module.scss";
interface FAQItem {
    Question: string;
    Answer: string;
    isOpen: boolean;
}
interface FAQitems {
    FAQItems: FAQItem[];
}

const FAQ: React.FC<FAQitems> = ({ FAQItems }) => {
    const [faqItems, setFaqItems] = useState<FAQItem[]>([...FAQItems]);
    const toggleFAQ = (index: number) => {
        setFaqItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {
                ...prevItems[index],
                isOpen: !prevItems[index].isOpen,
            };
            return updatedItems;
        });
    };

    return (
        <div className={styles["faq-container"]}>
            {faqItems.map((item, index) => (
                <div key={index} className={styles["faq-questions"]}>
                    <div onClick={() => toggleFAQ(index)}>
                        <span
                            className={`${styles["toggle-icon"]} ${
                                item.isOpen ? styles["toggle-icon-open"] : ""
                            }`}>
                            {item.isOpen ? "-" : "+"}
                        </span>
                        <h3>{item.Question}</h3>
                    </div>
                    <p className={item.isOpen ? styles["open"] : ""}>
                        {item.isOpen ? item.Answer : ""}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default FAQ;
