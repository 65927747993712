import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import Courseskeleton from "./CourseSkeleton/skeleton";
import CourseClass from "./Courses.module.scss";
// import StarRatings from "react-star-ratings";
import { Get_Courses } from "Service/parent";

import { Fade } from "react-awesome-reveal";
import {
    setLocalStorageWithExpiration,
    getLocalStorageWithExpiration,
} from "Reusable/utils/expiryLocalData";
const Courses = () => {
    const Navigate = useNavigate();
    const [Courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const courses = async () => {
        setLoading(true);
        try {
            const allCourses = await Get_Courses("POST", null, token);
            if (allCourses.Status === 1) {
                setCourses(allCourses.Courses);
                setLocalStorageWithExpiration("allCourses", allCourses.Courses);
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        let data = getLocalStorageWithExpiration("allCourses");
        if (!data) {
            courses();
        } else {
            setLoading(false);
            setCourses(data);
        }
        //eslint - disable - next - line;
    }, []);
    const viewHandler = (e: React.MouseEvent<HTMLElement>) => {
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/Parent/Course-details?courseId=${id}`);
    };
    return (
        <>
            {loading ? (
                <Courseskeleton />
            ) : (
                <div className={CourseClass["Container"]}>
                    {Courses.map((item: any, index: any) => (
                        <div className={CourseClass["course"]} key={index}>
                            <Fade triggerOnce>
                                <img alt="Course" src={item.CourseImage} />
                                <div className={CourseClass["Content"]}>
                                    <small>{item.CategoryName}</small>
                                    <h3>{item.CourseName}</h3>
                                    <p>{item.Overview}</p>
                                    {/* <div className={CourseClass["Star-rating"]}>
                                    <StarRatings
                                        rating={item.Rating}
                                        starRatedColor="#FFC107"
                                        numberOfStars={5}
                                        starDimension="18"
                                        starSpacing="5"
                                        name="rating"
                                    />
                                    <span>({item.ReviewsCount})</span>
                                </div> */}
                                </div>
                                <div className={CourseClass["explore"]}>
                                    <p>{item.NoOfSessions} classes</p>
                                    <button
                                        id={item.CourseID}
                                        onClick={viewHandler}>
                                        Explore
                                    </button>
                                </div>
                            </Fade>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Courses;
