import React from "react";
export const config = [
    {
        path: "",
        element: React.lazy(() => import("../../Pages/Common/Homepage/index")),
    },
    {
        path: "/about-us",
        element: React.lazy(() => import("../../Pages/Common/Aboutus")),
    },
    {
        path: "/courses",
        element: React.lazy(() => import("../../Pages/Common/courses")),
    },
    {
        path: "/courses/details",
        element: React.lazy(
            () => import("../../Pages/Common/CourseDetails/details")
        ),
    },
    {
        path: "/contact-us",
        element: React.lazy(
            () => import("../../Pages/Common/Contactus/contactus")
        ),
    },
    {
        path: "/FAQ",
        element: React.lazy(() => import("../../Pages/Common/FAQ/Faq")),
    },
    {
        path: "/testimonials",
        element: React.lazy(
            () => import("../../Pages/Common/Testimonials/Testimonials")
        ),
    },
    {
        path: "/resources",
        element: React.lazy(() => import("../../Pages/Common/ResourcesPage")),
    },
    {
        path: "/resources/blog-details",
        element: React.lazy(
            () => import("../../Pages/Common/BlogDetails/details")
        ),
    },
    {
        path: "/Delete",
        element: React.lazy(() => import("../../Reusable/Common/delete/image")),
    },
    {
        path: "/Teachers",
        element: React.lazy(() => import("../../Pages/Common/Teachers")),
    },
    {
        path: "/Privacy-Policy",
        element: React.lazy(
            () => import("../../Pages/Common/PrivacyPolicy/policy")
        ),
    },
    {
        path: "/login",
        element: React.lazy(() => import("../../Pages/Common/Login/Login")),
    },
    {
        path: "/Terms-and-conditions",
        element: React.lazy(
            () => import("../../Pages/Common/Terms/TermsConditions")
        ),
    },
    {
        path: "/signup/parent",
        element: React.lazy(() => import("../../Pages/Common/Login/signup")),
    },
    {
        path: "/signup/Tutor",
        element: React.lazy(
            () => import("../../Pages/Common/Login/signupTutor")
        ),
    },
    {
        path: "/faildPartial",
        element: React.lazy(
            () => import("../../Reusable/Common/partials/failed")
        ),
    },
    {
        path: "/SuccessPartial",
        element: React.lazy(
            () => import("../../Reusable/Common/partials/success")
        ),
    },
    {
        path: "/Student-registration-form",
        element: React.lazy(
            () => import("../../Pages/Common/StudentRegistration")
        ),
    },
    {
        path: "/Student-registration/success",
        element: React.lazy(
            () => import("../../Pages/Common/StudentRegistration/signupThank")
        ),
    },
    {
        path: "*",
        element: React.lazy(
            () => import("../../Pages/Common/pageNotFound/pageNotFound")
        ),
    },
];
