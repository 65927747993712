import { useState, useEffect } from "react";
import GetClasses from "./request.module.scss";
import { Services } from "Service/service";
import { Error } from "Reusable/utils/Toast";
import TableLoad from "../TableLoad/load";
import Moment from "react-moment";
function GetCancelRequest() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await Services.bulkCancelRequests(
                    "GET",
                    null,
                    token
                );
                if (response.Status === 1) {
                    setData(response.BulkCancelRequests);
                } else {
                    Error(response.Message);
                }
            } catch {
                Error("Something went Wrong try again");
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            }
        };
        fetch();
    }, []);
    return (
        <div className={GetClasses["Container"]}>
            {loading ? (
                <TableLoad />
            ) : (
                <div className={GetClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Dates</th>
                                <th>Cancel Reason</th>
                                <th>Comments</th>
                                <th>Total Days</th>
                                <th>Requested on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? (
                                data.map((item: any, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td
                                                style={{
                                                    maxWidth: "200px",
                                                }}>
                                                <Moment format="DD MMM YYYY">
                                                    {new Date(item.FromDate)}
                                                </Moment>
                                                {item.FromDate !=
                                                    item.ToDate && (
                                                    <Moment format="-DD MMM YYYY">
                                                        {new Date(item.ToDate)}
                                                    </Moment>
                                                )}
                                            </td>
                                            <td>{item.CancelReason}</td>
                                            <td style={{ maxWidth: "200px" }}>
                                                {item.Comments}
                                            </td>
                                            <td>{item.NoOfDays}</td>
                                            <td>
                                                {" "}
                                                <Moment format="DD MMM YYYY">
                                                    {new Date(item.CreatedAt)}
                                                </Moment>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}> no records Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default GetCancelRequest;
