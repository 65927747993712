import Modal from "react-modal";
import React, { useEffect } from "react";
import Styles from "./deomform.module.scss";
import { props } from "./types";
import cup from "../../../../Assets/cup.svg";
import { AiOutlineClose } from "react-icons/ai";
import useStore from "store";
Modal.setAppElement("#root");

const DemoForm = () => {
    const { setThankYouModal, ThankYouModal } = useStore((item: any) => item);

    const closeModal = () => {
        setThankYouModal(false);
    };
    // useEffect(() => {
    //     party.confetti(document.body, {
    //         count: party.variation.range(20, 40),
    //     });
    // }, []);
    return (
        <Modal
            isOpen={ThankYouModal}
            contentLabel="Modal"
            onRequestClose={closeModal}
            className={`${Styles["modal"]} ${Styles["thankyou"]}`}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["thankyou-header"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <img src={cup} alt="cup" />
                <h3>Thank You</h3>
                <p>
                    Your demo has been booked successfully. One of our
                    counsellor’s will reach out to you within 24-48 hours
                </p>
            </div>
            {/* <div className={Styles["body"]}>
                <h3>Did you check out these carefully-curated resources?</h3>
                <div className={Styles["Links"]}>
                    <NavLink to="/">
                        Free Resources Section <BsArrowRight />
                    </NavLink>
                    <NavLink to="/">
                        Paid Resources Section <BsArrowRight />
                    </NavLink>
                    <NavLink to="/">
                        Blog Resources Section <BsArrowRight />
                    </NavLink>
                </div>
            </div> */}
        </Modal>
    );
};
export default DemoForm;
