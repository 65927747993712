import React, { useState, useEffect } from "react";
import Layout from "../../../Reusable/Tutor/Layout/index";
import Earningclasses from "./earning.module.css";
import { Helmet } from "react-helmet";
import { warningSwal } from "../../../Reusable/utils/Toast";
import { Services } from "../../../Service/service";
import TableLoad from "./TableLoad/load";
import { useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
type Inputs = {
    FromDate?: string;
    ToDate?: string;
};
function Earning() {
    const [Loading, setLoading] = useState<boolean>(true);
    const [params] = useSearchParams();
    const [earnings, set_Earnings] = useState<any>({
        Earnings: [],
        CreatedAt: new Date(),
        AmountToPay: 0,
        PayoutStatus: 2,
    });
    const Navigate = useNavigate();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        Tutor_Earnings();
        // eslint-disable-next-line
    }, []);

    //Fetching  Earnings
    async function Tutor_Earnings() {
        setLoading(true);
        try {
            const AllClasses = await Services.Get_Payout_Details(
                "POST",
                JSON.stringify({ PayoutID: params.get("PayoutId") }),
                token
            );
            setTimeout(() => {
                setLoading(false);
            }, 200);
            if (AllClasses.Status === 1) {
                set_Earnings(AllClasses?.Get_Payout_Earnings[0]);
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            warningSwal("Error", "something went wrong please try again");
        }
    }

    let TableHead: string[] = [
        "Class Date",
        "Class Time",
        "Duration",
        "Batch Name",
        "Type",
        "Credit",
        "Debit",
    ];
    const backButton = () => {
        Navigate(-1);
    };
    console.log(earnings);
    return (
        <Layout Active={"Earnings"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Earnings</title>
            </Helmet>
            <div className={Earningclasses["Container"]}>
                <button className={Earningclasses["back"]} onClick={backButton}>
                    Back
                </button>
                <div className={Earningclasses["Tutor"]}>
                    <h3>
                        Payout Details{" "}
                        <p
                            className={
                                earnings?.PayoutStatus === 1
                                    ? Earningclasses["paid"]
                                    : Earningclasses["unpaid"]
                            }
                            style={{ fontSize: "1rem" }}>
                            {earnings?.PayoutStatus === 1 ? "paid" : "pending"}
                        </p>
                    </h3>
                    <header>
                        <div className={Earningclasses["payout_data"]}>
                            <div>
                                <p>Amount :</p>
                                <h4>
                                    ₹{" "}
                                    {isNaN(earnings.AmountToPay)
                                        ? 0.0
                                        : Number(earnings.AmountToPay).toFixed(
                                              2
                                          )}
                                </h4>
                            </div>
                            <div>
                                <p>Payout Date :</p>
                                <h4>
                                    <Moment format="DD-MM-YYYY">
                                        {new Date(earnings.CreatedAt)}
                                    </Moment>
                                </h4>
                            </div>
                        </div>
                    </header>
                    {Loading ? (
                        <TableLoad TableHead={TableHead} />
                    ) : (
                        <div className={Earningclasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        {TableHead.map((item) => (
                                            <th>{item}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {earnings?.Earnings?.length > 0 ? (
                                        earnings?.Earnings?.map((item: any) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <Moment format="DD-MM-YYYY">
                                                            {
                                                                new Date(
                                                                    item.Scheduled_StartDate
                                                                )
                                                            }
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        {
                                                            item.Scheduled_StartTime
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.DurationInMinutes}{" "}
                                                        Min
                                                    </td>
                                                    <td
                                                        style={{
                                                            maxWidth: "12rem",
                                                            wordBreak:
                                                                "break-word",
                                                        }}>
                                                        {item.BatchName}
                                                    </td>

                                                    <td>
                                                        {item.EarningType ===
                                                            "Lesson_Cancel" &&
                                                            "Lesson Cancel"}
                                                        {item.EarningType ===
                                                            "Lesson_Complete" &&
                                                            "Lesson Complete"}
                                                    </td>
                                                    <td
                                                        style={{
                                                            color:
                                                                String(
                                                                    item.Credit_Or_Debit
                                                                ).toLowerCase() ===
                                                                "DEBIT".toLowerCase()
                                                                    ? "red"
                                                                    : "green",
                                                        }}>
                                                        {item.Credit_Or_Debit !=
                                                        "DEBIT"
                                                            ? isNaN(item.Amount)
                                                                ? 0.0
                                                                : `₹ ${Number(
                                                                      item.Amount
                                                                  ).toFixed(2)}`
                                                            : "-"}
                                                    </td>
                                                    <td
                                                        style={{
                                                            color:
                                                                String(
                                                                    item.Credit_Or_Debit
                                                                ).toLowerCase() ===
                                                                "DEBIT".toLowerCase()
                                                                    ? "red"
                                                                    : "green",
                                                        }}>
                                                        {item.Credit_Or_Debit ==
                                                        "DEBIT"
                                                            ? isNaN(item.Amount)
                                                                ? 0.0
                                                                : `₹ ${Number(
                                                                      item.Amount
                                                                  ).toFixed(2)}`
                                                            : "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={8}
                                                style={{ textAlign: "center" }}>
                                                No Record Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Earning;
