import React from "react";

import BalanceClasses from "./balance.module.scss";
import LeaveChart from "./chart/chart";

const Balance: React.FC<any> = ({ leaveBalance }) => {
    const casual = {
        type: 1,
        stats: {
            Annual: leaveBalance?.Casual_AnualLeaves,
            Available: leaveBalance?.RemainingCasualLeaves,
            Consumed: Math.abs(
                leaveBalance?.Casual_AccruedLeaves -
                    leaveBalance?.RemainingCasualLeaves
            ),
            Accrued: leaveBalance?.Casual_AccruedLeaves,
        },
    };
    const Marriage = {
        type: 2,
        stats: {
            Annual: leaveBalance?.Marriage_AnualLeaves,
            Available: leaveBalance?.RemainingMarriageLeaves,
            Consumed: Math.abs(
                leaveBalance?.Marriage_AccruedLeaves -
                    leaveBalance?.RemainingMarriageLeaves
            ),
            Accrued: leaveBalance?.Marriage_AccruedLeaves,
        },
    };
    const Sick = {
        type: 3,
        stats: {
            Annual: leaveBalance?.Sick_AnualLeaves,
            Available: leaveBalance?.RemainingSickLeaves,
            Consumed: Math.abs(
                leaveBalance?.Sick_AccruedLeaves -
                    leaveBalance?.RemainingSickLeaves
            ),
            Accrued: leaveBalance?.Sick_AccruedLeaves,
        },
    };
    // const unpaid = {
    //     type: 4,
    //     stats: { Annual: 14, Available: 10, Consumed: 0, Accrued: 0 },
    // };
    return (
        <div className={BalanceClasses["Container"]}>
            <h3>Leave Balances</h3>
            <div className={BalanceClasses["charts"]}>
                <LeaveChart type={casual.type} stats={casual.stats} />
                <LeaveChart type={Marriage.type} stats={Marriage.stats} />
                <LeaveChart type={Sick.type} stats={Sick.stats} />
            </div>
        </div>
    );
};

export default Balance;
