import Layout from "Reusable/Tutor/Layout";
import { useState } from "react";
import ResourceClasses from "./Resources.module.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function Resource() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Layout Active={"Resources"}>
            <div className={ResourceClasses["Container"]}>
                <div className={ResourceClasses["wrapper"]}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div style={{ height: "750px", width: "100%" }}>
                            <Viewer
                                enableSmoothScroll
                                viewMode="viewMode"
                                fileUrl={`https://static.mygov.in/indiancc/2022/12/mygov-9999999992062473942.pdf`}
                                // plugins={[defaultLayoutPluginInstance]}
                            />
                        </div>
                    </Worker>
                    {/* <Document
                        file={
                            "https://static.mygov.in/indiancc/2022/12/mygov-9999999992062473942.pdf"
                        }
                        onLoadSuccess={onDocumentLoadSuccess}
                        error={"<p>An error occurred1!</p>"}>
                        <Page pageNumber={pageNumber} />
                    </Document> */}
                </div>
            </div>
        </Layout>
    );
}

export default Resource;
