import React from "react";
import { Route, Routes } from "react-router";
import { config } from "./config";
import Modal from "Pages/Common/CourseDetails/Demoform/demoForm";
import useStore from "store";
import { MdOutlineStarBorderPurple500 } from "react-icons/md";

function Common() {
    const { SetDemoFormBeforeLogin, DemoFormBeforeLogin } = useStore(
        (item: any) => item
    );

    return (
        <>
            {DemoFormBeforeLogin && <Modal dropDownProps={""} courseId={""} />}
            <div className="book-demo-right">
                <button onClick={() => SetDemoFormBeforeLogin(true)}>
                    <span>Book Demo</span>
                    <p className="icon">
                        <MdOutlineStarBorderPurple500 size={18} />
                    </p>
                </button>
            </div>
            <Routes>
                {config.map((route) => (
                    <Route
                        path={route.path}
                        element={<route.element />}></Route>
                ))}
            </Routes>
        </>
    );
}

export default Common;
