import React from "react";
import DetailsClasses from "./details.module.scss";
import { extractInitials } from "../../../../Reusable/utils/utils";
import Moment from "react-moment";
interface props {
    comment: string;
    loginDetails: any;
    DateTime: string;
}
const Comment: React.FC<props> = ({ comment, loginDetails, DateTime }) => {
    return (
        <div className={DetailsClasses["comment-container"]}>
            <div className={DetailsClasses["profile"]}>
                <p>
                    {" "}
                    {extractInitials(
                        `${loginDetails.FirstName} ${loginDetails.LastName}`
                    )}
                </p>
            </div>
            <div className={DetailsClasses["Comment-details"]}>
                <h5>
                    {loginDetails.FirstName} {loginDetails.LastName}
                </h5>
                <p>{comment}</p>
            </div>
            <p>
                <Moment format="MMM DD, YYYY">{new Date(DateTime)}</Moment>
            </p>
        </div>
    );
};

export default Comment;
