import React from "react";
import { useForm } from "react-hook-form";
import Styles from "./popup.module.scss";
import Modal from "react-modal";
import moment from "moment";
import ReactLoading from "react-loading";
interface props {
    modal: any;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    getDetails?: any;
    dates: { ToDate: string; FromDate: string };
    erroMsg: string;
    cancelLoad: boolean;
    classes: any;
    selectedRows: string[];
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}
interface Type {
    Comments: string;
    CancelReason: string;
}
const Popup: React.FC<props> = ({
    modal,
    setModal,
    getDetails,
    dates,
    setErrorMessage,
    erroMsg,
    cancelLoad,
    selectedRows,
    classes,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm<Type>();
    const CancelReasons = [
        {
            value: "Berevearment",
            Label: "Berevearment",
        },
        { value: "One_Day", Label: "One Day" },
        { value: "6_Class_Cancellation", Label: "6 Class Cancellation" },
        { value: "Medical_Emergency", Label: "Medical Emergency" },
        { value: "Other_Leaves", Label: "Others" },
    ];
    async function onSubmit(data: any) {
        setErrorMessage("");
        let fromDate = moment(dates.FromDate);
        let toDate = moment(dates.ToDate);
        let DateDifference = toDate.diff(fromDate, "days") + 1;

        // Check if both dates are in the same month
        if (!fromDate.isSame(toDate, "month")) {
            return setError("CancelReason", {
                message: "From Date and To Date should be in same month",
            });
        }
        if (
            classes.length != selectedRows.length &&
            data.CancelReason != "Other Leaves" &&
            data.CancelReason != "6 Class Cancellation"
        ) {
            return setError("CancelReason", {
                message: "Please select all the classes",
            });
        }
        if (data.CancelReason == "Berevearment" && DateDifference > 4)
            return setError("CancelReason", {
                message:
                    "No more than 4 Consecutive days allowed in this Category",
            });
        if (data.CancelReason == "One Day" && DateDifference != 1)
            return setError("CancelReason", {
                message: "No more than 1 Day is Allowed",
            });
        if (data.CancelReason == "Medical Emergency" && DateDifference > 7)
            return setError("CancelReason", {
                message: "No more than 7 Days is Allowed in this Category",
            });
        let CancelType = CancelReasons.filter(
            (item) => item.Label === data.CancelReason
        );

        getDetails({
            ...data,
            NoOfDays: DateDifference,
            CancelType: CancelType[0].value,
        });
        // reset({ Comments: "", CancelReason: "0" });
    }
    return (
        <>
            <Modal
                isOpen={modal}
                contentLabel="Modal"
                // onRequestClose={() => {
                //     setModal(false);
                //     reset({ Comments: "", CancelReason: "0" });
                // }}
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <header>
                        <h3>Bulk cancel</h3>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Cancellation Reason</label>
                            <select
                                defaultValue={"0"}
                                {...register("CancelReason", {
                                    required: "This is required",
                                    onChange: (e) => setErrorMessage(""),
                                })}>
                                <option value={"0"} disabled>
                                    Select Reason
                                </option>
                                {CancelReasons.map((item) => (
                                    <option value={item.Label}>
                                        {item.Label}
                                    </option>
                                ))}
                            </select>
                            {errors.CancelReason && (
                                <span style={{ color: "red" }}>
                                    {errors.CancelReason.message}
                                </span>
                            )}
                        </div>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Comments</label>
                            <textarea
                                {...register("Comments", {
                                    required: true,
                                })}></textarea>
                            {errors.Comments && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                        {erroMsg.length > 0 && (
                            <span style={{ color: "red", fontSize: "0.9rem" }}>
                                {erroMsg}
                            </span>
                        )}
                        {cancelLoad ? (
                            <div className={Styles["Control-btns"]}>
                                <ReactLoading
                                    color="#0d3075"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            </div>
                        ) : (
                            <div className={Styles["Control-btns"]}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setModal(false);
                                        setErrorMessage("");
                                        reset({
                                            Comments: "",
                                            CancelReason: "0",
                                        });
                                    }}>
                                    Close
                                </button>
                                <button type="submit">Submit</button>
                            </div>
                        )}
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Popup;
