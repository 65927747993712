import { FaUser } from "react-icons/fa";
import { IoCall, IoLogOut } from "react-icons/io5";
import { IoIosNotifications } from "react-icons/io";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { RiArrowDropDownLine } from "react-icons/ri";
import TopProfile from "./profile/topProfile";
import Swal from "sweetalert2";
import useStore from "../../../store";
import TopNavclass from "../../Parent/Topnav/topnav.module.scss";
import Logo from "Assets/logo.svg";
import AddKid from "Pages/Parent/Profile/profileDetails/profile";
import ParentProfile from "Reusable/Parent/profileDetailsModal/profile";
import Support from "../Support/support";
import Complaint from "./complaint";
import { Error } from "Reusable/utils/Toast";
import {
    setLocalStorageWithExpiration,
    getLocalStorageWithExpiration,
} from "Reusable/utils/expiryLocalData";
import { get_unseen_Notifications, resetNotification } from "Service/parent";
const TopNav = () => {
    const Navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [AddKidModal, setAddKitModal] = useState(false);
    const [ParentProfileModal, setParentProfileModal] = useState(false);
    const [SupportModal, setSupport] = useState("");
    const [hide, setHide] = useState(true);
    const [isProfileVisible, setProfileVisible] = useState(false);
    const [showComplaint, setShowComplaint] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    // eslint-disable-next-line
    const [cookie] = useCookies([
        "parentDetails",
        "StudentDetails",
        "HomepageCourses",
        "HomepageData",
        "currentStudent",
    ]);
    const { setParentSideNav, setTab, setActiveKid, ActiveKid } = useStore(
        (store: any) => store
    );
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const StudentDetails: any[] = cookie.StudentDetails;

    const [Notification, setNotification] = useState(0);

    const unseenNotifications = async () => {
        try {
            const Homepage = await get_unseen_Notifications("GET", null, token);
            if (Homepage.Status === 1) {
                setNotification(Homepage.Count.Notifications);
                setLocalStorageWithExpiration(
                    "Count",
                    Homepage.Count.Notifications
                );
            }
        } catch (err: any) {
            Error("Something went wrong try again later");
        }
    };
    useEffect(() => {
        const handleWheelScroll = (event: any) => {
            if (event.deltaY > 0) {
                // User is scrolling down (towards the bottom)
                const scrollPosition = window.scrollY;
                if (scrollPosition >= 150) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            } else if (event.deltaY < 0) {
                // User is scrolling up (towards the top)

                setIsSticky(false);
            }
        };
        let is_Notification_Count_Valid =
            getLocalStorageWithExpiration("Count");
        if (is_Notification_Count_Valid == null) {
            unseenNotifications();
        }
        window.addEventListener("wheel", handleWheelScroll);

        return () => {
            window.removeEventListener("wheel", handleWheelScroll);
        };
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (params.get("StudentId")) {
            let studentId: any = params.get("StudentId");
            if (String(studentId) !== String(localStorage.getItem("student"))) {
                studentId = localStorage.getItem("student");

                if (studentId) {
                    setParams({
                        ...Object.fromEntries(params),
                        StudentId: studentId,
                    });
                }
            }
            let Active = cookie?.StudentDetails?.filter(
                (item: any) => item.StudentID === Number(studentId)
            );
            if (Active && Active.length > 0) {
                setActiveKid(`${Active[0]?.FirstName} ${Active[0]?.LastName}`);
            }
        }
    }, [cookie?.StudentDetails]);
    const changeKidhandler = (e: any) => {
        let id = e.currentTarget.getAttribute("custom-id");
        localStorage.removeItem("HomepageData");
        let Active = cookie?.StudentDetails.filter(
            (item: any) => item.StudentID === Number(id)
        );
        setHide(true);
        setTab("HOME");

        setActiveKid(`${Active[0]?.FirstName} ${Active[0]?.LastName}`);
        Navigate(`/Parent/home?StudentId=${id}`);
        localStorage.setItem("student", id);
    };
    const notificationHandler = async () => {
        try {
            const Homepage = await resetNotification("POST", null, token);
            if (Homepage.Status === 1) {
                setNotification(0);
            }
        } catch (err: any) {
            Error("Something went wrong try again later");
        }
        Navigate("/parent/Notifications");
    };
    return (
        <>
            {showComplaint && (
                <Complaint
                    showModal={showComplaint}
                    setModal={setShowComplaint}
                />
            )}

            {SupportModal.length > 0 && (
                <Support setModal={setSupport} showModal={SupportModal} />
            )}
            {isSticky && <div style={{ height: "100px" }}></div>}
            {AddKid && (
                <AddKid
                    EditMode={false}
                    setModal={setAddKitModal}
                    showModal={AddKidModal}
                />
            )}
            {ParentProfileModal && (
                <ParentProfile
                    setModal={setParentProfileModal}
                    showModal={ParentProfileModal}
                />
            )}
            <div
                className={`${TopNavclass["Container"]} ${
                    isSticky ? TopNavclass["sticky"] : ""
                }  ${!hide ? TopNavclass["main-unhide"] : ""}`}>
                <header>
                    <div className={`${TopNavclass["Left"]}`}>
                        <img
                            src={Logo}
                            alt="greet"
                            onClick={() => Navigate("/")}
                        />
                        <p>
                            Hello{" "}
                            <span>
                                {cookie?.parentDetails?.FirstName
                                    ? String(
                                          cookie?.parentDetails.FirstName
                                      ).slice(0, 20)
                                    : "User"}{" "}
                            </span>
                        </p>
                    </div>
                    <div className={TopNavclass["user-btns"]}>
                        <button
                            className={TopNavclass["user-btn"]}
                            onClick={() => setParentSideNav()}>
                            {" "}
                            <HiOutlineMenuAlt3 size={22} />
                        </button>
                        <button
                            className={TopNavclass["user-btn"]}
                            onClick={() => setHide((prev) => !prev)}>
                            {" "}
                            <FaUser size={22} />
                        </button>
                    </div>
                    <div
                        className={`${TopNavclass["right_main"]} ${
                            !hide ? TopNavclass["unhide"] : TopNavclass["hide"]
                        }`}>
                        <div className={TopNavclass["right"]}>
                            {ActiveKid.length > 0 && (
                                <div className={TopNavclass["dropdown"]}>
                                    <button className={TopNavclass["dropbtn"]}>
                                        {ActiveKid}{" "}
                                        <RiArrowDropDownLine size={30} />
                                    </button>
                                    <div
                                        className={
                                            TopNavclass["dropdown-content"]
                                        }>
                                        {StudentDetails?.map((item: any) => (
                                            <button
                                                custom-id={item.StudentID}
                                                onClick={changeKidhandler}>
                                                {item.FirstName} {item.LastName}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div className={TopNavclass["notifications"]}>
                                <button onClick={notificationHandler}>
                                    <IoIosNotifications color="white" />
                                    <p>{Notification}</p>
                                </button>
                            </div>
                            <div className={TopNavclass["profile-menu"]}>
                                <button
                                    onClick={() =>
                                        setProfileVisible((prev) => !prev)
                                    }>
                                    <FaUser />
                                </button>
                                {isProfileVisible && (
                                    <TopProfile
                                        setSupport={setSupport}
                                        setTab={setTab}
                                        setIsProfileVisible={setProfileVisible}
                                        setAddKitModal={setAddKitModal}
                                        setParentProfileModal={
                                            setParentProfileModal
                                        }
                                        setshowComplaint={setShowComplaint}
                                    />
                                )}
                            </div>
                            {/* <button
                                className={TopNavclass["logout"]}
                                onClick={logoutHandler}>
                                <IoLogOut /> Logout
                            </button> */}
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
};

export default TopNav;
