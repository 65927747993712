import Modal from "react-modal";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useCookies } from "react-cookie";
import { IoIosCall } from "react-icons/io";
import { BiBookContent } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { Props, Inputs } from "./types";
import Styles from "./demo.module.scss";
import { NavLink } from "react-router-dom";
import { Get_Courses, Request_Demo } from "Service/parent";
import { Error } from "Reusable/utils/Toast";
import { getLocalStorageWithExpiration } from "Reusable/utils/expiryLocalData";
import useStore from "store";
Modal.setAppElement("#root");

const Form = () => {
    const [cookie] = useCookies(["StudentDetails", "parentDetails"]);
    const [Courses, setCourses] = useState([]);
    const {
        DemoForm,
        demoFormCourseId,
        setDemoForm,
        setThankYouModal,
        setdemoFormCourseId,
    } = useStore((item: any) => item);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<Inputs>();

    const courses = async () => {
        try {
            const allCourses = await Get_Courses("POST", null, token);
            if (allCourses.Status === 1) {
                setCourses(allCourses.Courses);
                if (demoFormCourseId) {
                    setValue("CourseID", demoFormCourseId);
                }
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        }
    };

    useEffect(() => {
        let data = getLocalStorageWithExpiration("allCourses");
        if (!data) {
            courses();
        } else {
            setCourses(data);
        }
        reset({
            EmailID: cookie?.parentDetails?.EmailID,
            MobileNumber: cookie?.parentDetails?.MobileNumber,
            CourseID: demoFormCourseId ? demoFormCourseId : "",
        });
        // eslint-disable-next-line
    }, []);
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        let data = cookie.StudentDetails.filter(
            (item: any) => item.StudentID === Number(formdata.StudentName)
        );

        let body = {
            ...formdata,
            ParentName: `${cookie?.parentDetails.FirstName} ${cookie?.parentDetails.LastName}`,
            CountryCode: `${cookie?.parentDetails.CountryCode}`,
            StudentName: `${data[0].FirstName} ${data[0].LastName}`,
            StudentAge:
                new Date().getFullYear() - new Date(data[0].DOB).getFullYear(),
        };
        try {
            const submit = await Request_Demo(
                "POST",
                JSON.stringify(body),
                token
            );
            if (submit.Status === 1) {
                closeModal();
                setThankYouModal(true);
            } else {
                Error(submit.Message);
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        }
    };
    const closeModal = () => {
        setDemoForm(false);
        reset();
    };

    const { setTab } = useStore((item: any) => item);
    return (
        <>
            <Modal
                isOpen={DemoForm}
                contentLabel="Modal"
                className={Styles["modal"]}
                onRequestClose={closeModal}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["form-wrapper"]}>
                    <header>
                        <h2>Request Demo</h2>
                        <p>
                            Fill this form to get in front of{" "}
                            <span>Expert</span>
                        </p>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={Styles["form-control"]}>
                            <FaUser className={Styles["icon"]} />
                            <select
                                {...register("StudentName", {
                                    required: true,
                                    onChange: (e) => {},
                                })}
                                placeholder="Select Student"
                                defaultValue={""}>
                                <option value="" disabled>
                                    Select Student
                                </option>
                                {cookie?.StudentDetails?.map((item: any) => (
                                    <option value={`${item.StudentID}`}>
                                        {item.FirstName} {item.LastName}
                                    </option>
                                ))}
                            </select>
                            {errors.StudentName && (
                                <span className={Styles["error"]}>
                                    This field is required
                                </span>
                            )}
                        </div>

                        <div className={Styles["form-control"]}>
                            <BiBookContent
                                className={Styles["icon"]}
                                size={21}
                            />
                            <select
                                {...register("CourseID", {
                                    required: true,
                                })}
                                placeholder="Select Course"
                                defaultValue={""}
                                disabled={demoFormCourseId ? true : false}>
                                <option value="" disabled>
                                    Select Course
                                </option>
                                {Courses.map((item: any) => (
                                    <option value={item.CourseID}>
                                        {item.CourseName}
                                    </option>
                                ))}
                            </select>
                            {errors.CourseID && (
                                <span className={Styles["error"]}>
                                    This field is required
                                </span>
                            )}
                        </div>
                        <div className={Styles["form-control"]}>
                            <MdEmail className={Styles["icon"]} size={21} />
                            <input
                                type="email"
                                disabled
                                {...register("EmailID", {
                                    required: false,
                                })}
                                placeholder="Email Address"
                            />
                            {errors.EmailID && (
                                <span className={Styles["error"]}>
                                    This field is required
                                </span>
                            )}
                        </div>
                        <div className={Styles["form-control"]}>
                            <IoIosCall className={Styles["icon"]} size={21} />
                            <input
                                disabled
                                type="text"
                                {...register("MobileNumber", {
                                    required: true,
                                })}
                                placeholder="Mobile Number"
                            />
                            {errors.MobileNumber && (
                                <span className={Styles["error"]}>
                                    This field is required
                                </span>
                            )}
                        </div>
                        <div className={Styles["contact"]}>
                            <p>
                                <span> Have a question</span> ?
                                <span
                                    style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setTab("ContactUs");
                                        closeModal();
                                    }}>
                                    {" "}
                                    Contact Support
                                </span>
                            </p>
                        </div>
                        <button>Submit</button>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Form;
