import { useEffect, useState } from "react";
import SkeletonLoad from "./Skeleton/skeleton";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import Demo from "./requestDemo/demo";
import MyCourse from "./MyCourse/MyCourse";
import ClassTaken from "./ClassTaken/taken";
import Classes from "./upcomingclass/Classes";
import { Error } from "Reusable/utils/Toast";
import {
    setLocalStorageWithExpiration,
    getLocalStorageWithExpiration,
} from "Reusable/utils/expiryLocalData";
import { Get_Home_Page } from "Service/parent";
import HomepageClasses from "./upcomingclass/Homepage.module.scss";
import HomeWork from "./Projects/projects";
const Homepage = () => {
    const [params] = useSearchParams();
    const [_, setCookie] = useCookies(["HomepageUpdate"]);
    const [pencilLoad, setPencilLoad] = useState(false);
    const [circleLoad, setCircleLoad] = useState(false);
    const [homePageData, setHomePageData] = useState({
        CompletedClasses: [],
        Courses: [],
        ActiveClasess: [],
    });
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const getHomePage = async (
        PageType: number,
        StudentId: any,
        circleloader: boolean
    ) => {
        if (circleloader) {
            setCircleLoad(true);
        } else {
            setPencilLoad(true);
        }
        try {
            const Homepage = await Get_Home_Page(
                "GET",
                null,
                token,
                PageType,
                StudentId
            );
            if (Homepage.Status === 1) {
                let data = {
                    ActiveClasess: Homepage.ActiveClasess,
                    CompletedClasses: Homepage.CompletedClasses,
                    Courses: Homepage.Courses,
                };
                setHomePageData(data);

                // setLocalStorageWithExpiration("HomepageData", data);
                // setCookie("HomepageUpdate", new Date(), { maxAge: 0.5 * 60 });
            }
        } catch (err: any) {
            console.log(err);
            Error("Something went wrong try again later");
        } finally {
            setTimeout(() => {
                setPencilLoad(false);
                setCircleLoad(false);
            }, 300);
        }
    };

    useEffect(() => {
        /* if (!getLocalStorageWithExpiration("HomepageData")) {
            let studentId: any = params.get("StudentId");

            getHomePage(2, studentId, false);
        } else {
            setPencilLoad(false);

            setHomePageData(getLocalStorageWithExpiration("HomepageData"));
        } */
        let studentId: any = params.get("StudentId");
        if (studentId) {
            getHomePage(2, studentId, false);
        }
    }, [params.get("StudentId")]);

    return (
        <div className={HomepageClasses["Container"]}>
            {/* {circleLoad && <Loader />} */}
            {pencilLoad ? (
                <SkeletonLoad />
            ) : (
                <>
                    <Classes
                        EnrolledCourses={homePageData.ActiveClasess}
                        getHomePage={getHomePage}
                    />
                    <MyCourse EnrolledCourses={homePageData.Courses} />
                    {/* <HomeWork /> */}
                    <Demo />
                </>
            )}
            <ClassTaken StudentId={params.get("StudentId")} />
        </div>
    );
};

export default Homepage;
