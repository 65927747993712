import React from "react";
import DateClasses from "./date.module.scss";
import { ExtractDateDetails } from "../../../../../Reusable/utils/utils";
const DateView: React.FC<any> = ({ date }) => {
    const { day, month, dayOfWeek } = ExtractDateDetails(date);
    return (
        <div className={DateClasses["date-calender"]}>
            <div className={DateClasses["Container"]}>
                <h3>{month}</h3>
                <p>{String(day).padStart(2, "0")}</p>
                <p>{dayOfWeek}</p>
            </div>
            <p>Second Half</p>
        </div>
    );
};

export default DateView;
