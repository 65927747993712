import React from "react";
import Skeleton from "react-loading-skeleton";
import SkeletonClasses from "./skeleton.module.scss";
function SkeletonLoading() {
    return (
        <>
            <div className={SkeletonClasses["Skeleton-Container"]}>
                <div className={SkeletonClasses["skeleton-rightside"]}>
                    <Skeleton height={"10px"} count={6} />
                </div>
            </div>
            <div className={SkeletonClasses["Table"]}>
                <table>
                    <thead>
                        <tr>
                            <th>Student Name</th>

                            <th>Attendance</th>
                            <th>Report</th>
                            <th>Review</th>
                            <th>Rating</th>

                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {["1", 2, 3, 4].map(() => (
                            <tr>
                                <td>
                                    <Skeleton height={"1rem"} />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default SkeletonLoading;
