import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSpring, animated } from "react-spring";
import CancelClass from "./cancelClass.module.scss";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Error, SuccessSwal } from "Reusable/utils/Toast";
import { AiOutlineClose } from "react-icons/ai";
import { get_Configuration, cancel_class } from "Service/parent";
import moment from "moment";
import { useCookies } from "react-cookie";
import useStore from "store";
import Loader from "Reusable/Common/LoadingComponents/loader";
interface CustomModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    data: any;
    getHomePage: any;
    studentId: any;
}

const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    setIsOpen,
    getHomePage,
    data,
    studentId,
}) => {
    const {
        handleSubmit,
        reset,
        control,
        watch,
        register,
        formState: { errors },
    } = useForm<any>();

    const [cookie] = useCookies(["parentDetails"]);
    const { timeZone } = useStore((item: any) => item);

    const bounce = useSpring({
        transform: isOpen ? "scale(1)" : "scale(0.9)",
    });
    const [loading, setLoading] = useState(true);
    const [IsPenalityCharged, setIsPenalityCharged] = useState({
        AmountPercent: 0,
        charged: 0,
    });
    const [Amount, setAmount] = useState(0);
    useEffect(() => {
        const config = async () => {
            try {
                const configData = await get_Configuration("GET", null, token);
                if (configData.Status === 1) {
                    const scheduledDateTimeMoment = moment(
                        `${data.Scheduled_StartDate}T${data.Scheduled_StartTime}+05:30`
                    ).tz(timeZone || moment.tz.guess());

                    // Get the current time
                    const currentTimeMoment = moment().tz(timeZone);

                    // Calculate the time difference in minutes
                    const timeDifferenceInMinutes =
                        scheduledDateTimeMoment.diff(
                            currentTimeMoment,
                            "minutes"
                        );
                    const [ParentClassCancelLimit_Minutes] =
                        configData.Configuration.filter(
                            (item: any) =>
                                item.Key == "ParentClassCancelLimit_Minutes"
                        );
                    const [ParentClassCancel_Penality] =
                        configData.Configuration.filter(
                            (item: any) =>
                                item.Key == "ParentClassCancel_Penality"
                        );
                    if (
                        timeDifferenceInMinutes > 0 &&
                        timeDifferenceInMinutes <
                            ParentClassCancelLimit_Minutes.Value
                    ) {
                        setAmount(
                            (data.PricePerClass / 100) *
                                ParentClassCancel_Penality.Value
                        );
                        console.log();
                        setIsPenalityCharged({
                            AmountPercent: ParentClassCancel_Penality.Value,
                            charged: 1,
                        });
                    }
                } else {
                    Error(configData.Message);
                }
            } catch {
                Error("Something went wrong try again");
            } finally {
                setLoading(false);
            }
        };
        config();
    }, []);
    const onRequestClose = () => {
        setIsOpen(false);
        setIsPenalityCharged({ AmountPercent: 0, charged: 0 });
        setAmount(0);
        reset();
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const formDataWatch = watch();
    // FORM SUBMIT

    const onSubmit: SubmitHandler<any> = async (formdata) => {
        let body: any = {
            Scheduled_StartTime: data.Scheduled_StartTime,
            CancelledByID: cookie?.parentDetails?.ParentID,
            CancelledBy: "Parent",
            Scheduled_StartDate: data.Scheduled_StartDate,
            Scheduled_Classes_StudentID: data?.Scheduled_Classes_StudentID,
            IsPenalityCharged: IsPenalityCharged.charged,
            BatchSize: data.BatchSize,
            ScheduleClassID: data.ScheduleClassID,
            ChargedAmount: Amount,
        };
        if (formdata.CancellationReason != "Others") {
            body = { ...body, ...formdata };
        } else {
            body = { ...body, CancellationReason: formdata.reason };
        }
        setLoading(true);
        try {
            let cancel = await cancel_class(
                "PUT",
                JSON.stringify(body),
                token,
                data.Scheduled_Classes_StudentID
            );
            if (cancel.Status === 1) {
                SuccessSwal("Cancelled", cancel.Message);
                onRequestClose();
                getHomePage(2, studentId, false);
            } else {
                Error(cancel.Message);
                setLoading(false);
            }
        } catch {
            setLoading(false);
            Error("Something went Wrong try again");
        }
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Modal"
            className={CancelClass["custom-modal"]}
            overlayClassName={CancelClass["custom-overlay"]}>
            <div>
                <div className={CancelClass["form-wrapper"]}>
                    <header>
                        <button onClick={onRequestClose}>
                            <AiOutlineClose />
                        </button>
                        <h2>Cancel Class</h2>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={CancelClass["form-control"]}>
                            <select
                                defaultValue={""}
                                {...register("CancellationReason", {
                                    required: true,
                                })}>
                                <option value="" disabled>
                                    Reason
                                </option>
                                <option value="Travelling">Travelling</option>
                                <option value="Child Sick/ Cranky">
                                    Child Sick/ Cranky
                                </option>
                                <option value="Internet /Technical Issue">
                                    Internet or technical Issue
                                </option>
                                <option value="We are not at Home">
                                    We are not at Home
                                </option>
                                <option value="Others">Others</option>
                            </select>
                            {errors.CancellationReason && (
                                <span>This is required</span>
                            )}
                        </div>
                        {formDataWatch.CancellationReason == "Others" && (
                            <div className={CancelClass["form-control"]}>
                                <textarea
                                    placeholder="Cancellation Reason"
                                    rows={5}
                                    {...register("reason", {
                                        required: true,
                                    })}
                                />
                                {errors.reason && <span>This is required</span>}
                            </div>
                        )}
                        {IsPenalityCharged.charged === 1 && (
                            <span>
                                {data.BatchSize != "Group"
                                    ? `Cancellation within 60 minutes of class start time will incur a ${IsPenalityCharged.AmountPercent}%  fee. Proceed with cancellation ?`
                                    : "We will inform the teacher about this. This class will be counted and chargeable to you if the class happens. However you can watch the class recording and also access the homework and other materials shared after the class"}
                            </span>
                        )}
                        <div className={CancelClass["Action_btns"]}>
                            <button disabled={loading}>
                                {loading ? "..please wait" : "Yes"}
                            </button>
                            <button type="button" onClick={onRequestClose}>
                                No
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;
