import moment from "moment";

const DateTimeGenerator = (time: string, dateStr: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    const formatted_date = new Date(dateStr);
    formatted_date.setUTCHours(hours);
    formatted_date.setUTCMinutes(minutes);
    const istOffset = 5.5 * 60 * 60 * 1000;
    return new Date(formatted_date.getTime() - istOffset);
};

export const CalenderDataGenerator = (data: any) => {
    // Check for duplicates if on leave based on AvailableDate
    const uniqueTimeSlots: any = [];
    const existingDates = new Set();

    data.forEach((item: any, index: any) => {
        if (item.OnLeave == 1) {
            const availableDate = moment(item.AvailableDate).format(
                "YYYY-MM-DD"
            );
            if (!existingDates.has(availableDate)) {
                uniqueTimeSlots.push({
                    TimeSlotID: item.TimeSlotID,
                    start: DateTimeGenerator(
                        item.StartTime,
                        item.AvailableDate
                    ),
                    allDay: item.OnLeave === 1 ? true : false,
                    title:
                        item.OnLeave === 1
                            ? "on Leave"
                            : `${String(item.StartTime).slice(0, 5)}-${String(
                                  item.EndTime
                              ).slice(0, 5)}`,
                    end: DateTimeGenerator(item.EndTime, item.AvailableDate),
                });
                existingDates.add(availableDate);
            }
        } else {
            uniqueTimeSlots.push({
                TimeSlotID: item.TimeSlotID,
                start: DateTimeGenerator(item.StartTime, item.AvailableDate),
                allDay: item.OnLeave === 1 ? true : false,
                title:
                    item.OnLeave === 1
                        ? "on Leave"
                        : `${String(item.StartTime).slice(0, 5)}-${String(
                              item.EndTime
                          ).slice(0, 5)}`,
                end: DateTimeGenerator(item.EndTime, item.AvailableDate),
            });
        }
    });

    return uniqueTimeSlots;
};
