import React, { useRef, useEffect, useState } from "react";
import menuClasses from "./topProfile.module.scss";
import Profilepic from "Assets/profile.svg";
import { FaUser } from "react-icons/fa";
import { FaUserGraduate, FaPhoneAlt } from "react-icons/fa";
import { RiMessage2Fill } from "react-icons/ri";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import useStore from "store";
type props = {
    setIsProfileVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setSupport: React.Dispatch<React.SetStateAction<string>>;
    setTab: React.Dispatch<React.SetStateAction<string>>;
    setAddKitModal: React.Dispatch<React.SetStateAction<boolean>>;
    setParentProfileModal: React.Dispatch<React.SetStateAction<boolean>>;
    setshowComplaint: React.Dispatch<React.SetStateAction<boolean>>;
};
const TopProfile: React.FC<props> = ({
    setIsProfileVisible,
    setAddKitModal,
    setParentProfileModal,
    setTab,
    setSupport,
    setshowComplaint,
}) => {
    const Navigate = useNavigate();
    const popupRef = useRef<HTMLDivElement>(null);
    const { setDemoForm } = useStore((item: any) => item);
    const [cookie, _, removeCookie] = useCookies([
        "parentDetails",
        "StudentDetails",
        "HomepageCourses",
        "HomepageData",
        "currentStudent",
    ]);
    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener("mousedown", handleOutsideClick);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    const handleOutsideClick = (event: MouseEvent) => {
        // Close the popup if the click is outside of it
        if (
            popupRef.current &&
            !popupRef.current.contains(event.target as Node)
        ) {
            setIsProfileVisible(false);
        }
    };
    const logoutHandler = () => {
        Swal.fire({
            title: "Are you sure you want to logout",
            showDenyButton: true,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            customClass: {
                actions: menuClasses["popup-actions"],
                cancelButton: "order-1 right-gap",
                confirmButton: menuClasses["logout-popup"],
                denyButton: "order-3",
            },
        }).then((result: any) => {
            if (result.isConfirmed) {
                localStorage.clear();
                sessionStorage.clear();
                Navigate("/");
                setTab("HOME");
                removeCookie("currentStudent");
                removeCookie("HomepageData");
                removeCookie("StudentDetails");
                removeCookie("parentDetails");
                removeCookie("HomepageCourses");
            }
        });
    };
    return (
        <>
            <div className={menuClasses["TopNav_Container"]} ref={popupRef}>
                <div className={menuClasses["header"]}>
                    <img src={Profilepic} alt="profile" />
                    <h3>
                        {cookie?.parentDetails?.FirstName
                            ? String(cookie?.parentDetails.FirstName).slice(
                                  0,
                                  15
                              )
                            : "User"}{" "}
                        {cookie?.parentDetails?.LastName &&
                        cookie?.parentDetails?.LastName != "NA"
                            ? String(cookie?.parentDetails.LastName).slice(
                                  0,
                                  15
                              )
                            : ""}
                    </h3>
                </div>
                <ul>
                    <li onClick={() => setParentProfileModal(true)}>
                        <button>
                            <FaUser /> Update Profile
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                setAddKitModal(true);
                            }}>
                            <FaUserGraduate />
                            Add Kid
                        </button>
                    </li>
                    {/* <li>
                        <button>
                            <FaWallet />
                            My Wallet
                        </button>
                    </li> */}

                    <li>
                        <button
                            onClick={() => {
                                setDemoForm(true);
                            }}>
                            {" "}
                            <TfiHeadphoneAlt />
                            Request Demo
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                setSupport("Contact");
                            }}>
                            <FaPhoneAlt />
                            Contact Us
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                setshowComplaint(true);
                            }}>
                            <RiMessage2Fill />
                            Raise Complaint
                        </button>
                    </li>
                </ul>
                <button onClick={logoutHandler}>Sign Out</button>
            </div>
        </>
    );
};

export default TopProfile;
