import { Line } from "rc-progress";
import { useNavigate } from "react-router";
import CourseClass from "./Mycourse.module.scss";
import { useSearchParams } from "react-router-dom";

const MyCourse: React.FC<any> = ({ EnrolledCourses }) => {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const viewHandler = (e: React.MouseEvent<HTMLElement>) => {
        let courseid = e.currentTarget.getAttribute("course-id");
        let enrollid = e.currentTarget.getAttribute("enroll-id");
        Navigate(
            `/Parent/EnrolledCourse?StudentId=${params.get("StudentId")}`,
            { state: { courseid: courseid, enrollid: enrollid } }
        );
    };

    return (
        <div className={CourseClass["Container"]}>
            <header>
                <h3>My Courses</h3>
                <p>Self learning and live courses I am enrolled in</p>
            </header>
            <div className={CourseClass["myClasses"]}>
                <table>
                    <thead>
                        <tr>
                            <th>Course</th>
                            <th className={CourseClass["preferredDays"]}>
                                Preferred Days
                            </th>
                            <th>Resources</th>
                            <th>Progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        {EnrolledCourses.length > 0 ? (
                            EnrolledCourses.map((item: any) => {
                                const Completed = Math.floor(
                                    (item.SessionsCompleted /
                                        item.TotalSessions) *
                                        100 || 0
                                );
                                return (
                                    <tr>
                                        <td
                                            className={
                                                CourseClass["courseName"]
                                            }>
                                            {item.CourseName}

                                            <br />
                                            <span>{item.CategoryName}</span>
                                        </td>

                                        <td
                                            className={
                                                CourseClass["preferredDays"]
                                            }>
                                            {item?.PreferredDays}
                                        </td>
                                        <td>
                                            <div
                                                className={
                                                    CourseClass["progress"]
                                                }>
                                                <button
                                                    course-id={item.CourseID}
                                                    enroll-id={
                                                        item.EnrollCourseID
                                                    }
                                                    onClick={viewHandler}>
                                                    View{" "}
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                className={
                                                    CourseClass["progress"]
                                                }>
                                                <p>
                                                    {Completed > 100
                                                        ? 100
                                                        : Completed}
                                                    %
                                                </p>
                                            </div>
                                            <Line
                                                percent={
                                                    Completed > 100
                                                        ? 100
                                                        : Completed
                                                }
                                                trailWidth={3}
                                                strokeWidth={4}
                                                strokeColor="red"
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={3}>No Courses Found </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MyCourse;
