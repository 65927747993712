import create from "zustand";
import moment from "moment-timezone";
const initialState = {
    showNavbar: true,
    timeZone: moment.tz.guess(),
    userData: {},
    Token: "",
    loginType: "Parent",
    RazorPayOrder: "",
    dropdown: "",
    shouldAnimate: false,
    prevItemName: null,
    Tab: "HOME",
    parentSideNav: false,
    NotificationCount: 0,
    CourseId: "",
    kidsDetails: [],
    ActiveKid: "",
    DemoForm: false,
    DemoFormBeforeLogin: false,
    ThankYouModal: false,
    demoFormCourseId: null,
    PaymentModal: false,
};
const useStore = create((set) => ({
    ...initialState,

    setTab: (active: any) => set((state: any) => ({ ...state, Tab: active })),
    setTimeZone: (Zone: any) =>
        set((state: any) => ({
            ...state,
            timeZone: Zone == "NA" ? moment.tz.guess() : Zone,
        })),
    SetDemoFormBeforeLogin: (active: any) =>
        set((state: any) => ({ ...state, DemoFormBeforeLogin: active })),
    SetCourseId: (CourseId: any) =>
        set((state: any) => ({ ...state, CourseId: CourseId })),

    setLoginType: (active: any) =>
        set((state: any) => ({ ...state, loginType: active })),

    setDemoForm: (active: any) =>
        set((state: any) => ({ ...state, DemoForm: active })),
    setdemoFormCourseId: (active: any) =>
        set((state: any) => ({ ...state, demoFormCourseId: active })),

    setThankYouModal: (active: any) =>
        set((state: any) => ({ ...state, ThankYouModal: active })),

    setActiveKid: (active: any) =>
        set((state: any) => ({ ...state, ActiveKid: active })),

    setParentSideNav: (prev: any) =>
        set((state: any) => ({
            ...state,
            parentSideNav: !state.parentSideNav,
        })),
    setshowNavbar: (auth: any) =>
        set((state: any) => ({ ...state, showNavbar: !state.showNavbar })),
    setPaymentModal: (data: any) =>
        set((state: any) => ({ ...state, PaymentModal: data })),
    setNotificationCount: (Count: number) =>
        set((state: any) => ({ ...state, NotificationCount: Count })),
    setUserData: (data: any) =>
        set((state: any) => ({ ...state, userData: data })),
    setDropdown: (data: any) =>
        set((state: any) => ({ ...state, dropdown: data })),
    setToken: (data: any) => set((state: any) => ({ ...state, Token: data })),
    setShouldAnimate: (data: any) =>
        set((state: any) => ({ ...state, shouldAnimate: data })),
    setPrevItemName: (data: any) =>
        set((state: any) => ({ ...state, setPrevItemName: data })),
    setKidsDetails: (data: any) =>
        set((state: any) => ({ ...state, kidsDetails: data })),
}));
export default useStore;
