import topnavclasses from "./topNav.module.css";
import User from "../../../Assets/user.svg";
import { useNavigate } from "react-router";
import useStore from "store";
import { useState, useEffect } from "react";
import { Services } from "Service/service";
import { Error, Success } from "Reusable/utils/Toast";
import {
    setLocalStorageWithExpiration,
    getLocalStorageWithExpiration,
} from "Reusable/utils/expiryLocalData";
import { IoMdNotificationsOutline } from "react-icons/io";
import Notifications from "./Notifications";
function TopNav() {
    const Navigate = useNavigate();
    const [data, setData] = useState<any>({});
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const { NotificationCount, setNotificationCount } = useStore(
        (item: any) => item
    );
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    let userdata: any = localStorage.getItem("LoginDetails");
    if (userdata) {
        userdata = JSON.parse(userdata);
    }
    const Get_NotificationCount = () => {
        Services.get_Notification_count("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setNotificationCount(response.Count.Notifications);
                    setLocalStorageWithExpiration(
                        "Notification",
                        response.Count.Notifications
                    );
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const Reset_NotificationCount = () => {
        Services.Reset_Notification_count("POST", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setNotificationCount(0);
                    setLocalStorageWithExpiration("Notification", 0);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        let is_Notification_Count_Valid =
            getLocalStorageWithExpiration("Notification");
        if (is_Notification_Count_Valid == null) {
            Get_NotificationCount();
        }
        setData(userdata);
    }, []);
    const ProfileHandler = () => {
        Navigate("/Tutor/profile");
    };
    const NotificationHandler = () => {
        Navigate("/Tutor/Notifications");
        if (NotificationCount > 0) {
            Reset_NotificationCount();
        }
    };
    return (
        <div className={topnavclasses["TopNav"]}>
            <div className={topnavclasses["notification-btn"]}>
                <button onClick={NotificationHandler}>
                    <IoMdNotificationsOutline size={26} />
                </button>
                <p className={topnavclasses["count"]}>{NotificationCount}</p>
                <div>
                    {isNotificationVisible && (
                        <Notifications
                            isNotificationVisible={isNotificationVisible}
                            setIsNotificationVisible={setIsNotificationVisible}
                        />
                    )}
                </div>
            </div>
            <div className={topnavclasses["user"]} onClick={ProfileHandler}>
                <img
                    src={data?.ProfilePicture}
                    alt="user"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = User;
                    }}
                />
                <p>{`${
                    userdata?.FirstName
                        ? String(userdata?.FirstName).slice(0, 16)
                        : "Tutor"
                } ${
                    userdata?.LastName
                        ? String(userdata?.LastName).slice(0, 16)
                        : ""
                }`}</p>
            </div>
        </div>
    );
}

export default TopNav;
