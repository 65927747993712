import React, { useState } from "react";
import styles from "./Contact.module.scss";
import { motion } from "framer-motion";
interface FAQItem {
    Question: string;
    Answer: string;
    isOpen: boolean;
}
interface FAQitems {
    FAQItems: FAQItem[];
}

const FAQ: React.FC<FAQitems> = ({ FAQItems }) => {
    const [Items, setFaqItems] = useState<FAQItem[]>([...FAQItems]);

    const toggleFAQ = (index: number) => {
        setFaqItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {
                ...prevItems[index],
                isOpen: !prevItems[index].isOpen,
            };
            return updatedItems;
        });
    };

    return (
        <div className={styles["faq-container"]}>
            {Items.map((item, index) => (
                <motion.div
                    key={index}
                    className={styles["faq-questions"]}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        duration: 0.5,
                        delay: index * 0.2,
                    }}>
                    <div onClick={() => toggleFAQ(index)}>
                        {/* <span
                            className={`${styles["toggle-icon"]} ${
                                item.isOpen ? styles["toggle-icon-open"] : ""
                            }`}>
                            {item.isOpen ? "-" : "+"}
                        </span> */}
                        <span>{index + 1}) </span>
                        <h3>{item.Question}</h3>
                    </div>
                    {item.isOpen && (
                        <p className={item.isOpen ? styles["open"] : ""}>
                            {item.isOpen ? item.Answer : ""}
                        </p>
                    )}
                </motion.div>
            ))}
        </div>
    );
};

export default FAQ;
