import React from "react";
import detailsClasses from "./details.module.scss";
import { BiTimeFive, BiPencil } from "react-icons/bi";
import { BsBook, BsPeople, BsGlobe } from "react-icons/bs";
import { HiOutlinePlayCircle } from "react-icons/hi2";
import { RxPerson } from "react-icons/rx";
import { payment_props } from "./Types";
const PaymentDetails: React.FC<payment_props> = ({
    courseDetails,
    ActionBtn,
    btnName,
}) => {
    return (
        <div className={detailsClasses["right__side"]}>
            <h3> Course Details</h3>
            <div className={detailsClasses["payment_details"]}>
                <div className={detailsClasses["detail"]}>
                    <h3>
                        <BiTimeFive /> Course level
                    </h3>
                    <p
                        style={{
                            color: "#F19426",
                            fontWeight: "bold",
                        }}>
                        {courseDetails?.Level}
                    </p>
                </div>
                <div className={detailsClasses["detail"]}>
                    <h3>
                        <BsBook /> Classes Per Week
                    </h3>
                    <p>{courseDetails?.ClassPerWeek}</p>
                </div>
                <div className={detailsClasses["detail"]}>
                    <h3>
                        <BsPeople /> Age Group
                    </h3>
                    <p>
                        {courseDetails?.FromAge}-{courseDetails?.ToAge} Years
                    </p>
                </div>
                <div className={detailsClasses["detail"]}>
                    <h3>
                        <HiOutlinePlayCircle /> Total Sessions
                    </h3>
                    <p>{courseDetails?.NoOfSessions}</p>
                </div>
                <div className={detailsClasses["detail"]}>
                    <h3>
                        <RxPerson /> One-on-One
                    </h3>
                    <p>
                        {courseDetails?.One_One_Available === 1
                            ? "Available"
                            : "Not Available"}
                    </p>
                </div>
                <div className={detailsClasses["detail"]}>
                    <h3>
                        <BiPencil /> Writing Activity
                    </h3>
                    <p>{courseDetails?.WritingActivity}</p>
                </div>
                <div className={detailsClasses["detail"]}>
                    <h3>
                        <BsGlobe /> Language
                    </h3>
                    <p>{courseDetails?.Language}</p>
                </div>
                <div
                    className={detailsClasses["detail"]}
                    style={{ cursor: "pointer" }}>
                    <h3>Cost Per Class</h3>
                    <div className={detailsClasses["price"]}>
                        {courseDetails?.One_One_Available === 1 && (
                            <p>
                                One to One :
                                <span>
                                    {" "}
                                    &#8377; {courseDetails?.One_One_Price}
                                </span>
                            </p>
                        )}
                        {courseDetails?.IsGroupAvailable == 1 && (
                            <p>
                                Group Price :
                                <span>
                                    {" "}
                                    &#8377; {courseDetails?.Group_Price}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
                {btnName && <button onClick={ActionBtn}>{btnName}</button>}
            </div>
        </div>
    );
};

export default PaymentDetails;
