import React, { useEffect, useState } from "react";
import Layout from "Reusable/Tutor/Layout";
import profileClasses from "./profile.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";

import Loader from "Reusable/Common/LoadingComponents/loader/loader";
import { Error } from "Reusable/utils/Toast";
import { Services } from "Service/service";
import { ProfileType } from "./Types";
import Profilepicture from "./Profilepicture";
import { MultiSelect } from "react-multi-select-component";
import Qualification from "./Qualifications";
import { useNavigate } from "react-router";

const Profile = () => {
    const Navigate = useNavigate();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [document, setDocument] = useState([]);
    const [Categories, setCategories] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [details, setDetails] = useState({});
    const [qualificationDetails, setQualification] = useState([
        {
            Qualification: "",
            QualificationLevel: "",
            Institution: "",
            Grade: "",
            Year: "",
        },
    ]);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm<ProfileType>();

    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    useEffect(() => {
        FetchDropDowns();
    }, []);
    //Fetching  Earnings
    async function TutorDetails() {
        // setLoading(true);
        try {
            const Details = await Services.getTutorProfile("GET", null, token);
            setTimeout(() => {
                setLoading(false);
            }, 200);
            if (Details.Status == 1) {
                setDetails(Details.Tutor);
                setQualification(Details.Tutor.Qualifications);
                setSelectedCategories(
                    Details.Tutor.Categories.map((item: any) => ({
                        value: item.CategoryID,
                        label: item.CategoryName,
                    }))
                );
                setSelectedCourses(
                    Details.Tutor.Courses.map((item: any) => ({
                        value: item.CourseID,
                        label: item.CourseName,
                    }))
                );
                localStorage.setItem(
                    "LoginDetails",
                    JSON.stringify(Details.Tutor)
                );
                let body = { ...Details.Tutor };
                delete body.ProfilePicture;
                reset({ ...body });
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    async function FetchDropDowns() {
        try {
            const [response1, response2] = await Promise.all([
                Services.getCoursesByCategory("POST", null, token),
                Services.getCategories("GET", null, token),
            ]);
            if (response1.Status === 1 && response1.Courses?.length > 0) {
                setCourses(
                    response1.Courses.map((item: any) => {
                        return {
                            value: item.CourseID,
                            label: item.CourseName,
                        };
                    })
                );
            }
            if (response2.Status === 1 && response2.Categories?.length > 0) {
                setCategories(
                    response2.Categories.map((item: any) => ({
                        value: item.CategoryID,
                        label: item.CategoryName,
                    }))
                );
            }
            await TutorDetails();
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    }
    const onSubmit: SubmitHandler<ProfileType> = async (data) => {
        // console.log(qualificationDetails);
        // return;
        setLoading(true);
        let body: any = { ...data };
        delete body.EmailID;
        delete body.MobileNumber2;
        delete body.MobileNumber1;
        try {
            const Details = await Services.updateTutorProfile(
                "PUT",
                JSON.stringify({
                    ...data,
                    Qualifications: qualificationDetails,
                    InstitutionsAttended: qualificationDetails.map(
                        (item) => item.Institution
                    ),
                }),
                token
            );
            setTimeout(() => {
                setLoading(false);
            }, 200);
            TutorDetails();
            if (Details.Status == 1) {
                Navigate(-1);
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    };

    return (
        <Layout Active={""}>
            {Loading ? (
                <Loader />
            ) : (
                <div className={profileClasses["Container"]}>
                    <div className={profileClasses["wrapper"]}>
                        <button
                            onClick={() => {
                                Navigate(-1);
                            }}>
                            Back
                        </button>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Profilepicture
                                data={details}
                                setValue={setValue}
                            />

                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="Name">
                                    First Name <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("FirstName", {
                                        required: "First Name is required",
                                    })}
                                />
                                {errors.FirstName && (
                                    <span>{errors.FirstName.message}</span>
                                )}
                            </div>
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="Name">
                                    Last Name <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("LastName", {
                                        required: "Last Name is required",
                                    })}
                                />
                                {errors.LastName && (
                                    <span>{errors.LastName.message}</span>
                                )}
                            </div>
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="EmailID">
                                    Email <span>*</span>
                                </label>
                                <input
                                    disabled
                                    type="email"
                                    {...register("EmailID", {
                                        required: false,
                                    })}
                                />
                                {errors.EmailID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Primary Contact Number <span>*</span>
                                </label>
                                <input
                                    disabled
                                    type="number"
                                    {...register("MobileNumber1", {
                                        required: false,
                                    })}
                                />
                                {errors.MobileNumber1 && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Secondary Contact Number <span>*</span>
                                </label>
                                <input
                                    disabled
                                    type="number"
                                    {...register("MobileNumber2", {
                                        required: false,
                                    })}
                                />{" "}
                                {errors.MobileNumber2 && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            {/* <div
                                className={
                                    profileClasses["form-control-multi-select"]
                                }>
                                <label htmlFor="Courses">
                                    Categories{" "}
                                    <span
                                        className={profileClasses["important"]}>
                                        *
                                    </span>
                                </label>
                                <MultiSelect
                                    className={
                                        profileClasses["react-multi-select"]
                                    }
                                    disabled
                                    options={Categories}
                                    value={selectedCategories}
                                    onChange={setSelectedCategories}
                                    labelledBy="Select"
                                />
                            </div> */}
                            {/* <div
                                className={
                                    profileClasses["form-control-multi-select"]
                                }>
                                <label htmlFor="Courses">
                                    Courses{" "}
                                    <span
                                        className={profileClasses["important"]}>
                                        *
                                    </span>
                                </label>
                                <MultiSelect
                                    className={
                                        profileClasses["react-multi-select"]
                                    }
                                    options={courses}
                                    value={selectedCourses}
                                    onChange={setSelectedCourses}
                                    labelledBy="Select"
                                />
                            </div> */}

                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Fee Per Hour{" "}
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="number"
                                    {...register("FeePerHour", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.FeePerHour && (
                                    <span>{errors.FeePerHour.message}</span>
                                )}
                            </div> */}
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Address
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("Address", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.Address && (
                                    <span>{errors.Address.message}</span>
                                )}
                            </div>
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    City
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("City", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.City && (
                                    <span>{errors.City.message}</span>
                                )}
                            </div>
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    State
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("State", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.State && (
                                    <span>{errors.State.message}</span>
                                )}
                            </div>
                            <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Pincode
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("Pincode", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.Pincode && (
                                    <span>{errors.Pincode.message}</span>
                                )}
                            </div>
                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Zoom EmailID
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("ZoomEmailID", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.ZoomEmailID && (
                                    <span>{errors.ZoomEmailID.message}</span>
                                )}
                            </div> */}
                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Zoom Personal MeetingID
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("ZoomPersonalMeetingID", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.ZoomPersonalMeetingID && (
                                    <span>
                                        {errors.ZoomPersonalMeetingID.message}
                                    </span>
                                )}
                            </div> */}
                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    odd Hrs Extra Fee
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("oddHrsExtraFee", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.oddHrsExtraFee && (
                                    <span>{errors.oddHrsExtraFee.message}</span>
                                )}
                            </div> */}
                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Account Number
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("AccountNumber", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.AccountNumber && (
                                    <span>{errors.AccountNumber.message}</span>
                                )}
                            </div> */}
                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Bank Name
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("BankName", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.BankName && (
                                    <span>{errors.BankName.message}</span>
                                )}
                            </div> */}
                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    IFSC Code
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("IFSC", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.IFSC && (
                                    <span>{errors.IFSC.message}</span>
                                )}
                            </div> */}
                            {/* <div className={profileClasses["form-control"]}>
                                <label htmlFor="FeePerHour">
                                    Pan Number
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register("PanNumber", {
                                        required: "This field is required",
                                    })}
                                />
                                {errors.PanNumber && (
                                    <span>{errors.PanNumber.message}</span>
                                )}
                            </div> */}

                            <div
                                className={`${profileClasses["form-control"]} ${profileClasses["full-row"]}`}>
                                <label htmlFor="Name">About</label>
                                <textarea
                                    rows={5}
                                    {...register("About", {
                                        required: true,
                                    })}
                                />
                                {errors.About && (
                                    <span>{errors.About.message}</span>
                                )}
                            </div>
                            <div className={profileClasses["bank-details"]}>
                                <h3>Bank account details</h3>
                                <div className={profileClasses["form-control"]}>
                                    <label htmlFor="ContactNumber">
                                        Bank Account Number
                                    </label>
                                    <input
                                        type="number"
                                        {...register("AccountNumber", {
                                            required: false,
                                        })}
                                    />
                                    {errors.AccountNumber && (
                                        <span>
                                            {errors.AccountNumber.message}
                                        </span>
                                    )}
                                </div>
                                <div className={profileClasses["form-control"]}>
                                    <label htmlFor="BankName">
                                        Tutor’s name on the Bank account
                                    </label>
                                    <input
                                        type="text"
                                        {...register("BankAccountName", {
                                            required: false,
                                        })}
                                    />
                                    {errors.BankName && (
                                        <span>{errors.BankName.message}</span>
                                    )}
                                </div>
                                <div className={profileClasses["form-control"]}>
                                    <label htmlFor="BankName">
                                        {" "}
                                        Name of the Bank
                                    </label>
                                    <input
                                        type="text"
                                        {...register("BankName", {
                                            required: false,
                                        })}
                                    />
                                    {errors.BankName && (
                                        <span>{errors.BankName.message}</span>
                                    )}
                                </div>
                                <div className={profileClasses["form-control"]}>
                                    <label htmlFor="IFSC">IFSC CODE</label>
                                    <input
                                        type="text"
                                        {...register("IFSC", {
                                            required: false,
                                        })}
                                    />
                                    {errors.IFSC && (
                                        <span>{errors.IFSC.message}</span>
                                    )}
                                </div>
                            </div>
                            <Qualification
                                qualification={qualificationDetails}
                                setQualification={setQualification}
                            />
                            <button disabled={Loading}>Submit</button>
                        </form>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Profile;
