import React, { useLayoutEffect, useEffect } from "react";
import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css"; // skELETON lOAD
import Error from "Pages/Error/error";
// -----------------Common-Routes ----------
import Common from "./Routes/Common/routes";
import LogoLoad from "./Reusable/Common/LoadingComponents/logoload";

//-----------------Tutor-Routes-------------------
import ParentRoutes from "./Routes/ParentRoutes/routes";
import TutorRoutes from "./Routes/TutorRoutes/routes";
import Auth from "./Reusable/utils/Auth";
import useStore from "store";
import moment from "moment-timezone";
import Thankyou from "Pages/Common/CourseDetails/Demoform/Thankyou";
const App: React.FC = () => {
    const { setTimeZone } = useStore((item: any) => item);
    const location = useLocation();
    useEffect(() => {
        if (localStorage.getItem("TimeZone")) {
            setTimeZone(localStorage.getItem("TimeZone") || moment.tz.guess());
        }
    }, []);
    useLayoutEffect(() => {
        window.scrollTo(0, 0);

        const menuSectionOptionsOpen = document?.getElementById(
            "MenuSectionOptions.Open"
        );

        if (menuSectionOptionsOpen) {
            menuSectionOptionsOpen.style.display = "none";
        }
    }, [location.pathname]);

    return (
        <React.Fragment>
            <>
                <Thankyou />
                <ToastContainer />
                <React.Suspense fallback={<LogoLoad />}>
                    <Routes>
                        <Route element={<Auth />}>
                            <Route path="/Tutor/*" element={<TutorRoutes />} />
                            <Route
                                path="/Parent/*"
                                element={<ParentRoutes />}
                            />
                        </Route>
                        <Route path="/*" element={<Common />} />
                    </Routes>
                </React.Suspense>
            </>
        </React.Fragment>
    );
};

export default App;
