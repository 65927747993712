import React from "react";
import HistroyClasses from "./history.module.scss";
import Moment from "react-moment";

const History: React.FC<any> = ({ history }) => {
    return (
        <div className={HistroyClasses["Container"]}>
            <h3>Leave History</h3>
            <table>
                <thead>
                    <th>Leave Dates</th>
                    <th>Leave Type</th>
                    <th>Status</th>
                    <th>Requested By</th>
                    <th>Action Taken on</th>
                    <th>Leave Note</th>
                    <th>Reject /Cancellation Reason</th>
                </thead>
                <tbody>
                    {history?.length > 0 ? (
                        history.map((item: any) => (
                            <tr>
                                <td>
                                    {item.FromDate !== item.ToDate ? (
                                        <>
                                            <Moment format="MMM DD">
                                                {new Date(item?.FromDate)}
                                            </Moment>
                                            -
                                            <Moment format="MMM DD,YYYY">
                                                {new Date(item?.ToDate)}
                                            </Moment>
                                        </>
                                    ) : (
                                        <Moment format="MMM DD, YYYY">
                                            {new Date(item?.FromDate)}
                                        </Moment>
                                    )}{" "}
                                    <br />
                                    <span> {item.NoOfDays} Days</span>
                                </td>
                                <td>
                                    {item.LeaveType === 1
                                        ? "Casual Leave"
                                        : item.LeaveType === 2
                                        ? "Marriage Leave"
                                        : item.LeaveType === 3
                                        ? "Sick Leave"
                                        : "Unpaid Leave"}
                                    <br />
                                    <span>
                                        Requested on{" "}
                                        <Moment format="MMM DD,YYYY">
                                            {new Date(item?.CreatedAt)}
                                        </Moment>
                                    </span>
                                </td>
                                <td>
                                    {item.ApproveStatus === 1
                                        ? "Approved"
                                        : item.ApproveStatus === 2
                                        ? "Pending"
                                        : "Rejected"}
                                    <br />
                                    {item.ApproveStatus !== 2 && (
                                        <span>
                                            {item.ApproveStatus === 1
                                                ? "Approved By"
                                                : "Rejected By"}{" "}
                                            {item?.Approved_RejectedByName}
                                        </span>
                                    )}
                                </td>
                                <td>{item?.TutorName}</td>
                                <td>
                                    {item.ApproveStatus !== 2 && (
                                        <Moment format="MMM DD,YYYY">
                                            {
                                                new Date(
                                                    item?.Approved_RejectedOn
                                                )
                                            }
                                        </Moment>
                                    )}
                                </td>
                                <td
                                    style={{
                                        maxWidth: "200px",
                                        wordBreak: "break-all",
                                    }}>
                                    {item.LeaveNote}
                                </td>
                                <td>
                                    {item?.RejectReason !== "NA" &&
                                        item?.RejectReason}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7} style={{ textAlign: "center" }}>
                                No Data Found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default History;
