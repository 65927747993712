import React, { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import { IoMdLock } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { BsFolderSymlink } from "react-icons/bs";
import { AiOutlineClockCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment-timezone";
import Report from "./Report/report";
import Layout from "Reusable/Parent/layout";
import Loader from "Reusable/Common/LoadingComponents/logoload/index";
import Moment from "react-moment";
import useStore from "store";
//Assets
import enrolledClasses from "./enrolled.module.scss";
import courseDetailImage from "Assets/Parent/courseDetails.png";

//Services
import {
    getManagers,
    getEnrolledCourseDetails,
    getEnrolledCourseDetailsLessonPlan,
} from "Service/parent";
import { Error } from "Reusable/utils/Toast";
import { Console } from "console";

type coursetypes = {
    CourseName: string;
    BatchSize: string;
    Duration: number;
    TotalLessons: number;
    TutorName: string;
    CourseImage: string;
};
type Manager = {
    EmailID: string;
    RelationshipManager: string;
};
type LessonplansType = {
    PlanStatus_Class?: number;
    Topic: string;
    LessonPlanID: number;
    Scheduled_StartDate: string;
    PlanNumber: number;
};
const EnrolledCourse = () => {
    const [showView, setView] = useState(false);
    const { timeZone } = useStore((item: any) => item);

    const [load, setLoad] = useState(true);
    const [lessonPlans, setLessonPlans] = useState<LessonplansType[]>([]);
    const [CourseDetails, setCourseDetails] = useState<coursetypes>();
    const [Managers, setManagers] = useState<Manager[]>([]);
    const [Resource, setResource] = useState({});
    const backgroundColors = ["#6752A4", "#6398F8", "#D34FBA", "#D34FBC"];
    const Rm = ["RM1", "RM2", "RM3"];

    const location = useLocation();
    const ViewHandler = (e: React.MouseEvent<HTMLElement>) => {
        let planId = e.currentTarget.getAttribute("custom-planid");
        let planNumber = e.currentTarget.getAttribute("custom-plannumber");
        setResource({
            planId,
            planNumber,
            enrollCourseId: location.state.enrollid,
        });
        setView(true);
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        let enrollid = location.state.enrollid;
        let courseid = location.state.courseid;
        let body = {
            EnrollCourseID: enrollid,
            StudentID: localStorage.getItem("student"),
            CourseID: courseid,
        };
        const FetchDetails = async () => {
            try {
                const [CourseDetails, AllManagers, lessonPlan] =
                    await Promise.all([
                        getEnrolledCourseDetails("GET", null, token, enrollid),
                        getManagers(
                            "GET",
                            null,
                            token,
                            body.StudentID,
                            enrollid
                        ),
                        getEnrolledCourseDetailsLessonPlan(
                            "POST",
                            JSON.stringify(body),
                            token
                        ),
                    ]);
                if (CourseDetails.Status === 1) {
                    setCourseDetails(CourseDetails.EnrollCourse);
                }
                if (AllManagers.Status === 1) {
                    setManagers(AllManagers.Managers);
                }
                if (lessonPlan.Status === 1) {
                    setLessonPlans(lessonPlan.LessonPlans);
                }
            } catch {
                Error("Something went wrong try again");
            } finally {
                setTimeout(() => {
                    setLoad(false);
                }, 200);
            }
        };
        if (enrollid && courseid) {
            FetchDetails();
        }
    }, []);
    return (
        <Layout>
            {showView && (
                <Report
                    setModal={setView}
                    showModal={showView}
                    Data={Resource}
                />
            )}
            {load ? (
                <Loader />
            ) : (
                <div className={enrolledClasses["Container"]}>
                    <div className={enrolledClasses["header"]}>
                        <img
                            src={
                                CourseDetails?.CourseImage != "NA"
                                    ? CourseDetails?.CourseImage
                                    : courseDetailImage
                            }
                            alt="course"
                        />
                        <div className={enrolledClasses["course-details"]}>
                            <h2>{CourseDetails?.CourseName}</h2>
                            <p>
                                <img
                                    src={CourseDetails?.CourseImage}
                                    alt={"user"}
                                />
                                <span>By {CourseDetails?.TutorName}</span>
                            </p>
                            <div className={enrolledClasses["about"]}>
                                {/* <div>
                                    <FaRegUser />
                                    <h4>Tutor:Learn2read</h4>
                                </div> */}
                                <div>
                                    <BsFolderSymlink />
                                    <h4>
                                        Total Lessons:
                                        {String(
                                            CourseDetails?.TotalLessons
                                        ).padStart(2, "0")}
                                    </h4>
                                </div>
                                <div>
                                    <FiUsers />
                                    <h4>
                                        Batch Size:{CourseDetails?.BatchSize}
                                    </h4>
                                </div>
                                <div>
                                    <AiOutlineClockCircle />
                                    <h4>
                                        Duration:{CourseDetails?.Duration}{" "}
                                        Minutes
                                    </h4>
                                </div>
                            </div>
                            <div className={enrolledClasses["Contact"]}>
                                <h3> Relationship Manager</h3>
                                <div>
                                    {Managers.map((item, index) => (
                                        <a
                                            href={`mailto:${item.EmailID}`}
                                            style={{
                                                backgroundColor:
                                                    backgroundColors[index],
                                            }}>
                                            {item.RelationshipManager}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={enrolledClasses["topic"]}>
                        <AnimatePresence>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Topic</th>
                                        <th>Class Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lessonPlans.length > 0 ? (
                                        lessonPlans.map((item, index) => {
                                            let Data = String(
                                                item?.Scheduled_StartDate
                                            ).split(" ");
                                            let formattedDate = moment(
                                                `${Data[0]}T${Data[1]}+05:30`
                                            )
                                                .tz(
                                                    timeZone ||
                                                        moment.tz.guess()
                                                )
                                                .format("DD MMM YYYY hh:mm A");

                                            return (
                                                <motion.tr
                                                    key={index}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    transition={{
                                                        duration: 0.5,
                                                        delay: index * 0.2,
                                                    }}>
                                                    <td
                                                        style={{
                                                            textAlign: "left",
                                                        }}>
                                                        <p>
                                                            {" "}
                                                            {item?.PlanStatus_Class ===
                                                            0 ? (
                                                                <IoMdLock color="red" />
                                                            ) : (
                                                                ""
                                                            )}
                                                            {item?.Topic}
                                                        </p>
                                                        {/* <span>Learn2read</span> */}
                                                    </td>
                                                    <td>
                                                        {item?.Scheduled_StartDate
                                                            ? formattedDate
                                                            : "NA"}
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {item?.PlanStatus_Class ===
                                                            0
                                                                ? "Pending"
                                                                : item?.PlanStatus_Class ===
                                                                  2
                                                                ? "Partially Completed"
                                                                : "Completed"}
                                                        </p>
                                                    </td>

                                                    <td>
                                                        <button
                                                            className={
                                                                enrolledClasses[
                                                                    "reportClass"
                                                                ]
                                                            }
                                                            custom-plannumber={
                                                                item.PlanNumber
                                                            }
                                                            custom-planid={
                                                                item.LessonPlanID
                                                            }
                                                            onClick={
                                                                ViewHandler
                                                            }
                                                            disabled={
                                                                item.PlanStatus_Class ===
                                                                0
                                                            }>
                                                            View
                                                        </button>
                                                    </td>
                                                </motion.tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>
                                                No Lesson Plans Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </AnimatePresence>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default EnrolledCourse;
