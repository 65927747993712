import React, { useState } from "react";
import Modal from "react-modal";
import uploadClasses from "./upload.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { UploadDoc } from "../types";
import { IoMdClose } from "react-icons/io";
import { parent_upload } from "Service/parent";
import { Success, warningSwal } from "Reusable/utils/Toast";
import Resource from "./Resource";
type props = {
    showModal: boolean;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    Data?: any;
    ActiveTab: number;
};
const token = {
    Authorization: `token ${localStorage.getItem("learn2readToken")}`,
};
const Report: React.FC<props> = ({ setModal, showModal, Data, ActiveTab }) => {
    const {
        register,
        setValue,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<UploadDoc>();
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setModal(false);
    };

    const submit: SubmitHandler<UploadDoc> = async (formData) => {
        if (!formData.FileImage) {
            warningSwal("warning", "Please Upload Document");
            return;
        }
        setLoading(true);
        let body = {
            ...formData,
            StudentID: localStorage.getItem("student"),
            UploadType: ActiveTab,
        };
        try {
            const [profileData] = await Promise.all([
                parent_upload("POST", JSON.stringify(body), token),
            ]);
            if (profileData?.Status === 1) {
                Success("Profile updated Successfully");
                Data(ActiveTab);
                closeModal();
                setLoading(false);
            }
        } catch {
            setLoading(false);
            Error("something Went wrong try again");
        }
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                contentLabel="Modal"
                className={uploadClasses["modal"]}
                onRequestClose={closeModal}
                overlayClassName={uploadClasses["overlay"]}>
                <div className={uploadClasses["Container"]}>
                    {/* <div className={}></div> */}
                    <header>
                        <h3>Upload Image</h3>
                        <button onClick={closeModal}>
                            <IoMdClose />
                        </button>
                    </header>
                    <form onSubmit={handleSubmit(submit)}>
                        <div className={uploadClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                File Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("FileName", { required: true })}
                                placeholder="Enter File Name"
                            />
                            {errors?.FileName && (
                                <span>File Name is required</span>
                            )}
                        </div>
                        <Resource setValue={setValue} />
                        <div className={uploadClasses["Action-btns"]}>
                            <button type="submit">
                                {loading ? "uploading..." : "Upload"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Report;
