import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SkeletonClass from "./Skeleton.module.scss";
const SkeletonLoad = () => {
    return (
        <SkeletonTheme>
            <div className={SkeletonClass["Container"]}>
                <div className={SkeletonClass["left"]}>
                    <Skeleton height={"1rem"} />
                    <Skeleton height={"1rem"} />
                    <Skeleton height={"1rem"} />
                    <Skeleton height={"1rem"} />
                </div>
                <div className={SkeletonClass["right"]}>
                    <Skeleton height={"1rem"} />
                    <Skeleton height={"1rem"} />
                </div>
            </div>
            <div className={SkeletonClass["left"]}>
                <Skeleton height={"1rem"} />
                <Skeleton height={"1rem"} />
                <Skeleton height={"1rem"} />
                <Skeleton height={"1rem"} />
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonLoad;
