import { useState, useEffect, ChangeEvent } from "react";
import Layout from "../../../../Reusable/Tutor/Layout";
import DetailClasses from "./details.module.scss";
import Swal from "sweetalert2";
import { Services } from "../../../../Service/service";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Error, Success } from "../../../../Reusable/utils/Toast";
import Moment from "react-moment";
import SkeletonLoad from "./SkeletonLoad/skeleton";
import swal from "sweetalert";
import Classes from "../../classes/Classes";
import Loader from "../../../../Reusable/Common/LoadingComponents/loader/loader";
import { useNavigate } from "react-router-dom";
function Details() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);
    let TutorDetails: any = localStorage.getItem("LoginDetails");
    const [data, setData] = useState<any>();
    const [scheduleClasses, setScheduleClasses] = useState([]);
    const [attendanceLoad, setAttendanceLoad] = useState<boolean>(false);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    // INITIAL RENDER
    useEffect(() => {
        Get_Details(params.get("batchid"));
    }, []);

    // GET SCHEDULE CLASS DETAILS
    async function Get_Details(id: string | null) {
        setLoading(true);
        try {
            const [response1, response2] = await Promise.all([
                Services.getBatchRequestsDetailsByBatchId(
                    "GET",
                    null,
                    token,
                    id
                ),
                Services.classesByBatchId("GET", null, token, id),
            ]);
            if (response1.Status === 1 && response2.Status == 1) {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                setData(response1.Batches);
                setScheduleClasses(response2.ScheduleClasess);
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    // API CALL FOR APPROVE REJECT ACTION BUTTONS
    async function ApproveRejectRequest(
        RequestId: number,
        BatchID: number,
        IsAccepted: number,
        BatchCreatedBy: string,
        BatchName: string,
        BatchType: string
    ) {
        try {
            if (TutorDetails) {
                TutorDetails = JSON.parse(TutorDetails);
            }
            let loginDetails: any = localStorage.getItem("LoginDetails");
            if (loginDetails && loginDetails.length > 0) {
                loginDetails = JSON.parse(loginDetails);
            }
            let RMS = [
                loginDetails?.Lead_RM,
                loginDetails?.Buddy_RM,
                loginDetails?.SecondShift_RM_Morning,
                loginDetails?.SecondShift_RM_Evening,
            ];
            const Response = await Services.ApproveRejectBatchRequests(
                "PUT",
                JSON.stringify({
                    BatchID,
                    IsAccepted,
                    BatchCreatedBy,
                    TutoRms: RMS.filter((item) => item != 0),
                    BatchName,
                    TutorName: `${TutorDetails?.FirstName} ${TutorDetails?.LastName}`,
                    BatcBatchCreatedByType: BatchType,
                    CourseName: data.CourseName,
                }),
                token,
                RequestId
            );

            if (Response.Status === 1) {
                Success(Response.Message);
                Get_Details(params.get("batchid"));
            } else {
                swal("Oops...", Response.Message, "error");
                Error("Some thing went wrong try again");
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }

    const Warning = (e: any, type: number) => {
        let Message;
        let RequestId = Number(params.get("requestId"));
        let BatchId = Number(e.currentTarget.getAttribute("custom-batchId"));
        let BatchName = e.currentTarget.getAttribute("custom-batchname");
        let BatchType = e.currentTarget.getAttribute("custom-type");
        let BatchCreatedBy = String(
            e.currentTarget.getAttribute("custom-createBy")
        );

        if (type == 1) {
            // Approve==1,reject==2

            Message = "Are you sure you want to Approve ";
        } else {
            Message = "Are You Sure You want to Reject the Batch?";
        }

        Swal.fire({
            title: Message,
            showDenyButton: true,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            customClass: {
                actions: DetailClasses["popup-actions"],
                cancelButton: "order-1 right-gap",
                confirmButton: DetailClasses["logout-popup"],
                denyButton: "order-3",
            },
        }).then((result: any) => {
            if (result.isConfirmed) {
                ApproveRejectRequest(
                    RequestId,
                    BatchId,
                    type,
                    BatchCreatedBy,
                    BatchName,
                    BatchType
                );
            }
        });
    };

    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Batch Request"}>
            {attendanceLoad && (
                <div className={DetailClasses["loader"]}>
                    <Loader />
                </div>
            )}

            <Helmet>
                <meta charSet="utf-8" />
                <title>Batch Request</title>
            </Helmet>
            <div className={DetailClasses["Container"]}>
                <button className={DetailClasses["back"]} onClick={backButton}>
                    Back
                </button>
                <div className={DetailClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad />
                    ) : (
                        <div className={DetailClasses["container"]}>
                            <div className={DetailClasses["header"]}>
                                <h3>
                                    <span></span>Batch Name:{data?.BatchName}
                                </h3>

                                <div className={DetailClasses["action-btns"]}>
                                    {data?.IsTutorAccepted === 1 ? (
                                        <p
                                            className={
                                                DetailClasses["approved"]
                                            }>
                                            Approved
                                        </p>
                                    ) : data?.IsTutorAccepted == 2 ? (
                                        <p className={DetailClasses["reject"]}>
                                            Rejected
                                        </p>
                                    ) : (
                                        <>
                                            <button
                                                custom-batchId={data.BatchID}
                                                custom-createBy={
                                                    data.BatchCreatedBy
                                                }
                                                custom-batchname={
                                                    data.BatchName
                                                }
                                                custom-type={
                                                    data.BatchCreatedByType
                                                }
                                                onClick={(e) => Warning(e, 1)}>
                                                Approve
                                            </button>
                                            <button
                                                custom-batchId={data.BatchID}
                                                custom-createBy={
                                                    data.BatchCreatedBy
                                                }
                                                custom-batchname={
                                                    data.BatchName
                                                }
                                                custom-type={
                                                    data.BatchCreatedByType
                                                }
                                                onClick={(e) => Warning(e, 2)}>
                                                Reject
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={DetailClasses["profile"]}>
                                <div className={DetailClasses["internal"]}>
                                    <h3>Batch Details</h3>

                                    <div>
                                        <h3>Course</h3>
                                        <p>{data?.CourseName}</p>
                                    </div>

                                    <div>
                                        <h3>Batch Size</h3>
                                        <p>{data?.BatchSize} </p>
                                    </div>
                                    <div>
                                        <h3>Days</h3>
                                        <p>
                                            {data?.Days?.map((item: any) => {
                                                return (
                                                    <>
                                                        {/* <Moment format="HH:MM ">
                                                        </Moment> */}
                                                        {item.Time},{item.day}
                                                        <br />
                                                    </>
                                                );
                                            })}
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Duration</h3>
                                        <p>{data?.Duration} Min </p>
                                    </div>

                                    {/* <div>
                                        <h3>Lesson Plan</h3>
                                        <p>{data?.TutorLessonPlan} </p>
                                    </div> */}
                                    <div>
                                        <h3>Start Date:</h3>
                                        <p>
                                            <Moment format="DD-MM-YYYY">
                                                {new Date(data?.StartDate)}
                                            </Moment>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Start Time:</h3>
                                        <p>{data.Time}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={DetailClasses["internal"]}>
                                <h3>Tutor Instructions</h3>

                                <p>{data?.TutorInstructions}</p>
                            </div>
                            <div className={DetailClasses["Table"]}>
                                <h3>Student Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Parent Name</th>
                                            <th>Student Name</th>

                                            {/* <th>Status</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.Students &&
                                            data?.Students?.length > 0 &&
                                            data?.Students?.map((item: any) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {item.ParentName}
                                                        </td>
                                                        <td>
                                                            {item.StudentName}
                                                        </td>

                                                        {/* <td>
                                                            {item.Status ==
                                                                1 && (
                                                                <span
                                                                    className={
                                                                        DetailClasses[
                                                                            "completed"
                                                                        ]
                                                                    }>
                                                                    Active
                                                                </span>
                                                            )}
                                                            {item.Status ==
                                                                2 && (
                                                                <span
                                                                    className={
                                                                        DetailClasses[
                                                                            "completed"
                                                                        ]
                                                                    }>
                                                                    Completed
                                                                </span>
                                                            )}
                                                            {item.Status ==
                                                                3 && (
                                                                <span
                                                                    className={
                                                                        DetailClasses[
                                                                            "cancelled"
                                                                        ]
                                                                    }>
                                                                    Cancelled
                                                                </span>
                                                            )}
                                                        </td> */}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {/* <h3>Classes</h3> */}
                    <Classes Loading={loading} classesData={scheduleClasses} />
                </div>
            </div>
        </Layout>
    );
}

export default Details;
