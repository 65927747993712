import Modal from "react-modal";
import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import TopupClasses from "./topup.module.scss";
import { AiOutlineClose } from "react-icons/ai";
import { FaRupeeSign, FaComment } from "react-icons/fa";
import { Create_Transaction } from "Service/parent";
import { useCookies } from "react-cookie";
import { Error } from "Reusable/utils/Toast";
Modal.setAppElement("#root");

const Rating: React.FC<props> = ({
    modal,
    setModal,
    getDetails,
    setPayment,
}) => {
    const [cookie] = useCookies(["parentDetails"]);
    const [loading, setLoading] = useState(false);
    const {
        handleSubmit,
        reset,
        control,
        register,
        watch,
        formState: { errors },
    } = useForm<Inputs>();

    const closeModal = () => {
        setModal(false);
        reset();
    };

    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        let body = JSON.stringify({
            ...formdata,
            MobileNumber: cookie?.parentDetails?.MobileNumber,
            type: "web",
        });
        setLoading(true);
        try {
            const Wallet = await Create_Transaction("POST", body, token);
            if (Wallet.Status === 1) {
                // localStorage.setItem("paymentLink", Wallet.Link);
                // setPayment(true);
                setModal(false);
                window.open(Wallet.Link);
                // window.open();
            } else {
                if (Wallet?.Message) {
                    Error(Wallet.Message);
                } else {
                    Error("Something went wrong try Again");
                }
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }
    };

    return (
        <>
            <Modal
                isOpen={modal}
                contentLabel="Modal"
                className={TopupClasses["modal"]}
                // onRequestClose={closeModal}
                overlayClassName={TopupClasses["overlay"]}>
                <div className={TopupClasses["form-wrapper"]}>
                    <button onClick={closeModal}>
                        <AiOutlineClose size={20} />
                    </button>
                    <header>
                        <h3>Topup</h3>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={TopupClasses["form-control"]}>
                            <FaRupeeSign className={TopupClasses["icon"]} />
                            <input
                                type="number"
                                {...register("Amount", { required: "true" })}
                                placeholder="Topup Amount"
                            />
                            {errors?.Amount && <span>this is required</span>}
                        </div>
                        <div className={TopupClasses["form-control"]}>
                            <FaComment className={TopupClasses["icon"]} />
                            <input
                                {...register("Comments", { required: "true" })}
                                placeholder="Comments"
                            />
                            {errors?.Comments && <span>this is required</span>}
                        </div>
                        <div className={TopupClasses["buttons"]}>
                            <button
                                className={TopupClasses["submit"]}
                                disabled={loading}>
                                {loading ? "...please wait" : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Rating;

interface props {
    modal: any;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    setPayment: React.Dispatch<React.SetStateAction<boolean>>;
    getDetails?: any;
}

interface Inputs {
    Amount: number;
    MobileNumber: string;
    Comments: string;
}
