import React, { useState } from "react";
import profileClasses from "./profile.module.scss";
import { FaPlus } from "react-icons/fa";
import NoMedia from "Assets/Nomedia.jpg";
import pdf from "Assets/pdf1.png";
import { MdDelete } from "react-icons/md";
import { ConfirmationDiaolog } from "Reusable/utils/utils";
import { SuccessSwal } from "Reusable/utils/Toast";
import { Delete_upload } from "Service/parent";
import UploadDoc from "./UploadDocuments/upload";
const token = {
    Authorization: `token ${localStorage.getItem("learn2readToken")}`,
};

const Documents: React.FC<any> = ({
    uploads,
    ParentUpload,
    setUploads,
    setLoading,
}) => {
    const [ActiveTab, setActiveTab] = useState(1);
    const [uploadDoc, setUploadDOC] = useState(false);
    const TabHandler = (e: React.MouseEvent<HTMLElement>) => {
        let Type = e.currentTarget.getAttribute("custom-id");
        setActiveTab(Number(Type));
        ParentUpload(Type);
    };
    const DeleteHandler = (e: React.MouseEvent<HTMLElement>) => {
        let Text = "Are you sure you want to Delete Resource?";
        let Id = e.currentTarget.getAttribute("custom-id");
        const DeleteUpload = async () => {
            try {
                const [DeleteData] = await Promise.all([
                    Delete_upload("DELETE", null, token, Id),
                ]);
                if (DeleteData?.Status === 1) {
                    setUploads((prev: any) =>
                        prev.filter((item: any) => item.UploadID != Id)
                    );
                    SuccessSwal("File Deleted", "File Deleted Successfully");
                }

                setTimeout(() => {
                    setLoading(false);
                }, 200);
            } catch {
                setLoading(false);
                Error("something Went wrong try again");
            }
        };
        ConfirmationDiaolog(Text, "Delete", DeleteUpload);
    };
    return (
        <div className={profileClasses["Document-container"]}>
            {uploadDoc && (
                <UploadDoc
                    showModal={uploadDoc}
                    setModal={setUploadDOC}
                    Data={ParentUpload}
                    ActiveTab={ActiveTab}
                />
            )}
            <header>
                <h3>Documents</h3>
                {localStorage.getItem("student") && (
                    <button onClick={() => setUploadDOC(true)}>
                        <FaPlus size={15} />
                        Upload{" "}
                    </button>
                )}
            </header>
            <div className={profileClasses["tabs"]}>
                <button
                    className={ActiveTab === 1 ? profileClasses["Active"] : ""}
                    onClick={TabHandler}
                    custom-id={1}>
                    Materials
                </button>
                <button
                    className={ActiveTab === 2 ? profileClasses["Active"] : ""}
                    onClick={TabHandler}
                    custom-id={2}>
                    Assignments
                </button>
                <button
                    className={ActiveTab === 3 ? profileClasses["Active"] : ""}
                    onClick={TabHandler}
                    custom-id={3}>
                    Notes
                </button>
            </div>
            {uploads?.length === 0 ? (
                <div className={profileClasses["noMedia"]}>
                    <img src={NoMedia} alt="nomedia" />
                    {localStorage.getItem("student") && (
                        <button onClick={() => setUploadDOC(true)}>
                            Upload Media
                        </button>
                    )}
                </div>
            ) : (
                <div className={profileClasses["uploads"]}>
                    {uploads?.map((item: any) => {
                        // Use the last part of the URL after the last '/'
                        const fileName = item?.FileImage?.substring(
                            item?.FileImage?.lastIndexOf("/") + 1
                        );

                        // Extract the file extension
                        const fileExtension = fileName?.substring(
                            fileName?.lastIndexOf(".") + 1
                        );
                        return (
                            <div className={profileClasses["upload"]}>
                                <img
                                    src={
                                        fileExtension === "pdf"
                                            ? pdf
                                            : item?.FileImage
                                    }
                                    alt="nomedia"
                                    onClick={() =>
                                        window.open(
                                            item?.FileImage,
                                            "_blank",
                                            "noreferrer"
                                        )
                                    }
                                />
                                <div>
                                    <p>
                                        {item.FileName}{" "}
                                        <span>
                                            ({Math.round(item.FileSize)}KB)
                                        </span>
                                    </p>
                                    <button
                                        custom-id={item.UploadID}
                                        onClick={DeleteHandler}>
                                        <MdDelete />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Documents;
