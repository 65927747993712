export const config = {
    bucketName: "learn2read",
    dirName: "documents/Tutor",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_S3_ACCESSKEY
        ? process.env.REACT_APP_S3_ACCESSKEY
        : "",
    secretAccessKey: process.env.REACT_APP_S3_SECRET
        ? process.env.REACT_APP_S3_SECRET
        : "",
};
