import { HttpMethod } from "./Types";
const httpcall = (
    url: string,
    method: HttpMethod,
    body: any,
    token: any
): Promise<any> => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};

//---------------Parent Login---------------

export const Parent_Login_Send_Otp = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/login`, method, body, token);
};

export const Parent_Login_Verify = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/login/verifyparent`, method, body, token);
};

export const Parent_Update_Password = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/forgotpassword_update`, method, body, token);
};

//----------------parent Signup------------------

export const Parent_sign_up_Send_Otp = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/signup/sendotp`, method, body, token);
};
export const Parent_verify_Otp = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/signup/verifyotp`, method, body, token);
};
export const Parent_Sign_up = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/signup`, method, body, token);
};

//----------Check Type for getting homePageData-----------
export const HomePage_Type_Check = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/get_homepage_type`, method, body, token);
};

//--------------------------Homepage-------------

export const Get_Home_Page = (
    method: HttpMethod,
    body: any,
    token: any,
    Type: number,
    studentId: number | string
) => {
    return httpcall(
        `parent/web_homepage?Type=${Type}&StudentID=${studentId}`,
        method,
        body,
        token
    );
};
export const getCompletedClasses = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/completedclasses`, method, body, token);
};

//------------------Courses -------------------

export const Get_Courses = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`courses_list`, method, body, token);
};
export const Get_Courses_Details = (
    method: HttpMethod,
    body: any,
    token: any,
    CourseId: any
) => {
    return httpcall(`course_detail/${CourseId}`, method, body, token);
};
export const getEnrolledCourseDetailsLessonPlan = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(
        `parent/lessonsplans_by_enrollcourse_student`,
        method,
        body,
        token
    );
};
export const getEnrolledCourseDetails = (
    method: HttpMethod,
    body: any,
    token: any,
    enrollId: any
) => {
    return httpcall(
        `parent/enrolled_course_details?EnrollCourseID=${enrollId}`,
        method,
        body,
        token
    );
};
export const getManagers = (
    method: HttpMethod,
    body: any,
    token: any,
    studentId: any,
    enrollId: any
) => {
    return httpcall(
        `parent/getmanagers?StudentID=${studentId}&EnrollCourseID=${enrollId}`,
        method,
        body,
        token
    );
};
export const getResourcesByPlanNumber = (
    method: HttpMethod,
    body: any,
    token: any,
    EnrollCourseID: any,
    PlanNumber: any,
    LessonPlanID: any
) => {
    return httpcall(
        `parent/classes_by_plannumber?EnrollCourseID=${EnrollCourseID}&PlanNumber=${PlanNumber}&LessonPlanID=${LessonPlanID}`,
        method,
        body,
        token
    );
};

//------------Request-Demo--------------
export const Request_Demo = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/request_demo`, method, body, token);
};
//--------------ALL Resources-------------

export const Get_Resources = (method: HttpMethod, body: any, token: any) => {
    return httpcall(
        `parent/get_resourses_by_enrollCourse`,
        method,
        body,
        token
    );
};

//------------------MANAGE KID  -----------
export const Get_Kid_Details = (
    method: HttpMethod,
    body: any,
    token: any,
    StudentId: any
) => {
    return httpcall(
        `parent/kid_detail?StudentID=${StudentId}`,
        method,
        body,
        token
    );
};
export const update_Kid_Details = (
    method: HttpMethod,
    body: any,
    token: any,
    StudentId: any
) => {
    return httpcall(`parent/update_kid/${StudentId}`, method, body, token);
};

export const get_All_Kids = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/getkids`, method, body, token);
};

export const Delete_kid = (
    method: HttpMethod,
    body: any,
    token: any,
    kidId: any
) => {
    return httpcall(`parent/Inactive_kid/${kidId}`, method, body, token);
};

export const get_Enrolled_Course_List_By_Student = (
    method: HttpMethod,
    body: any,
    token: any,
    StudentId: any,
    Status: any
) => {
    return httpcall(
        `enrolled_courses_list?StudentID=${StudentId}&BatchStatus=${Status}`,
        method,
        body,
        token
    );
};
export const get_parent_uploads = (
    method: HttpMethod,
    body: any,
    token: any,
    StudentId: any,
    UploadType: any
) => {
    return httpcall(
        `parent/Get_ParentUploads?StudentID=${StudentId}&UploadType=${UploadType}`,
        method,
        body,
        token
    );
};
export const get_parent_Details = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/parent_detail`, method, body, token);
};
export const Delete_upload = (
    method: HttpMethod,
    body: any,
    token: any,
    uploadId: any
) => {
    return httpcall(
        `parent/delete_parentuploads/${uploadId}`,
        method,
        body,
        token
    );
};
export const parent_upload = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/Add_ParentUploads`, method, body, token);
};
export const add_kid = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/add_kid`, method, body, token);
};

// -------------Update Parent profile ------------
export const Update_Parent_Profile = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/update_parent`, method, body, token);
};
// -------------Wallet Transactions ------------
export const Wallet_Transactions = (
    method: HttpMethod,
    body: any,
    token: any,
    filter: boolean,
    date?: any
) => {
    let url = `parent/wallets`;
    if (filter) {
        url = url + `?Date=${date}`;
    }
    return httpcall(url, method, body, token);
};
export const Create_Transaction = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/create_wallet_transaction`, method, body, token);
};

// ---------------support -----------
export const Contact_US = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/Add_ContactForm`, method, body, token);
};
export const Enquiry = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/add_enquiry`, method, body, token);
};
export const EnquirywithOutToken = (method: HttpMethod, body: any) => {
    return httpcall(`parent/add_enquiry_web`, method, body, null);
};
export const ADD_Complaint = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/add_complaints`, method, body, token);
};

//--------------Cancel Class-----------------------

export const get_Configuration = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/getconfiguration`, method, body, token);
};
export const cancel_class = (
    method: HttpMethod,
    body: any,
    token: any,
    studentId: any
) => {
    return httpcall(
        `parent/cancelstudent_from_scheduled_class/${studentId}`,
        method,
        body,
        token
    );
};
export const RateClass = (
    method: HttpMethod,
    body: any,
    token: any,
    Scheduled_Classes_StudentID: any
) => {
    return httpcall(
        `parent/update_class_review/${Scheduled_Classes_StudentID}`,
        method,
        body,
        token
    );
};
export const get_unseen_Notifications = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/get_unseen_noticationcount`, method, body, token);
};
export const resetNotification = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`parent/update_notification_lastseen`, method, body, token);
};
export const getNotifications = (method: HttpMethod, body: any, token: any) => {
    return httpcall(
        `parent/notifications
    `,
        method,
        body,
        token
    );
};
export const cancelRescheduleRequest = (
    method: HttpMethod,
    body: any,
    token: any,
    type: any
) => {
    let api_Type = "reject_reschedulerequest";

    if (type === "Approve") {
        api_Type = "approve_rescheduleclass";
    }
    return httpcall(`parent/${api_Type}`, method, body, token);
};

//--------Help and Support------------

export const SUPPORT = (
    method: HttpMethod,
    body: any,
    token: any,
    queryParams: any
) => {
    const urlWithQuery = queryParams
        ? `parent/CustomerSupport?${new URLSearchParams(queryParams)}`
        : "parent/CustomerSupport";
    return httpcall(urlWithQuery, method, body, token);
};
