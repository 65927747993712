import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import loadClasses from "./load.module.css";

type props = {
    TableHead: string[];
};
const Load: React.FC<props> = ({ TableHead }) => {
    return (
        <SkeletonTheme>
            <div className={loadClasses["Table"]}>
                <table>
                    <thead>
                        <tr>
                            {TableHead.map((item) => (
                                <th>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 1, 1].map(() => (
                            <tr>
                                {TableHead.map((item) => (
                                    <td>
                                        <Skeleton />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </SkeletonTheme>
    );
};

export default Load;
