import React from "react";
import TopNavclass from "./TopNav.module.scss";
// import Greet from "Assets/Parent/hello.png";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useStore from "store";
import Logo from "Assets/logo.svg";
const Topnav: React.FC<any> = () => {
    const { setDemoForm } = useStore((item: any) => item);
    const [cookie] = useCookies(["parentDetails"]);
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 150) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div
            className={`${TopNavclass["Navbar-container"]} ${
                isSticky ? TopNavclass["bg-nav"] : ""
            }`}>
            <div className={`${TopNavclass["Left"]}`}>
                <img src={Logo} alt="greet" />
                <p>
                    Hello{" "}
                    <span>
                        {cookie?.parentDetails?.FirstName
                            ? cookie?.parentDetails.FirstName
                            : "User"}{" "}
                    </span>
                </p>
            </div>
            <button
                onClick={() => {
                    setDemoForm(true);
                }}>
                <span>Request a</span> free Demo
            </button>
        </div>
    );
};

export default Topnav;
