import users from "Assets/user.svg";
import { useState } from "react";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import profileClasses from "./upload.module.scss";
import { config } from "S3Bucket/config";
import ReactS3Client from "react-aws-s3-typescript";
import { BsCloudUpload } from "react-icons/bs";
import pdf from "Assets/pdf1.png";
window.Buffer = window.Buffer || require("buffer").Buffer;
const Profilepicture: React.FC<any> = ({ setValue, data }) => {
    const [profilePic, setprofilePic] = useState<any>("");

    const profileupload = (
        <div className={profileClasses["add-item"]}>
            <BsCloudUpload className={profileClasses["upload-icon"]} />
            <h5>Drag Your File here</h5>
            <p>(Only *.jpeg ,*.png,*.pdf will be accepted)</p>
        </div>
    );

    const profilePicUpload = (files: any) => {
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg" ||
            files.type === "application/pdf"
        ) {
            const timestamp = Date.now();
            let fileName = `${timestamp}.${String(files.type).split("/")[1]}`;
            const newfile = new File([files], fileName);
            const s3 = new ReactS3Client({
                ...config,
                dirName: "Parents/Uploads",
            });
            s3.uploadFile(newfile)
                .then((res: any) => {
                    setValue("FileImage", String(res.key).split("/")[2]);
                    setValue("FileSize", files.size / 1000);
                    setprofilePic({
                        location: res.location,
                        type:
                            files.type === "application/pdf" ? "pdf" : "image",
                    });
                })
                .catch((err: any) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    return (
        <>
            <div className={profileClasses["profile-handler"]}>
                <FileUploader
                    classes={profileClasses["profile-upload-input"]}
                    multiple={false}
                    handleChange={(files: any) => {
                        profilePicUpload(files);
                    }}
                    name="file"
                    children={profileupload}
                    // types={fileTypes}
                />
            </div>
            {profilePic && profilePic?.type && (
                <img
                    className={profileClasses["image"]}
                    src={
                        profilePic?.type === "pdf" ? pdf : profilePic?.location
                    }
                    alt="profilepic"
                />
            )}
        </>
    );
};

export default Profilepicture;
