import Modal from "react-modal";
import React, { useEffect } from "react";
import Styles from "./event.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { Inputs, props } from "./types";
import { Services } from "../../../../Service/service";
import { Error } from "../../../../Reusable/utils/Toast";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
import moment from "moment";
Modal.setAppElement("#root");

const Events: React.FC<props> = ({
    modal,
    setModal,
    getDetails,
    GetTutorAvailability,
    setedit,
    edit,
}) => {
    const {
        handleSubmit,
        reset,
        register,
        setError,
        formState: { errors },
    } = useForm<Inputs>();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const closeModal = () => {
        setModal(false);
        reset({ StartTime: "", EndTime: "" });
        setedit(null);
    };

    useEffect(() => {
        if (edit) {
            reset({ ...edit });
        }
    }, [edit]);
    // FORM SUBMIT

    const getLastDate = (data: any) => {
        const dates = data.map((item: any) => item.AvailableDate);

        const latestDate = new Date(
            Math.max(...dates.map((date: any) => new Date(date)))
        );

        return latestDate.toISOString().split("T")[0];
    };
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        let data = getDetails?.map((item: any) => ({
            StartTime: formdata.StartTime,
            EndTime: formdata.EndTime,
            AvailableDate: moment(item).format("YYYY-MM-DD"),
        }));
        const startTime = new Date(`2000-01-01 ${formdata.StartTime}`);
        const endTime = new Date(`2000-01-01 ${formdata.EndTime}`);
        if (startTime >= endTime) {
            return setError("EndTime", {
                message: "Start time must be earlier than end time",
            });
        }
        if (edit) {
            data = [
                {
                    StartTime: formdata.StartTime,
                    EndTime: formdata.EndTime,
                    AvailableDate: edit.Date,
                },
            ];
        }

        try {
            const SaveTutorAvailability = await Services.SaveTutorAvailability(
                "POST",
                JSON.stringify({
                    DatesList: data,
                    highestDate: getLastDate(data),
                }),
                token
            );
            if (SaveTutorAvailability.Status === 1) {
                swal("Added", SaveTutorAvailability.Message, "success");
                setModal(false);
                GetTutorAvailability();
                setedit(null);
                reset();
            } else {
                Error(SaveTutorAvailability?.Message);
            }
        } catch (err) {
            console.log(err);
            Error("Something went wrong please try again");
        }
    };
    const DeleteHandler = async () => {
        try {
            const SaveTutorAvailability =
                await Services.DeleteTutorAvailability(
                    "DELETE",
                    null,
                    token,
                    edit.TimeSlotID
                );
            if (SaveTutorAvailability.Status === 1) {
                swal("Deleted", SaveTutorAvailability.Message, "success");
                setModal(false);
                GetTutorAvailability();
                setedit(null);
                reset();
            } else {
                Error(SaveTutorAvailability?.Message);
            }
        } catch (err) {
            console.log(err);
            Error("Something went wrong please try again");
        }
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Tutor Availability</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            Start Time<span>:</span>
                        </label>
                        <div className={Styles["form-control-one"]}>
                            <input
                                type="time"
                                placeholder="Start Time"
                                {...register("StartTime", {
                                    required: true,
                                })}
                            />
                            {errors.StartTime && (
                                <span className={Styles["error"]}>
                                    Start Time is required
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            End Time<span>:</span>
                        </label>
                        <div className={Styles["form-control-one"]}>
                            <input
                                type="time"
                                placeholder="End Time"
                                {...register("EndTime", {
                                    required: "EndTime is required",
                                })}
                            />
                            {errors.EndTime && (
                                <span className={Styles["error"]}>
                                    {errors.EndTime.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={Styles["buttons"]}>
                        <button type="submit" className={Styles["submit"]}>
                            {edit ? "Update" : "Submit"}
                        </button>
                        {edit ? (
                            <button
                                type="button"
                                onClick={DeleteHandler}
                                className={Styles["delete"]}>
                                Delete
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={closeModal}
                                className={Styles["close"]}>
                                Cancel
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default Events;
