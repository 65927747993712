import { PieChart } from "react-minimal-pie-chart";
import React from "react";
import ChartClasses from "./chart.module.scss";
import { Props } from "./Types";
import { colorGenerator } from "./colorGenerator";
const Statistics: React.FC<Props> = ({ type, stats }) => {
    return (
        <div className={ChartClasses["Container"]}>
            <h3>
                {type === 1
                    ? "Casual Leave"
                    : type === 2
                    ? "Marriage Leave"
                    : type === 3
                    ? "Sick Leave"
                    : "Unpaid Leave"}
            </h3>
            {type !== 4 ? (
                <div className={ChartClasses["chat-wrapper"]}>
                    <div className={ChartClasses["chart"]}>
                        <PieChart
                            radius={40}
                            lineWidth={30}
                            data={[
                                {
                                    title: `Consumed:${stats.Consumed} days`,
                                    value: stats.Consumed,
                                    color: colorGenerator(type)?.light,
                                },
                                {
                                    title: `Available:${stats.Available} days`,
                                    value: stats.Available,
                                    color: colorGenerator(type).dark,
                                },
                            ]}
                        />
                    </div>
                    <p>
                        {stats.Available} days <br /> Available
                    </p>
                </div>
            ) : (
                <div>
                    {" "}
                    <p style={{ padding: "4rem 0" }}>No Data to Display</p>
                </div>
            )}
            <div className={ChartClasses["details"]}>
                <div>
                    <h3>Available</h3>
                    <p>{stats?.Available}</p>
                </div>
                <div>
                    <h3>Consumed</h3>
                    <p>{stats?.Consumed}</p>
                </div>

                <div>
                    <h3>Accrued so far</h3>
                    <p>{stats?.Accrued}</p>
                </div>

                <div>
                    <h3>Annual quota</h3>
                    <p>{stats?.Annual}</p>
                </div>
            </div>
        </div>
    );
};

export default Statistics;
