import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FAQ from "./FAQ";
import TopNav from "./TopNav/Topnav";
import detailClass from "./details.module.scss";
import { Get_Courses_Details } from "Service/parent";
import Load from "Reusable/Common/LoadingComponents/logoload";
import { useSearchParams } from "react-router-dom";
import CourseDetails from "Pages/Common/CourseDetails/paymentDetails";
import Demoform from "Pages/Parent/Home/Homepage/requestDemo/Form";
import useStore from "store";
function Details() {
    const Navigate = useNavigate();
    const [cookie] = useCookies(["parentDetails"]);
    const { setdemoFormCourseId } = useStore((item: any) => item);
    const [loadData, setLoad] = useState(true);
    const [Course, setCourse] = useState<any>({});
    const [faq, setFaq] = useState([]);
    const [params] = useSearchParams();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const Get_Courses_Detail = async () => {
        try {
            const allCourses = await Get_Courses_Details(
                "GET",
                null,
                token,
                params.get("courseId")
            );
            if (allCourses.Status === 1) {
                if (allCourses.Courses.Question_Answers.length > 0) {
                    const questions = allCourses.Courses.Question_Answers.map(
                        (item: { Question: any; Answer: any }) => {
                            return {
                                Question: item.Question,
                                Answer: item.Answer,
                                isOpen: false,
                            };
                        }
                    );
                    console.log(questions);
                    setFaq(questions);
                }
                setCourse(allCourses.Courses);
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        } finally {
            setTimeout(() => {
                setLoad(false);
            }, 300);
        }
    };

    useEffect(() => {
        Get_Courses_Detail();
        setdemoFormCourseId(params.get("courseId"));
        return () => {
            setdemoFormCourseId(null);
        };
    }, []);

    const ActionBtn = () => {
        Navigate(
            `/Student-registration-form?web=false&mobileNumber=${
                cookie?.parentDetails?.MobileNumber
            }&countryCode=${String(
                cookie?.parentDetails?.CountryCode
            ).substring(1)}&CourseID=${params.get("courseId")}&Navigation=true`
        );
    };
    return loadData ? (
        <Load />
    ) : (
        <>
            <TopNav />
            <Demoform />
            <div className={detailClass["Course-details"]}>
                <header>
                    <div className={detailClass["img"]}>
                        <img src={Course.CourseImage} alt="course" />
                    </div>
                    <h2>{Course.CourseName}</h2>
                    <div className={detailClass["overlay"]}></div>
                </header>
                <div className={detailClass["Content-wapper"]}>
                    <div className={detailClass["Content"]}>
                        <div className={detailClass["Content-left"]}>
                            <div>
                                <h3>Course Overview</h3>
                                <p>{Course.Overview}</p>
                            </div>
                            <div>
                                <h3>What you'll Learn in this course</h3>
                                <p>{Course.What_You_Learn}</p>
                            </div>
                            <FAQ FAQItems={faq} />
                        </div>
                        <div className={detailClass["content-right"]}>
                            <CourseDetails
                                courseDetails={Course}
                                ActionBtn={ActionBtn}
                                btnName={"Enroll"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;
