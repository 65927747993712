import { toast } from "react-toastify";
import swal from "sweetalert";
//Success Message

export const Success = (message: string) => {
    toast.success(`${message}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
    });
};

// Error Message

export const Error = (message: string) => {
    toast.error(`${message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const SuccessSwal = (message: string, SubMessage: string) => {
    swal(message, SubMessage, "success");
};
export const warningSwal = (message: string, SubMessage: string) => {
    swal(message, SubMessage, "warning");
};
