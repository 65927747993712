import TopNav from "./Topnav";
import React, { ReactNode } from "react";
import Layoutclass from "./Layout.module.css";
import Demoform from "Pages/Parent/Home/Homepage/requestDemo/Form";

type LayoutProps = {
    children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className={Layoutclass["container"]}>
            <Demoform />
            <TopNav />
            <div className={Layoutclass["left"]}>{children}</div>
        </div>
    );
};

export default Layout;
