import Modal from "react-modal";
import React, { useState, useEffect } from "react";
import Styles from "./deomform.module.scss";
import { FaUserAlt } from "react-icons/fa";
import { FaBabyCarriage } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { useForm, SubmitHandler } from "react-hook-form";
import { Inputs, props } from "./types";
import { Services } from "../../../../Service/service";
import { Error } from "../../../../Reusable/utils/Toast";
import { countryCodes } from "../../Login/constants";
import { Country } from "country-state-city";
import { MdEmail } from "react-icons/md";
import { GiClassicalKnowledge } from "react-icons/gi";
import ReactFlagsSelect from "react-flags-select";
import useStore from "store";
Modal.setAppElement("#root");

const DemoForm: React.FC<props> = () => {
    const [countryCode, setCountryCode] = useState<any>({
        countryCode: "IN",
        Code: "91",
    });
    const [courses, setCourses] = useState([]);
    const {
        setThankYouModal,
        CourseId,
        SetDemoFormBeforeLogin,
        DemoFormBeforeLogin,
        SetCourseId,
    } = useStore((item: any) => item);
    let Countries = Country.getAllCountries();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Inputs>();

    //Initial render
    useEffect(() => {
        if (!CourseId) {
            getCourses();
        }
    }, [CourseId]);

    //FETCH COURSES
    async function getCourses() {
        try {
            const courseData = await Services.getCoursesByCategory(
                "POST",
                null,
                null
            );
            setCourses(courseData.Courses);
        } catch (err) {
            Error("something went wrong");
        }
    }
    // const
    // CLOSE FORM
    const closeModal = () => {
        SetDemoFormBeforeLogin(false);
        SetCourseId("");
        reset();
    };

    // SET COUNTRY CODE MOBILE NUMBER
    const Set_Country_CODE = (event: any) => {
        // console.log(event);
        const data = Countries.filter((item) => item.isoCode == event);
        if (data.length > 0) {
            setCountryCode({
                countryCode: data[0].isoCode,
                Code: data[0].phonecode,
            });
        }
    };

    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        let body = JSON.stringify({
            ...data,
            CourseID: CourseId ? CourseId : data.CourseID,
            MobileNumber: `${data.MobileNumber}`,
            CountryCode: `+${countryCode.Code}`,
        });

        Services.request_demo("POST", body, null)
            .then((response: any) => {
                console.log(response);
                if (response.Status === 1) {
                    closeModal();
                    setThankYouModal(true);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                Error("something went wrong please try again");
            });
    };

    return (
        <Modal
            isOpen={DemoFormBeforeLogin}
            contentLabel="Modal"
            className={Styles["modal"]}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <header>
                    <h2>Request Demo</h2>
                    <p>
                        Fill this form to get in front of <span>Expert</span>
                    </p>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <FaUserAlt className={Styles["icon"]} />
                        <input
                            {...register("ParentName", { required: true })}
                            placeholder="Your Name"
                        />
                        {errors.ParentName && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className={`${Styles["form-control"]}`}>
                        <div className={Styles["input-number"]}>
                            {/* <span>+</span> */}
                            <ReactFlagsSelect
                                selected={countryCode.countryCode}
                                showSelectedLabel={false}
                                onSelect={Set_Country_CODE}
                                searchPlaceholder="Search countries"
                                searchable
                                fullWidth={false}
                                // customLabels={customLabels}
                                placeholder=""
                                className={Styles["select-btn"]}
                            />
                        </div>
                        <input
                            style={{ textIndent: "3.2rem" }}
                            type="number"
                            {...register("MobileNumber", { required: true })}
                            placeholder="Mobile Number"
                        />
                        {errors.MobileNumber && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className={Styles["form-control"]}>
                        <FaUserAlt className={Styles["icon"]} />
                        <input
                            {...register("StudentName", { required: true })}
                            placeholder="Child's Name"
                        />
                        {errors.StudentName && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className={Styles["form-control"]}>
                        <FaBabyCarriage className={Styles["icon"]} />
                        <input
                            {...register("StudentAge", {
                                required: "This field is required",
                                min: {
                                    value: 2,
                                    message: "Age should be at least 2",
                                },
                                max: {
                                    value: 12,
                                    message: "Age should be at most 12",
                                },
                            })}
                            placeholder="Child's Age"
                        />
                        {errors.StudentAge && (
                            <span className={Styles["error"]}>
                                {errors?.StudentAge?.message}
                            </span>
                        )}
                    </div>
                    <div className={Styles["form-control"]}>
                        <MdEmail className={Styles["icon"]} />
                        <input
                            type="email"
                            {...register("EmailID", { required: true })}
                            placeholder="Email"
                        />
                        {errors.EmailID && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    {!CourseId && (
                        <div className={Styles["form-control"]}>
                            <GiClassicalKnowledge className={Styles["icon"]} />
                            <select
                                {...register("CourseID", {
                                    required: true,
                                })}
                                defaultValue={""}>
                                <option value="" disabled>
                                    Select Course
                                </option>
                                {courses.map((item: any) => (
                                    <option value={item?.CourseID}>
                                        &#160; {item?.CourseName}
                                    </option>
                                ))}
                            </select>
                            {errors.CourseID && (
                                <span className={Styles["error"]}>
                                    This field is required
                                </span>
                            )}
                        </div>
                    )}

                    <div className={Styles["buttons"]}>
                        <button type="submit" className={Styles["submit"]}>
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={closeModal}
                            className={Styles["close"]}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
