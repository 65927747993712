import React, { useState, useEffect } from "react";
import Layout from "Reusable/Tutor/Layout";
import Earningclasses from "./earning.module.css";
import { Helmet } from "react-helmet";
import Earning from "./earning";
import Payouts from "./Payouts";
import { useSearchParams } from "react-router-dom";
function Index() {
    const [Active, setActive] = useState<any>(1);
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        if (params.get("type") != Active) setActive(Number(params.get("type")));
    }, []);
    const NavHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        let Type = Number(event.currentTarget.getAttribute("custom-btn-type"));
        setActive(Type);
        setParams({ type: String(Type) });
    };
    return (
        <Layout Active={"Earnings"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Earnings</title>
            </Helmet>
            <div className={Earningclasses["Container"]}>
                <div className={Earningclasses["Tutor"]}>
                    <div className={Earningclasses["Navigator"]}>
                        <button
                            custom-btn-type="1"
                            className={
                                Active === 1 ? Earningclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Earnings
                        </button>
                        <button
                            custom-btn-type="2"
                            className={
                                Active === 2 ? Earningclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Payouts
                        </button>
                    </div>
                    {Active === 1 ? <Earning /> : <Payouts />}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
