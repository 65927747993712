import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Error } from "Reusable/utils/Toast";
import StarRatings from "react-star-ratings";
import RatingClasses from "./Rating.module.scss";
import { AiOutlineClose } from "react-icons/ai";
import { RateClass } from "Service/parent";
import Moment from "react-moment";
import { SuccessSwal, warningSwal } from "Reusable/utils/Toast";
Modal.setAppElement("#root");

const Rating: React.FC<props> = ({
    modal,
    setModal,
    getDetails,
    getHomePage,
}) => {
    const {
        handleSubmit,
        reset,
        control,
        register,
        watch,
        formState: { errors },
    } = useForm<Inputs>();

    const [rating, setRating] = useState(0);

    const closeModal = () => {
        setModal(false);
        reset();
        setRating(0);
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const changeRating = (rating: any) => {
        setRating(rating);
    };
    useEffect(() => {
        setRating(getDetails?.Rating);
        if (getDetails?.Review != "NA") {
            reset({ Review: getDetails.Review });
        }
    }, []);
    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        try {
            let cancel = await RateClass(
                "PUT",
                JSON.stringify({ ...formdata, Rating: rating }),
                token,
                getDetails.Scheduled_Classes_StudentID
            );
            if (cancel.Status === 1) {
                closeModal();
                SuccessSwal("Success", cancel.Message);
                getHomePage(2, getDetails.StudentID, true);
            } else {
                warningSwal("Warning", cancel.Message);
            }
        } catch {
            warningSwal("Error", "Something went Wrong try again");
        }
    };

    return (
        <>
            <Modal
                isOpen={modal}
                contentLabel="Modal"
                className={RatingClasses["modal"]}
                // onRequestClose={closeModal}
                overlayClassName={RatingClasses["overlay"]}>
                <div className={RatingClasses["form-wrapper"]}>
                    <button onClick={closeModal}>
                        <AiOutlineClose />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>
                            How was your session with {getDetails?.TutorName} on{" "}
                            {getDetails?.CourseName} held on{" "}
                            <Moment format="DD MMM YYYY">
                                {
                                    new Date(
                                        `${getDetails?.Scheduled_StartDate}T${getDetails?.Scheduled_StartTime}`
                                    )
                                }
                            </Moment>
                            ?
                        </p>
                        <div>
                            <StarRatings
                                rating={rating}
                                starRatedColor="#FFDF00"
                                starHoverColor="#FFDF00"
                                changeRating={changeRating}
                                numberOfStars={5}
                                starDimension="30px"
                                name="rating"
                            />
                        </div>
                        <div className={RatingClasses["form-control"]}>
                            {/* <label htmlFor="">Comments</label> */}
                            <textarea
                                rows={10}
                                placeholder="Comments"
                                {...register("Review", { required: true })}
                            />
                            {errors.Review && <span>Review is required</span>}
                        </div>
                        <div className={RatingClasses["buttons"]}>
                            <button className={RatingClasses["submit"]}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Rating;

interface props {
    modal: any;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    getDetails?: any;
    getHomePage?: any;
}

interface Inputs {
    Rating: number;
    Review: string;
}
