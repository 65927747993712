import { useState, useEffect } from "react";
import Earningclasses from "./earning.module.css";
import { useNavigate } from "react-router";

import { warningSwal } from "../../../Reusable/utils/Toast";
import { Services } from "../../../Service/service";
import TableLoad from "./TableLoad/load";
import Moment from "react-moment";
type Inputs = {
    FromDate?: string;
    ToDate?: string;
};
function Earning() {
    const Navigate = useNavigate();
    const [Loading, setLoading] = useState<boolean>(true);
    const [earnings, set_Earnings] = useState<any>([]);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        Tutor_Earnings();
        // eslint-disable-next-line
    }, []);

    //Fetching  Earnings
    async function Tutor_Earnings() {
        setLoading(true);
        try {
            const AllClasses = await Services.Tutor_Payouts(
                "POST",
                null,
                token
            );
            setTimeout(() => {
                setLoading(false);
            }, 200);
            if (AllClasses.Status === 1) {
                set_Earnings(AllClasses.Get_Payouts);
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            warningSwal("Error", "something went wrong please try again");
        }
    }

    let TableHead: string[] = [
        "Payout ID",
        "Payout Date",
        "Classes",
        "Amount",
        "Payout Status",
    ];
    const detailHandler = (e: any) => {
        let id = e.currentTarget.getAttribute("id");
        Navigate("/Tutor/Earnings/Payout_Details?PayoutId=" + id);
    };
    return (
        <>
            {Loading ? (
                <TableLoad TableHead={TableHead} />
            ) : (
                <div className={Earningclasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                {TableHead.map((item) => (
                                    <th>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {earnings?.length > 0 ? (
                                earnings?.map((item: any) => {
                                    return (
                                        <tr
                                            onClick={detailHandler}
                                            id={item.PayoutID}>
                                            <td>{item.PayoutID}</td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.CreatedAt}
                                                </Moment>
                                            </td>
                                            <td>{item?.EarningsIds?.length}</td>
                                            <td
                                                style={{
                                                    color:
                                                        item.AmountToPay < 0
                                                            ? "red"
                                                            : "green",
                                                }}>
                                                ₹{" "}
                                                {isNaN(item.AmountToPay)
                                                    ? 0.0
                                                    : Number(
                                                          item.AmountToPay
                                                      ).toFixed(2)}
                                            </td>
                                            <td>
                                                <span
                                                    className={
                                                        item.PayoutStatus === 1
                                                            ? Earningclasses[
                                                                  "paid"
                                                              ]
                                                            : Earningclasses[
                                                                  "unpaid"
                                                              ]
                                                    }>
                                                    {item.PayoutStatus === 1
                                                        ? "paid"
                                                        : "pending"}
                                                </span>{" "}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={8}
                                        style={{ textAlign: "center" }}>
                                        No Record Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Earning;
