import React from "react";
import useStore from "../../../../store";
import TabClasses from "./tabs.module.scss";

import { MdOutlineKeyboardArrowRight } from "react-icons/md";

type Tabsprops = {
    setActive: React.Dispatch<React.SetStateAction<string>>;
    Active: string;
};

const Tabs: React.FC<Tabsprops> = ({ Active, setActive }) => {
    // Handles SideNav Show And Hide (managed Globally)
    const parentSideNav = useStore((state: any) => state.parentSideNav);
    const setParentSideNav = useStore((state: any) => state.setParentSideNav);

    const Tabs: string[] = [
        "HOME",
        "ALL COURSES",
        "WALLET TRANSACTIONS",
        "PROFILE",
    ];

    // Handling Active tabs
    const ButtonHandler = (e: React.MouseEvent<HTMLElement>) => {
        if (parentSideNav) {
            setParentSideNav();
        }
        let key = e.currentTarget.getAttribute("custom-key");
        setActive(String(key));
    };
    return (
        <>
            <div
                className={`${parentSideNav ? TabClasses["overlay"] : ""}`}
                onClick={() => {
                    setParentSideNav();
                }}></div>
            <div
                className={`${TabClasses["container"]} ${
                    parentSideNav ? TabClasses["showTabs"] : ""
                } `}>
                {Tabs.map((item: string, index) => (
                    <button
                        key={index}
                        className={
                            Active === item ||
                            (Active == "ContactUs" && item == "HOME")
                                ? TabClasses["Active-btn"]
                                : ""
                        }
                        onClick={ButtonHandler}
                        custom-key={item}>
                        {item}
                        <MdOutlineKeyboardArrowRight
                            className={TabClasses["icon"]}
                        />
                    </button>
                ))}
            </div>
        </>
    );
};

export default Tabs;
