import React from "react";
import profileClasses from "./profile.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import nocourseFound from "Assets/nocourseFound.jpg";
import { useNavigate } from "react-router";
type courses = {
    courses: course[];
    setTab: React.Dispatch<React.SetStateAction<string>>;
};
type course = {
    CourseImage: string;
    CourseName: string;
    BatchSize: string;
    ToAge: number;
    FromAge: number;
    CourseID: number;
    EnrollCourseID: number;
};

const MyCourses: React.FC<courses> = ({ courses, setTab }) => {
    const Navigate = useNavigate();
    const viewHandler = (e: React.MouseEvent<HTMLElement>) => {
        let courseid = e.currentTarget.getAttribute("course-id");
        let enrollid = e.currentTarget.getAttribute("enroll-id");
        Navigate(
            `/Parent/EnrolledCourse?StudentId=${localStorage.getItem(
                "student"
            )}`,
            { state: { courseid: courseid, enrollid: enrollid } }
        );
    };
    return (
        <div className={profileClasses["courses"]}>
            <h3>My Courses</h3>
            {courses.length === 0 ? (
                <div className={profileClasses["noCourseFound"]}>
                    <img src={nocourseFound} alt="course" />
                    <p>
                        Discover a range of programs designed to cater to your
                        child's unique learning needs.
                        <button onClick={() => setTab("ALL COURSES")}>
                            Courses
                        </button>
                    </p>
                </div>
            ) : (
                <div className={profileClasses["course-container"]}>
                    <AnimatePresence>
                        {courses.map((item: course, index: any) => (
                            <motion.div
                                className={profileClasses["course"]}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    duration: 0.5,
                                    delay: index * 0.2,
                                }}
                                course-id={item.CourseID}
                                enroll-id={item.EnrollCourseID}
                                onClick={viewHandler}>
                                <img src={item.CourseImage} alt={"course"} />
                                <h5>{item.BatchSize}</h5>
                                <h3>
                                    {item.CourseName}
                                    <br /> (age {item.FromAge}-{item.ToAge})
                                </h3>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            )}
        </div>
    );
};

export default MyCourses;
