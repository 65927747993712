import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./complete.module.scss";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Inputs, props } from "./types";
import { Services } from "Service/service";
import { Error, warningSwal } from "Reusable/utils/Toast";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
Modal.setAppElement("#root");

const DemoForm: React.FC<any> = ({
    modal,
    setModal,
    getDetails,
    id,
    setCompleteData,
    completeData,
}) => {
    const {
        handleSubmit,
        reset,
        register,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm<Inputs>({ defaultValues: { is_partial_completed: false } });
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const [lessonPlan, setLessonPlan] = useState([]);
    const [partial, setPartial] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [loading, setLoading] = useState(false);

    const findNonCommonObjects = (arr1: any, arr2: any) => {
        const arr2Set = new Set(arr2.map((obj: any) => JSON.stringify(obj)));
        return arr1.filter((obj: any) => !arr2Set.has(JSON.stringify(obj)));
    };

    const watchData = watch();

    useEffect(() => {
        if (completeData) {
            setValue("Comments", completeData);
        }
    }, []);
    // CLOSE FORM
    const closeModal = () => {
        setModal(false);
        reset();
    };

    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        setLoading(true);
        let Body: any = {
            CourseID: getDetails?.data?.CourseID,
            DemoClassID: id,
            DemoClassPrice: getDetails?.data?.DemoClassPrice,
            DemoStartTime: getDetails?.data?.DemoStartTime,
            Students: getDetails?.data?.Students,
            FeePerHour: getDetails?.data?.FeePerHour,
            ActionComments: formdata.Comments,
            TutorID: getDetails?.data?.TutorID,
            TutorEmailID: getDetails?.data?.TutorEmailID,

            BatchSize: getDetails?.data?.BatchSize,
            DemoStartDate:
                getDetails?.data?.DemoStartDate &&
                getDetails?.data?.DemoStartDate != "NA" &&
                String(getDetails?.data?.DemoStartDate).split("T")[0],
        };

        if (completeData) {
            try {
                const CompleteClass = await Services.updateCompleteComments(
                    "PUT",
                    JSON.stringify({ ActionComments: formdata.Comments }),
                    token,
                    id
                );
                setLoading(false);
                if (CompleteClass.Status === 1) {
                    swal("Completed", CompleteClass.Message, "success");
                    setModal(false);
                    getDetails?.details(id);
                    setCompleteData(null);
                } else {
                    warningSwal("Warning", CompleteClass?.Message);
                }
            } catch (err) {
                setLoading(false);
                console.log(err);
                warningSwal("Error", "Something went wrong please try again");
            }
        } else {
            try {
                const CompleteClass = await Services.CompleteClassDemo(
                    "POST",
                    JSON.stringify(Body),
                    token
                );
                setLoading(false);
                if (CompleteClass.Status === 1) {
                    swal("Completed", CompleteClass.Message, "success");
                    setModal(false);
                    getDetails?.details(id);
                    setCompleteData(null);
                } else {
                    warningSwal("Warning", CompleteClass?.Message);
                }
            } catch (err) {
                setLoading(false);
                console.log(err);
                warningSwal("Error", "Something went wrong please try again");
            }
        }
    };
    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            minHeight: state.hasValue ? "3.3rem" : "3rem", // Adjust the height based on your preference
        }),
    };
    const onPlanSelect = (data: any) => {
        setPartial(findNonCommonObjects(lessonPlan, data));
        setValue("Lesson", []);
        setSelectedPlan(data);
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Complete Class</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            Comments <span>:</span>
                        </label>

                        <textarea
                            {...register("Comments", {
                                required: false,
                            })}></textarea>
                    </div>

                    {/* eslint-disable-next-line */}

                    <div className={Styles["buttons"]}>
                        <button
                            type="submit"
                            className={Styles["submit"]}
                            disabled={loading}>
                            {loading ? "...please wait" : "Complete"}
                        </button>
                        <button
                            type="button"
                            onClick={closeModal}
                            className={Styles["close"]}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
