import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import profileClasses from "../profileDetailsModal/profile.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { Contact_US, Enquiry } from "Service/parent";
import { Error, SuccessSwal } from "Reusable/utils/Toast";
import { useCookies } from "react-cookie";
import { Get_Courses } from "Service/parent";
import {
    setLocalStorageWithExpiration,
    getLocalStorageWithExpiration,
} from "Reusable/utils/expiryLocalData";
type props = {
    showModal: string;
    setModal: React.Dispatch<React.SetStateAction<string>>;
    Data?: any;
};

const Report: React.FC<props> = ({ setModal, showModal, Data }) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<any>();
    const [cookie, setCookie] = useCookies(["parentDetails"]);
    const [Courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setModal("");
        reset();
    };
    const get_courses = async () => {
        try {
            const allCourses = await Get_Courses("POST", null, token);
            if (allCourses.Status === 1) {
                setCourses(allCourses.Courses);
                setLocalStorageWithExpiration("allCourses", allCourses.Courses);
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        }
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    useEffect(() => {
        reset({
            FirstName: cookie?.parentDetails.FirstName,
            LastName: cookie?.parentDetails.LastName,
            EmailID: cookie?.parentDetails.EmailID,
            MobileNumber: cookie?.parentDetails.MobileNumber,
        });
        if (
            showModal === "Contact" &&
            !getLocalStorageWithExpiration("allCourses")
        ) {
            get_courses();
        } else {
            setCourses(getLocalStorageWithExpiration("allCourses"));
        }
    }, []);
    const submit: SubmitHandler<any> = async (formData) => {
        setLoading(true);
        let url = Contact_US;
        if (showModal === "Enquiry") {
            url = Enquiry;
        }
        try {
            const [profileData] = await Promise.all([
                url("POST", JSON.stringify(formData), token),
            ]);
            if (profileData?.Status === 1) {
                SuccessSwal("Form Submitted", profileData.Message);
                setModal("");

                closeModal();
                reset();
            } else {
                Error(profileData.Message);
            }
            setTimeout(() => {
                setLoading(false);
            }, 200);
        } catch {
            setLoading(false);
            Error("something Went wrong try again");
        }
    };

    return (
        <>
            <Modal
                isOpen={showModal.length > 0 ? true : false}
                contentLabel="Modal"
                className={profileClasses["modal"]}
                onRequestClose={closeModal}
                overlayClassName={profileClasses["overlay"]}>
                <div className={profileClasses["Container"]}>
                    {/* <div className={}></div> */}
                    <header>
                        <h3>
                            {showModal === "Contact" ? "Contact Us" : "Enquiry"}
                        </h3>
                        <button onClick={closeModal}>
                            <IoMdClose />
                        </button>
                    </header>

                    <form onSubmit={handleSubmit(submit)}>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                First Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("FirstName", { required: false })}
                                disabled
                                placeholder="Enter First Name"
                            />
                            {errors?.FirstName && (
                                <span>First Name is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Last Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("LastName", { required: false })}
                                disabled
                                placeholder="Enter Last Name"
                            />
                            {errors?.LastName && (
                                <span>Last Name is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Email <span>*</span>
                            </label>

                            <input
                                type="email"
                                {...register("EmailID", { required: false })}
                                disabled
                                placeholder="Enter Email Address"
                            />
                            {errors?.EmailID && (
                                <span>Email Address is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Mobile Number <span>*</span>
                            </label>

                            <input
                                type="number"
                                {...register("MobileNumber", {
                                    required: false,
                                })}
                                disabled
                                placeholder="Enter Mobile Number"
                            />
                            {errors?.MobileNumber && (
                                <span>Mobile Number is required</span>
                            )}
                        </div>
                        {showModal === "Contact" && (
                            <div className={profileClasses["form_control"]}>
                                <label htmlFor="">
                                    {" "}
                                    Course <span>*</span>
                                </label>
                                <select
                                    {...register("CourseID", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Course
                                    </option>
                                    {Courses?.map((item: any) => (
                                        <option value={item.CourseID}>
                                            {item.CourseName}
                                        </option>
                                    ))}
                                </select>

                                {errors?.CourseID && (
                                    <span>Course Name is Required</span>
                                )}
                            </div>
                        )}
                        <div
                            className={profileClasses["form_control"]}
                            style={{ gridColumn: "1/-1" }}>
                            <label htmlFor="">Comments</label>
                            <textarea
                                rows={7}
                                placeholder="comments"
                                {...register("Comments", { required: true })}
                            />
                            {errors?.Comments && (
                                <span>Comment is Required</span>
                            )}
                        </div>
                        <div className={profileClasses["Action-btns"]}>
                            <button type="submit" disabled={loading}>
                                {!loading ? "Submit" : "...Please wait"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Report;
