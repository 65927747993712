import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./reschedule.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { Inputs, props } from "./types";
import { Services } from "../../../../Service/service";
import { Error } from "../../../../Reusable/utils/Toast";
import { AiOutlineClose } from "react-icons/ai";
import Load from "Reusable/Common/LoadingComponents/loader";
import moment from "moment";
import swal from "sweetalert";
Modal.setAppElement("#root");

const DemoForm: React.FC<props> = ({
    rescheduleModal,
    setRescheduleModal,
    data,
}) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<Inputs>();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const [loading, setLoading] = useState(false);
    const backendDate = new Date(data.Scheduled_StartDate); // Date received from the backend
    const minDate = new Date(backendDate);
    const currDate = new Date();

    minDate.setDate(backendDate.getDate() - 2); // Date minus 2 days
    const effectiveMinDate = minDate > currDate ? minDate : currDate;
    const maxDate = new Date(backendDate);
    maxDate.setDate(backendDate.getDate() + 2);

    useEffect(() => {
        setValue(
            "ReScheduled_StartDate",
            data.Scheduled_StartDate.substr(0, 10)
        );
        // eslint-disable-next-line
    }, []);

    // CLOSE FORM
    const closeModal = () => {
        setRescheduleModal(false);
        reset();
    };

    //Calculate End Time Based on Batch Duration
    //Calculate End Time Based on Batch Duration
    const calculateScheduledEndTime = (
        startDate: string,
        startTime: string,
        additionalMinutes: number
    ) => {
        const scheduledStartDateTime = new Date(`${startDate} ${startTime}`);
        console.log(startDate, startTime, additionalMinutes);
        // Add additional minutes
        const scheduledEndDateTime = new Date(
            scheduledStartDateTime.getTime() + additionalMinutes * 60000
        );
        console.log(scheduledEndDateTime);
        // Format the result
        const scheduledEndDate =
            moment(scheduledEndDateTime).format("YYYY-MM-DD");
        console.log(scheduledEndDate);
        const scheduledEndTime = scheduledEndDateTime
            .toTimeString()
            .split(" ")[0];

        return { scheduledEndDate, scheduledEndTime };
    };

    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = (formdata) => {
        // Required to send intial Start Date and parent Details (parentId,RM ID's) along with form Data
        const parentDetails = [];

        for (let i = 0; i < data?.Students?.length; i++) {
            let RM = [
                data?.Students[i]?.Lead_RM,
                data?.Students[i]?.Buddy_RM,
                data?.Students[i]?.SecondSift_RM,
            ];
            let details = {
                ParentID: data?.Students[i].ParentID,

                StudentID: data?.Students[i].StudentID,
            };
            parentDetails.push(details);
        }
        let result = calculateScheduledEndTime(
            formdata?.ReScheduled_StartDate,
            formdata.ReScheduled_StartTime,
            data.ClassDuration
        );
        let allRms = [
            data?.Students[0]?.Lead_RM,
            data?.Students[0]?.Buddy_RM,
            data?.Students[0]?.SecondShift_RM_Morning,
            data?.Students[0]?.SecondShift_RM_Evening,
        ].filter((item) => item != 0);
        let body = JSON.stringify({
            ...formdata,
            Scheduled_StartDate: data?.Scheduled_StartDate,
            ZoomMeetingID: data?.ZoomMeetingID,
            ScheduleClassID: data?.ScheduleClassID,
            RM: allRms.filter(
                (value, index, self) => self.indexOf(value) === index
            ),
            parentDetails: parentDetails,
            CourseName: data?.CourseName,
            ScheduleCreatedBy: data?.ScheduleCreatedBy,
            BatchName: data.BatchName,
            ScheduleCreatedByType: data?.ScheduleCreatedByType,
            RequestType: 1,
            ReScheduled_EndTime: result.scheduledEndTime,
            ReScheduled_EndDate: result.scheduledEndDate,
        });
        setLoading(true);
        Services.Reschedule("PUT", body, token, data.id)
            .then((response: any) => {
                console.log(response);
                if (response.Status === 1) {
                    closeModal();
                    swal("Requested", response.Message, "success");
                    data.details(data?.id);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                Error("something went wrong please try again");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            isOpen={rescheduleModal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Reschedule</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Date">Reschedule Date</label>
                        <div className={Styles["date-input-container"]}>
                            <input
                                className={Styles["date-input"]}
                                type="date"
                                {...register("ReScheduled_StartDate", {
                                    required: true,
                                })}
                                min={effectiveMinDate
                                    .toISOString()
                                    .substr(0, 10)}
                                max={maxDate.toISOString().substr(0, 10)}
                            />
                        </div>
                        {errors.ReScheduled_StartDate && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">Start Time</label>
                        <div className={Styles["date-input-container"]}>
                            <input
                                className={Styles["date-input"]}
                                type="time"
                                step={300}
                                {...register("ReScheduled_StartTime", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors.ReScheduled_StartTime && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    {/* <div className={Styles["form-control"]}>
                        <label htmlFor="">End Time</label>
                        <div className={Styles["date-input-container"]}>
                            <input
                                className={Styles["date-input"]}
                                type="time"
                                {...register("ReScheduled_EndTime", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors.ReScheduled_EndTime && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div> */}
                    {loading ? (
                        <Load />
                    ) : (
                        <div className={Styles["buttons"]}>
                            <button type="submit" className={Styles["submit"]}>
                                Reschedule
                            </button>
                            <button
                                type="button"
                                onClick={closeModal}
                                className={Styles["close"]}>
                                Cancel
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
