import { useState, useEffect } from "react";
import Layout from "../../../Reusable/Tutor/Layout";
import { Helmet } from "react-helmet";
import { Services } from "../../../Service/service";
import SkeletonLoad from "./TableLoad/load";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import TutorClasses from "./request.module.scss";
import { Error, SuccessSwal, warningSwal } from "../../../Reusable/utils/Toast";
import swal from "sweetalert";
import Swal from "sweetalert2";
import FallingLines from "../../../Reusable/Common/LoadingComponents/fallingLines/fallingLines";
import { useSearchParams } from "react-router-dom";

import { AiFillEye } from "react-icons/ai";
function Approval() {
    const [params, setParams] = useSearchParams();
    const Navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    let TutorDetails: any = localStorage.getItem("LoginDetails");
    const [approveLoad, setApproveLoad] = useState(false);
    const [Active, setActive] = useState<number>(1);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        if (params.get("isApproved")) {
            let id = Number(params.get("isApproved"));
            if (id == 3) {
                getBatchRequests(1, 3);
            } else {
                getBatchRequests(id, 1);
            }
            setActive(id);
        } else {
            getBatchRequests(1, 1);
        }
    }, []);

    // ---GET BATCH REQUESTS

    async function getBatchRequests(Type: number, status: number) {
        try {
            const AllClasses = await Services.getBatchRequests(
                "GET",
                null,
                token,
                Type,
                status
            );
            setTimeout(() => {
                setLoading(false);
                setApproveLoad(false);
            }, 200);
            setData(AllClasses.ScheduleClasess);
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }

    // API CALL FOR APPROVE REJECT ACTION BUTTONS
    async function ApproveRejectRequest(
        RequestId: number,
        BatchID: string,
        IsAccepted: number,
        BatchCreatedBy: string,
        BatchName: string,
        BatchType: string,
        CourseName: string
    ) {
        let loginDetails: any = localStorage.getItem("LoginDetails");
        if (loginDetails && loginDetails.length > 0) {
            loginDetails = JSON.parse(loginDetails);
        }
        let RMS = [
            loginDetails?.Lead_RM,
            loginDetails?.Buddy_RM,
            loginDetails?.SecondShift_RM_Morning,
            loginDetails?.SecondShift_RM_Evening,
        ];
        try {
            const Response = await Services.ApproveRejectBatchRequests(
                "PUT",
                JSON.stringify({
                    BatchID,
                    IsAccepted,
                    BatchCreatedBy,
                    BatchName,
                    TutoRms: RMS.filter((item) => item != 0),
                    TutorName: `${loginDetails?.FirstName} ${loginDetails?.LastName}`,
                    BatcBatchCreatedByType: BatchType,
                    CourseName,
                }),
                token,
                RequestId
            );

            if (Response.Status === 1) {
                SuccessSwal(
                    "Success",
                    IsAccepted == 1
                        ? "Batch Approved Successfull"
                        : "Batch rejected successfully"
                );
                setApproveLoad(true);
                if (Active == 3) {
                    getBatchRequests(1, 3);
                } else {
                    getBatchRequests(Active, 1);
                }
            } else {
                swal("Oops...", Response.Message, "error");
                // Error("Some thing went wrong try again");
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            warningSwal("Error", "something went wrong please try again");
        }
    }

    const Warning = (e: any, type: number) => {
        e.stopPropagation();
        let Message;
        let RequestId = Number(e.currentTarget.getAttribute("custom-id"));
        let BatchId = e.currentTarget.getAttribute("custom-batchId");
        let BatchName = e.currentTarget.getAttribute("custom-batchname");
        let BatchType = e.currentTarget.getAttribute("custom-type");
        let CourseName = e.currentTarget.getAttribute("custom-coursename");

        let BatchCreatedBy = String(
            e.currentTarget.getAttribute("custom-createBy")
        );

        if (type == 1) {
            // Approve==1,reject==2

            Message = "Are you sure you want to Approve the Batch?";
        } else {
            Message = "Are You Sure You want to Reject the Batch?";
        }

        Swal.fire({
            title: Message,
            showDenyButton: true,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            customClass: {
                actions: TutorClasses["popup-actions"],
                cancelButton: "order-1 right-gap",
                confirmButton: TutorClasses["logout-popup"],
                denyButton: "order-3",
            },
        }).then((result: any) => {
            if (result.isConfirmed) {
                ApproveRejectRequest(
                    RequestId,
                    BatchId,
                    type,
                    BatchCreatedBy,
                    BatchName,
                    BatchType,
                    CourseName
                );
            }
        });
    };
    const handleNavigate = (e: React.MouseEvent<HTMLElement>) => {
        let id = e.currentTarget.getAttribute("id");
        let approverequest = e.currentTarget.getAttribute(
            "custom-approverequest"
        );
        Navigate(
            `/Tutor/Batch-request/Details?batchid=${id}&requestId=${approverequest}&isApproved=${Active}`
        );
    };

    // EVENT TO GET ACTIVE PAST AND CANCELLED CLASSES
    const NavHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        let Type = Number(event.currentTarget.getAttribute("custom-btn-type"));
        setActive(Type);
        setLoading(true);
        if (Type == 3) {
            getBatchRequests(1, 3);
        } else {
            getBatchRequests(Type, 1);
        }
        setParams({ isApproved: String(Type) });
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Batch Request</title>
            </Helmet>
            {approveLoad && <FallingLines />}
            <Layout Active={"Batch Request"}>
                <div className={TutorClasses["Container"]}>
                    <div className={TutorClasses["Tutor"]}>
                        <h3>Batches</h3>
                        <div className={TutorClasses["Navigator"]}>
                            <button
                                custom-btn-type="0"
                                className={
                                    Active === 0 ? TutorClasses["active"] : ""
                                }
                                onClick={NavHandler}>
                                Pending
                            </button>
                            <button
                                custom-btn-type="1"
                                className={
                                    Active === 1 ? TutorClasses["active"] : ""
                                }
                                onClick={NavHandler}>
                                Ongoing
                            </button>
                            <button
                                custom-btn-type="2"
                                className={
                                    Active === 2 ? TutorClasses["active"] : ""
                                }
                                onClick={NavHandler}>
                                Rejected
                            </button>
                            <button
                                custom-btn-type="3"
                                className={
                                    Active === 3 ? TutorClasses["active"] : ""
                                }
                                onClick={NavHandler}>
                                Finished
                            </button>
                        </div>
                        {loading ? (
                            <SkeletonLoad />
                        ) : (
                            <div className={TutorClasses["Table"]}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Batch Name</th>
                                            <th>Batch Size</th>
                                            <th>Course Name</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? (
                                            data.map(
                                                (item: any, index: any) => {
                                                    return (
                                                        <tr
                                                            id={item.BatchID}
                                                            onClick={
                                                                handleNavigate
                                                            }
                                                            key={index}
                                                            custom-approverequest={
                                                                item.BatchApproveRequestID
                                                            }>
                                                            <td
                                                                style={{
                                                                    maxWidth:
                                                                        "200px",
                                                                }}>
                                                                {item.BatchName}
                                                            </td>
                                                            <td>
                                                                {item.BatchSize}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.CourseName
                                                                }
                                                            </td>

                                                            <td>
                                                                <Moment format="DD-MM-YYYY">
                                                                    {
                                                                        item.CreatedAt
                                                                    }
                                                                </Moment>{" "}
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className={
                                                                        TutorClasses[
                                                                            "Action-btns"
                                                                        ]
                                                                    }>
                                                                    {item.IsAccepted ===
                                                                    1 ? (
                                                                        <p
                                                                            className={
                                                                                TutorClasses[
                                                                                    "complete"
                                                                                ]
                                                                            }>
                                                                            Approved
                                                                        </p>
                                                                    ) : item.IsAccepted ==
                                                                      2 ? (
                                                                        <p
                                                                            className={
                                                                                TutorClasses[
                                                                                    "reject"
                                                                                ]
                                                                            }>
                                                                            Rejected
                                                                        </p>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                gap: "0.5rem",
                                                                            }}>
                                                                            <button
                                                                                custom-id={
                                                                                    item.BatchApproveRequestID
                                                                                }
                                                                                custom-batchId={
                                                                                    item.BatchID
                                                                                }
                                                                                custom-createBy={
                                                                                    item.BatchCreatedBy
                                                                                }
                                                                                custom-batchname={
                                                                                    item.BatchName
                                                                                }
                                                                                custom-type={
                                                                                    item.BatchCreatedByType
                                                                                }
                                                                                custom-coursename={
                                                                                    item.CourseName
                                                                                }
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    Warning(
                                                                                        e,
                                                                                        1
                                                                                    )
                                                                                }>
                                                                                Approve
                                                                            </button>
                                                                            <button
                                                                                custom-id={
                                                                                    item.BatchApproveRequestID
                                                                                }
                                                                                custom-batchId={
                                                                                    item.BatchID
                                                                                }
                                                                                custom-createBy={
                                                                                    item.BatchCreatedBy
                                                                                }
                                                                                custom-coursename={
                                                                                    item.CourseName
                                                                                }
                                                                                custom-batchname={
                                                                                    item.BatchName
                                                                                }
                                                                                custom-type={
                                                                                    item.BatchCreatedByType
                                                                                }
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    Warning(
                                                                                        e,
                                                                                        2
                                                                                    )
                                                                                }>
                                                                                Reject
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    <button
                                                                        style={{
                                                                            backgroundColor:
                                                                                "inherit",
                                                                            color: "black",
                                                                            fontSize:
                                                                                "1rem",
                                                                            padding:
                                                                                "0",
                                                                        }}
                                                                        id={
                                                                            item.BatchID
                                                                        }
                                                                        onClick={
                                                                            handleNavigate
                                                                        }
                                                                        custom-approverequest={
                                                                            item.BatchApproveRequestID
                                                                        }>
                                                                        <AiFillEye />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>
                                                    {" "}
                                                    no records Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Approval;
