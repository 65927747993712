import React, { useState, useEffect } from "react";
import profileClasses from "./profile.module.scss";
import Profilepic from "Assets/profile.svg";
import { useCookies } from "react-cookie";
import { MdEdit } from "react-icons/md";
import MyCourse from "../Profile/MyCourses";
import Documents from "./Documents";
import StudentProfileModal from "./profileDetails/profile";
import Loader from "Reusable/Common/LoadingComponents/loader/loader";
import { MdDelete } from "react-icons/md";
import {
    Get_Kid_Details,
    get_Enrolled_Course_List_By_Student,
    get_parent_uploads,
    Delete_kid,
} from "Service/parent";
import { warningSwal, SuccessSwal } from "Reusable/utils/Toast";
import { profileData } from "./types";
import useStore from "../../../store";
import { useNavigate } from "react-router";
import { ConfirmationDiaolog } from "Reusable/utils/utils";
type props = {
    setTab: React.Dispatch<React.SetStateAction<string>>;
};
const Index: React.FC<props> = ({ setTab }) => {
    const [cookie, setCookie] = useCookies(["StudentDetails", "parentDetails"]);
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [Courses, setCourses] = useState([]);
    const [profileData, setProfileData] = useState<profileData>();
    const [profileModal, setProfileModal] = useState(false);
    const [uploads, setUploads] = useState([]);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const { setActiveKid, ActiveKid } = useStore((store: any) => store);
    const ParentUpload = async (UploadType: String) => {
        if (!loading) setLoading(true);
        try {
            const [Documents] = await Promise.all([
                get_parent_uploads(
                    "GET",
                    null,
                    token,
                    localStorage.getItem("student"),
                    UploadType
                ),
            ]);
            if (Documents?.Status === 1) {
                setUploads(Documents?.ParentUploads);
            }

            setTimeout(() => {
                setLoading(false);
            }, 200);
        } catch {
            setLoading(false);
            warningSwal("Error", "something Went wrong try again");
        }
    };
    const DeleteKid = async () => {
        if (!loading) setLoading(true);
        try {
            const [Documents] = await Promise.all([
                Delete_kid(
                    "DELETE",
                    null,
                    token,
                    localStorage.getItem("student")
                ),
            ]);
            if (Documents?.Status === 1) {
                let id = localStorage.getItem("student");
                localStorage.removeItem("HomepageData");
                let Active = cookie?.StudentDetails.filter(
                    (item: any) => item.StudentID != Number(id)
                );
                setCookie("StudentDetails", Active);
                setTab("HOME");
                SuccessSwal(
                    "Kid Deleted",
                    `${ActiveKid} profile Deleted Successfully`
                );
                if (Active && Active?.length > 0) {
                    setActiveKid(
                        `${Active[0]?.FirstName} ${Active[0]?.LastName}`
                    );
                    Navigate(`/Parent/home?StudentId=${Active[0].StudentID}`);
                    setActiveKid(
                        `${Active[0]?.FirstName} ${Active[0]?.LastName}`
                    );
                    localStorage.setItem("student", Active[0].StudentID);
                } else {
                    Navigate(`/Parent/home`);
                    setActiveKid("");
                    localStorage.removeItem("student");
                }
            }

            setTimeout(() => {
                setLoading(false);
            }, 200);
        } catch {
            setLoading(false);
            warningSwal("Error", "something Went wrong try again");
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const [profileData, Courses, Parentuploads] = await Promise.all(
                    [
                        Get_Kid_Details(
                            "GET",
                            null,
                            token,
                            localStorage.getItem("student")
                        ),
                        get_Enrolled_Course_List_By_Student(
                            "GET",
                            null,
                            token,
                            localStorage.getItem("student"),
                            "ALL"
                        ),
                        get_parent_uploads(
                            "GET",
                            null,
                            token,
                            localStorage.getItem("student"),
                            1
                        ),
                    ]
                );
                if (profileData?.Status === 1) {
                    setProfileData(profileData.Students);
                }
                if (Courses?.Status === 1) {
                    setCourses(Courses?.Courses);
                }
                if (Parentuploads?.Status === 1) {
                    setUploads(Parentuploads?.ParentUploads);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            } catch {
                setLoading(false);
                warningSwal("Warning", "something Went wrong try again");
            }
        };
        if (localStorage.getItem("student")) {
            fetchProfileData();
        } else {
            setLoading(false);
        }
    }, []);

    const DeleteKid_handler = () => {
        let Message = `Are you Sure You Want to Delete ${ActiveKid}?`;
        ConfirmationDiaolog(Message, "Delete", DeleteKid);
    };
    return (
        <>
            {loading && <Loader />}
            <div className={profileClasses["Container"]}>
                {profileModal && (
                    <StudentProfileModal
                        setModal={setProfileModal}
                        showModal={profileModal}
                        Data={profileData}
                        setProfileData={setProfileData}
                        EditMode={true}
                    />
                )}
                {localStorage.getItem("student") && (
                    <div className={profileClasses["profile"]}>
                        <header>
                            <img src={Profilepic} alt="profile" />
                            <div className={profileClasses["profile_details"]}>
                                <div>
                                    <h3 onClick={() => setProfileModal(true)}>
                                        {profileData?.FirstName
                                            ? profileData?.FirstName
                                            : "Kid Profile"}{" "}
                                        {profileData?.LastName}
                                        <MdEdit title="Edit Kid" />
                                    </h3>
                                    {/* <button
                                        title="Delete Kid Profile"
                                        onClick={DeleteKid_handler}>
                                        <MdDelete size={20} />
                                    </button> */}
                                </div>
                                <p>{profileData?.EmailID}</p>
                                <p>{cookie?.parentDetails?.MobileNumber}</p>
                            </div>
                        </header>
                    </div>
                )}
                <div className={profileClasses["enrolledCourses"]}>
                    <MyCourse courses={Courses} setTab={setTab} />
                    <Documents
                        ParentUpload={ParentUpload}
                        uploads={uploads}
                        setUploads={setUploads}
                        setLoading={setLoading}
                    />
                </div>
            </div>
        </>
    );
};

export default Index;
