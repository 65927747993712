import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import profileClasses from "./profile.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import Picture from "./Picture";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import { Update_Parent_Profile, get_parent_Details } from "Service/parent";
import { SuccessSwal, warningSwal } from "Reusable/utils/Toast";
import { useCookies } from "react-cookie";
type props = {
    showModal: boolean;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    Data?: any;
};
export type profileUploaTypes = {
    FirstName: string;
    LastName: string;
    EmailID: string;
    Address: string;
    Country: string;
    City: string;
    State: string;
    Pincode: string;
    ProfilePicture: string;
};

const Report: React.FC<props> = ({ setModal, showModal, Data }) => {
    const {
        register,
        setValue,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<profileUploaTypes>();
    const [cookie, setCookie] = useCookies(["parentDetails"]);

    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setModal(false);
        reset();
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const getDetails = async () => {
        try {
            const Response = await get_parent_Details("GET", null, token);
            if (Response.Status == 1) {
                console.log(Response);
                setCookie("parentDetails", { ...Response.Parent });
                reset({
                    FirstName: Response.Parent?.FirstName,
                    LastName: Response.Parent?.LastName,
                    EmailID: Response.Parent?.EmailID,
                    Address: Response.Parent?.Address,
                    Pincode: Response.Parent?.Pincode,
                    Country: Response.Parent?.Country,
                    State: Response.Parent?.State,
                    City: Response.Parent?.City,
                });
            }
        } catch (err) {
            setLoading(false);
            warningSwal("Warning", "something Went wrong try again");
        }
    };
    useEffect(() => {
        getDetails();
    }, []);
    const submit: SubmitHandler<profileUploaTypes> = async (formData) => {
        setLoading(true);
        try {
            const [profileData] = await Promise.all([
                Update_Parent_Profile("PUT", JSON.stringify(formData), token),
            ]);
            if (profileData?.Status === 1) {
                SuccessSwal("success", "Profile updated Successfully");
                setCookie("parentDetails", {
                    ...cookie?.parentDetails,
                    ...formData,
                });
                // setProfileData((prev: any) => ({ ...prev, ...formData }));

                closeModal();
                reset();
            } else {
                warningSwal("warning", profileData.Message);
            }
            setTimeout(() => {
                setLoading(false);
            }, 200);
        } catch {
            setLoading(false);
            Error("something Went wrong try again");
        }
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                contentLabel="Modal"
                className={profileClasses["modal"]}
                onRequestClose={closeModal}
                overlayClassName={profileClasses["overlay"]}>
                <div className={profileClasses["Container"]}>
                    {/* <div className={}></div> */}
                    <header>
                        <h3>Update Profile</h3>
                        <button onClick={closeModal}>
                            <IoMdClose />
                        </button>
                    </header>
                    <form onSubmit={handleSubmit(submit)}>
                        <Picture
                            setValue={setValue}
                            data={cookie?.parentDetails}
                        />
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                First Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("FirstName", { required: true })}
                                placeholder="Enter First Name"
                            />
                            {errors?.FirstName && (
                                <span>First Name is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Last Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("LastName", { required: true })}
                                placeholder="Enter Last Name"
                            />
                            {errors?.LastName && (
                                <span>Last Name is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Email <span>*</span>
                            </label>

                            <input
                                type="email"
                                {...register("EmailID", { required: true })}
                                placeholder="Enter Email Address"
                            />
                            {errors?.EmailID && (
                                <span>Email Address is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Address <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("Address", { required: true })}
                                placeholder="Enter Email Address"
                            />
                            {errors?.Address && (
                                <span>Address is required</span>
                            )}
                        </div>
                        {/* <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Gender <span>*</span>
                            </label>

                            <select
                                {...register("Gender", { required: true })}
                                defaultValue={"NA"}>
                                <option value="NA" disabled>
                                    Select Gender
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            {errors?.Gender && <span>Gender is required</span>}
                        </div> */}

                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                City <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("City", { required: true })}
                            />
                            {errors?.City && <span>City is required</span>}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                State <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("State", { required: true })}
                            />
                            {errors?.State && <span>State is required</span>}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Country <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("Country", { required: true })}
                            />
                            {errors?.Country && (
                                <span>Country is required</span>
                            )}
                        </div>
                        <div className={profileClasses["Action-btns"]}>
                            <button type="submit" disabled={loading}>
                                {!loading ? "Update Profile" : "...Please wait"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Report;
