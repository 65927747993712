import { useEffect, useState } from "react";
import TransactionClasses from "./Transactions.module.scss";

import NoTransactions from "Assets/noTransaction.webp";
import moment from "moment-timezone";
import { Wallet_Transactions } from "Service/parent";
import { HiOutlineArrowSmDown, HiOutlineArrowNarrowUp } from "react-icons/hi";
import Wallet from "Assets/wallet.png";
import { motion, AnimatePresence } from "framer-motion";
import square from "Assets/square.svg";
import Topup from "./Topup/topup";
import Loader from "Reusable/Common/LoadingComponents/loader/loader";
import Payment from "./Topup/Payment";
import useStore from "store";

function Transactions() {
    const [WalletTransaction, setWalletTransaction] = useState({
        TotalWalletAmount: 0,
        Wallets: [],
    });
    const PaymentModal = useStore((state: any) => state.PaymentModal);
    const timeZone = useStore((state: any) => state.timeZone);
    const setPaymentModal = useStore((state: any) => state.setPaymentModal);
    const [Modal, setModal] = useState(false);
    const [payments, setPayments] = useState(false);

    const [Loading, setLoading] = useState(true);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const get_Transactions = async (filter: boolean, date?: any) => {
        setLoading(true);
        try {
            const Wallet = await Wallet_Transactions(
                "GET",
                null,
                token,
                filter,
                date
            );
            if (Wallet.Status === 1) {
                setWalletTransaction({
                    Wallets: Wallet.Wallets,
                    TotalWalletAmount: Wallet.TotalWalletAmount,
                });
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }
    };
    useEffect(() => {
        get_Transactions(false);
    }, [PaymentModal]);
    const dateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let Date = e.target.value;
        setLoading(true);
        get_Transactions(true, Date);
    };
    const truncateWithoutRounding = (number: any, decimalPlaces: any) => {
        const factor = Math.pow(10, decimalPlaces);
        return Math.floor(number * factor) / factor;
    };
    return (
        <div className={TransactionClasses["Transaction_Container"]}>
            <Topup
                modal={Modal}
                setModal={setModal}
                setPayment={setPaymentModal}
                getDetails={get_Transactions}
            />
            {PaymentModal && (
                <Payment modal={PaymentModal} setModal={setPaymentModal} />
            )}
            <div className={TransactionClasses["wallet_Amount"]}>
                <img alt="wallet" src={Wallet} />
                <div>
                    <h3>
                        ₹{" "}
                        {truncateWithoutRounding(
                            WalletTransaction.TotalWalletAmount,
                            3
                        )}
                    </h3>
                    <button
                        onClick={() => {
                            setModal(true);
                        }}>
                        <img alt="square" src={square} /> Top Up
                    </button>
                </div>
            </div>
            <div className={TransactionClasses["header"]}>
                <h3>Recent Transactions</h3>
                <div className={TransactionClasses["filter"]}>
                    <input type="Date" onChange={dateChangeHandler} />
                    {/* <div className={TransactionClasses["course_search"]}>
                        <MdOutlineContentPasteSearch
                            className={TransactionClasses["search_icon"]}
                        />
                        <input
                            type="text"
                            placeholder="Search by Course Name"
                        />
                    </div> */}
                </div>
            </div>
            <div className={TransactionClasses["Transactions"]}>
                {Loading ? (
                    <Loader />
                ) : WalletTransaction.Wallets.length > 0 ? (
                    <AnimatePresence>
                        <table>
                            <thead>
                                <tr>
                                    <th>Transaction Date</th>
                                    <th>Class Date/ Transaction Date</th>
                                    <th>Course Name</th>
                                    <th>Invoice</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Debit/Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {WalletTransaction.Wallets?.map(
                                    (item: any, index: any) => (
                                        <motion.tr
                                            key={item.id}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: index * 0.2,
                                            }}>
                                            <td>
                                                {moment(item.wCreatedAt)
                                                    .tz(
                                                        timeZone ||
                                                            moment.tz.guess()
                                                    )
                                                    .format("DD MMM YYYY")}
                                            </td>
                                            <td>
                                                {item.Type === 1 ||
                                                item.Type === 4
                                                    ? item?.DateTime == "NA"
                                                        ? "NA"
                                                        : moment(
                                                              `${
                                                                  String(
                                                                      item.DateTime
                                                                  ).split(
                                                                      " "
                                                                  )[0]
                                                              }T${
                                                                  String(
                                                                      item.DateTime
                                                                  ).split(
                                                                      " "
                                                                  )[1]
                                                              }+05:30`
                                                          )
                                                              .tz(
                                                                  timeZone ||
                                                                      moment.tz.guess()
                                                              )
                                                              .format(
                                                                  "DD MMM YYYY"
                                                              )
                                                    : item?.wCreatedAt == "NA"
                                                    ? "NA"
                                                    : moment(item.wCreatedAt)
                                                          .tz(
                                                              timeZone ||
                                                                  moment.tz.guess()
                                                          )
                                                          .format(
                                                              "DD MMM YYYY"
                                                          )}
                                            </td>
                                            <td>{item.CourseName} </td>

                                            <td>
                                                {item.InvoiceFile != "NA" ? (
                                                    <a
                                                        href={item.InvoiceFile}
                                                        target="_blank">
                                                        Link
                                                    </a>
                                                ) : (
                                                    "NA"
                                                )}{" "}
                                            </td>

                                            <td
                                                style={{
                                                    color:
                                                        item.IsCredited === 1
                                                            ? "green"
                                                            : "red",
                                                }}>
                                                ₹ {Math.round(item.Amount)}
                                            </td>
                                            <td>
                                                {item.Type === 1
                                                    ? "Lesson Complete"
                                                    : item.Type === 2
                                                    ? "Manual Balance Adjustment"
                                                    : item.Type === 3
                                                    ? "Top Up"
                                                    : item.Type === 4
                                                    ? "Class cancel"
                                                    : item.Type === 5
                                                    ? "Course registration"
                                                    : "Top Up"}
                                            </td>
                                            <td>
                                                {" "}
                                                {item.IsCredited === 1 ? (
                                                    <span
                                                        className={
                                                            TransactionClasses[
                                                                "Approved"
                                                            ]
                                                        }>
                                                        <HiOutlineArrowNarrowUp
                                                            size={15}
                                                        />{" "}
                                                        Credit
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={
                                                            TransactionClasses[
                                                                "reject"
                                                            ]
                                                        }>
                                                        <HiOutlineArrowSmDown
                                                            size={16}
                                                        />{" "}
                                                        Debit
                                                    </span>
                                                )}
                                            </td>
                                        </motion.tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </AnimatePresence>
                ) : (
                    <div className={TransactionClasses["No_Transactions"]}>
                        <img src={NoTransactions} alt="transaciton" />
                        <p>No Transactions Found</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Transactions;
