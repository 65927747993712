import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import loadClasses from "./load.module.css";

function Load() {
    return (
        <SkeletonTheme>
            <div className={loadClasses["Table"]}>
                <table>
                    <thead>
                        <tr>
                            <th>Course Name</th>
                            <th>Category</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(3)
                            .fill()
                            .map(() => (
                                <tr>
                                    <td>
                                        <Skeleton height={"1rem"} />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </SkeletonTheme>
    );
}

export default Load;
