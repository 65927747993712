import Modal from "react-modal";
import React, { useEffect } from "react";
import Styles from "./report.module.scss";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Inputs, props } from "./types";
import { Services } from "../../../../Service/service";
import { Error } from "../../../../Reusable/utils/Toast";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
Modal.setAppElement("#root");

const DemoForm: React.FC<props> = ({ modal, setModal, getDetails, id }) => {
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm<Inputs>();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    let [selectedStudent] = getDetails.data?.Students?.filter(
        (item: { Scheduled_Classes_StudentID: any }) =>
            // eslint-disable-next-line
            item.Scheduled_Classes_StudentID == modal
    );
    useEffect(() => {
        const { NewTopicDone, HomeWorkCompleted, Comments, RevisionDone } =
            selectedStudent;

        if (
            NewTopicDone != null &&
            HomeWorkCompleted != null &&
            RevisionDone != null
        ) {
            reset({
                NewTopicDone: NewTopicDone,
                Comments: Comments,
                HomeWorkCompleted: HomeWorkCompleted,
                RevisionDone: RevisionDone,
            });
        }
        // reset()
        // eslint-disable-next-line
    }, []);

    // CLOSE FORM
    const closeModal = () => {
        setModal(false);
        reset();
    };

    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = (formdata) => {
        let data = JSON.stringify({
            ...formdata,
            IsReportSubmitted: 1,
            Scheduled_Classes_StudentID: modal,
            ScheduleClassID: getDetails.data.ScheduleClassID,
            BatchName: getDetails.data.BatchName,
            IsNewReport: selectedStudent.IsReportSubmitted,
            // IsNewReport :
        });

        Services.ReportSubmitStudent("POST", data, token)
            .then((response: any) => {
                console.log(response);
                if (response.Status === 1) {
                    getDetails.setLoading(true);
                    getDetails.details(id);
                    closeModal();
                    swal("Reported", response.Message, "success");
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                Error("something went wrong please try again");
            });
    };

    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Report</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">Revision Done :</label>
                        <div className={Styles["radio-buttons"]}>
                            <label>
                                <Controller
                                    name="RevisionDone"
                                    control={control}
                                    defaultValue={1}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type="radio"
                                                {...field}
                                                defaultChecked={
                                                    field.value === 1
                                                }
                                                value={1}
                                            />
                                            Yes
                                        </>
                                    )}
                                />
                            </label>
                            <label>
                                <Controller
                                    name="RevisionDone"
                                    control={control}
                                    defaultValue={1}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type="radio"
                                                {...field}
                                                defaultChecked={
                                                    field.value === 0
                                                }
                                                value={0}
                                            />
                                            No
                                        </>
                                    )}
                                />
                            </label>
                        </div>
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">
                            Homework Completed <span>:</span>
                        </label>
                        <div className={Styles["radio-buttons"]}>
                            <label>
                                <Controller
                                    name="HomeWorkCompleted"
                                    control={control}
                                    defaultValue={1}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type="radio"
                                                {...field}
                                                defaultChecked={
                                                    field.value === 1
                                                }
                                                value={1}
                                            />
                                            Yes
                                        </>
                                    )}
                                />
                            </label>
                            <label>
                                <Controller
                                    name="HomeWorkCompleted"
                                    control={control}
                                    defaultValue={1}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type="radio"
                                                {...field}
                                                defaultChecked={
                                                    field.value === 0
                                                }
                                                value={0}
                                            />
                                            No
                                        </>
                                    )}
                                />
                            </label>
                            <label>
                                <Controller
                                    name="HomeWorkCompleted"
                                    control={control}
                                    defaultValue={1}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type="radio"
                                                {...field}
                                                defaultChecked={
                                                    field.value === 2
                                                }
                                                value={2}
                                            />
                                            NA
                                        </>
                                    )}
                                />
                            </label>
                        </div>
                    </div>
                    {/* <div className={Styles["form-control"]}>
                        <label htmlFor="">
                            Topic taken today <span>:</span>
                        </label>
                        <div className={Styles["input"]}>
                            <input
                                type="text"
                                {...register("NewTopicDone", {
                                    required: "true",
                                })}
                            />
                            {errors.NewTopicDone && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            Description of Topic <span>:</span>
                        </label>

                        <textarea
                            {...register("Comments", {
                                required: false,
                            })}></textarea>
                    </div> */}
                    <div className={Styles["buttons"]}>
                        <button type="submit" className={Styles["submit"]}>
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={closeModal}
                            className={Styles["close"]}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
