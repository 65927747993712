import React, { useEffect, useState } from "react";
import ContactClasses from "./Contact.module.scss";
import { IoSearch } from "react-icons/io5";
import { SUPPORT } from "Service/parent";
import { warningSwal } from "Reusable/utils/Toast";
import Skeleton from "react-loading-skeleton";
import PencilLoad from "Reusable/Common/LoadingComponents/pencilLoader/Loader";
import FAQ from "./FAQ";

function Contact() {
    const [questions, setQuestions] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [loading, setLoading] = useState(true);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    useEffect(() => {
        let debouncedSearch: any;
        if (searchString.length > 0) {
            debouncedSearch = setTimeout(async () => {
                getQuestions({ SearchString: searchString });
            }, 300);
        } else {
            getQuestions(null);
        }
        return () => clearTimeout(debouncedSearch);
    }, [searchString]);
    const getQuestions = async (query: any) => {
        if (!loading) {
            setLoading(true);
        }
        try {
            const response = await SUPPORT("GET", null, token, query);
            if (response.Status === 1) {
                setQuestions(response.CustomerSupport);
            } else {
                warningSwal("warning", response.Message);
            }
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            warningSwal("ERROR", "Somethinw went wrong try again");
        }
    };

    return (
        <div className={ContactClasses["Container"]}>
            <h3>Help and Support</h3>
            <header>
                <IoSearch className={ContactClasses["search-icon"]} />
                <input
                    type="text"
                    placeholder="Type your query here"
                    autoFocus
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                />
            </header>
            {loading ? (
                <>
                    <Skeleton
                        count={4}
                        baseColor="lightgray"
                        style={{ marginTop: "0.7rem" }}
                    />
                </>
            ) : (
                <FAQ FAQItems={questions} />
            )}
        </div>
    );
}

export default Contact;
