import { useState, useEffect } from "react";
import Layout from "../../../Reusable/Tutor/Layout";
import { Helmet } from "react-helmet";
import { Services } from "../../../Service/service";
import SkeletonLoad from "./TableLoad/load";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import TutorClasses from "./request.module.scss";
import ReactPaginate from "react-paginate";
import { Error, SuccessSwal, warningSwal } from "../../../Reusable/utils/Toast";
import swal from "sweetalert";
import Swal from "sweetalert2";
import FallingLines from "../../../Reusable/Common/LoadingComponents/fallingLines/fallingLines";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { AiFillEye } from "react-icons/ai";
function Approval() {
    const Navigate = useNavigate();
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);
    let RecordCount = 20;
    const [forcepage, setforcepage] = useState(0);

    const [loading, setLoading] = useState(true);
    let TutorDetails: any = localStorage.getItem("LoginDetails");
    const [Active, setActive] = useState<number>(1);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        getBatchRequests(Active, skip);
    }, [Active]);

    // ---GET BATCH REQUESTS

    async function getBatchRequests(Type: number, skip: any) {
        try {
            const response = await Services.getDemoClasses(
                "GET",
                null,
                token,
                Type,
                skip
            );

            setTimeout(() => {
                setLoading(false);
            }, 200);
            setData(response.DemoRequests);
            if (
                response.DemoRequests.length == 0 ||
                response.Count <= RecordCount
            ) {
                setShowPagiate(false);
            } else {
                if (!showPaginate) {
                    setShowPagiate(true);
                }
                setpageCount(
                    Math.ceil(Number(response?.Count) / Number(RecordCount))
                );
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }

    // EVENT TO GET ACTIVE PAST AND CANCELLED CLASSES
    const NavHandler = (type: number) => {
        setActive(type);
        setforcepage(0);
    };
    const detailsHandler = (id: any) => {
        Navigate(`/Tutor/Demo-class-details?DemoClassID=${id}`);
    };
    const handlePageChange = (selectedObject: any) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();

        window.scrollTo({ top: 10, behavior: "smooth" });
        setforcepage(selectedObject.selected);
        getBatchRequests(Active, selectedObject.selected * RecordCount);
        setLoading(true);
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Demo Classes</title>
            </Helmet>
            <Layout Active={"Demo Classes"}>
                <div className={TutorClasses["Container"]}>
                    <div className={TutorClasses["Tutor"]}>
                        <h3>Demo Classes</h3>
                        <div className={TutorClasses["Navigator"]}>
                            <button
                                custom-btn-type="1"
                                className={
                                    Active === 1 ? TutorClasses["active"] : ""
                                }
                                onClick={() => NavHandler(1)}>
                                Demo Scheduled
                            </button>
                            <button
                                custom-btn-type="2"
                                className={
                                    Active === 2 ? TutorClasses["active"] : ""
                                }
                                onClick={() => NavHandler(2)}>
                                Completed
                            </button>
                            <button
                                custom-btn-type="3"
                                className={
                                    Active === 3 ? TutorClasses["active"] : ""
                                }
                                onClick={() => NavHandler(3)}>
                                Cancelled
                            </button>
                        </div>
                        {loading ? (
                            <SkeletonLoad />
                        ) : (
                            <div className={TutorClasses["Table"]}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Demo ClassID</th>
                                            <th>Start Date Time</th>
                                            <th>Batch Size</th>
                                            <th>Tutor</th>
                                            <th>Course Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? (
                                            data.map(
                                                (item: any, index: any) => {
                                                    const timeStr =
                                                        item?.DemoStartTime;

                                                    // Combine date and time
                                                    const dateTime = moment(
                                                        item?.DemoStartDate
                                                    ).set({
                                                        hour: moment(
                                                            timeStr,
                                                            "HH:mm:ss"
                                                        ).hours(),
                                                        minute: moment(
                                                            timeStr,
                                                            "HH:mm:ss"
                                                        ).minutes(),
                                                        second: moment(
                                                            timeStr,
                                                            "HH:mm:ss"
                                                        ).seconds(),
                                                    });
                                                    return (
                                                        <tr
                                                            onClick={() =>
                                                                detailsHandler(
                                                                    item?.DemoClassID
                                                                )
                                                            }>
                                                            <td>
                                                                {
                                                                    item.DemoClassID
                                                                }{" "}
                                                                <br />
                                                                {Active == 3 &&
                                                                    `(${item.BatchSize})`}
                                                            </td>
                                                            <td>
                                                                <Moment format="hh:mm A">
                                                                    {dateTime}
                                                                </Moment>
                                                                <br />
                                                                <Moment format="DD-MM-YYYY">
                                                                    {dateTime}
                                                                </Moment>
                                                            </td>
                                                            {Active != 3 && (
                                                                <td>
                                                                    {
                                                                        item.BatchSize
                                                                    }
                                                                </td>
                                                            )}

                                                            <td>
                                                                {item.TutorName}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.CourseName
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>
                                                    {" "}
                                                    no records Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {showPaginate && (
                            <div
                                style={{
                                    justifySelf: "right",
                                }}>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    // pageRange={2}
                                    forcePage={forcepage}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Approval;
