import react, { useState } from "react";

import requestClasses from "./request.module.scss";
import Moment from "react-moment";
import { TbPlaneDeparture } from "react-icons/tb";
import { Services } from "../../../../Service/service";
import { Success } from "../../../../Reusable/utils/Toast";
import { BiLastPage, BiFirstPage } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import swal from "sweetalert";
import { ConfirmationDiaolog } from "../../../../Reusable/utils/utils";
const Request: react.FC<any> = ({
    setModal,
    pending,
    setEdit,
    setShowDetails,
}) => {
    const ITEMS_PER_PAGE = 3;
    const [currentPage, setCurrentPage] = useState(0);
    const offset = currentPage * ITEMS_PER_PAGE;
    const paginatedData = pending.slice(offset, offset + ITEMS_PER_PAGE);
    const handlePrevPageClick = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const handleNextPageClick = () => {
        const maxPage = Math.ceil(pending.length / ITEMS_PER_PAGE) - 1;
        if (currentPage < maxPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const editHandler = (e: any) => {
        let id = e.currentTarget.getAttribute("custom-id");
        let [selectedRequest] = pending.filter(
            (item: any) => item.LeaveRequestID === Number(id)
        );
        setEdit(selectedRequest);
        setModal(true);
    };

    const cancelLeave = async (requestId: number) => {
        const AllClasses = await Services.updateLeave(
            "PUT",
            JSON.stringify({ ApprovedStatus: 4 }),
            token,
            requestId
        );
        if (AllClasses.Status === 1) {
            Success("Leave Cancelled Successfully");
        }
    };
    const cancelHandler = async (e: any) => {
        let id = e.currentTarget.getAttribute("custom-id");
        ConfirmationDiaolog(
            "Are you sure You Want to Cancel Leave request",
            "Cancel",
            cancelLeave,
            id
        );
    };

    return (
        <div className={requestClasses["Container"]}>
            <header>
                <h3>Pending leave requests</h3>
            </header>
            <div className={requestClasses["leaves-data"]}>
                {paginatedData.length > 0 && (
                    <div className={requestClasses["Applied_leaves"]}>
                        {paginatedData.map((item: any, index: any) => (
                            <div className={requestClasses["leave"]}>
                                <div className={requestClasses["icon"]}>
                                    <TbPlaneDeparture color="white" />
                                </div>
                                <div
                                    className={requestClasses["leave-details"]}>
                                    <div>
                                        <h4>Leave</h4>
                                        <p
                                            style={{
                                                maxWidth: "10rem",
                                                wordWrap: "break-word",
                                            }}>
                                            <Moment format="DD MMM,YYYY">
                                                {new Date(item.FromDate)}
                                            </Moment>

                                            {item.ToDate !== item.FromDate && (
                                                <>
                                                    <span>-</span>
                                                    <Moment format="MMM DD,YYYY">
                                                        {new Date(item.ToDate)}
                                                    </Moment>
                                                </>
                                            )}
                                            <br />
                                            {(item.IsCustomLeave == 1 ||
                                                item.IsCustomLeave == 2) && (
                                                <span>
                                                    {item.CustomDates[0]
                                                        .FromDayType == 1
                                                        ? "First Half"
                                                        : "Second Half"}
                                                </span>
                                            )}
                                            {item.IsCustomLeave == 1 && (
                                                <span>
                                                    {"-"}
                                                    {item.CustomDates[0]
                                                        .ToDayType == 1
                                                        ? "First Half"
                                                        : "Second Half"}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <h4>Leave Type</h4>
                                        <p>
                                            {" "}
                                            {item.LeaveType === 1
                                                ? "Casual Leave"
                                                : item.LeaveType === 2
                                                ? "Marriage Leave"
                                                : item.LeaveType === 3
                                                ? "Sick Leave"
                                                : "Unpaid Leave"}
                                        </p>
                                    </div>
                                    <div>
                                        <h4>Requested On</h4>
                                        <p>
                                            {" "}
                                            <Moment format="MMM DD,YYYY">
                                                {new Date(item.CreatedAt)}
                                            </Moment>
                                        </p>
                                    </div>
                                    <div>
                                        <h4>Status</h4>
                                        <p>
                                            {item.ApproveStatus === 1
                                                ? "Approved"
                                                : item.ApproveStatus === 2
                                                ? "Pending"
                                                : "Rejected"}
                                        </p>
                                    </div>
                                    <div className={requestClasses["option"]}>
                                        <ul className={requestClasses["menu"]}>
                                            <li>
                                                <button>
                                                    <BsThreeDots size={18} />
                                                </button>
                                                <ul
                                                    className={
                                                        requestClasses[
                                                            "submenu"
                                                        ]
                                                    }>
                                                    <li>
                                                        <button
                                                            onClick={() =>
                                                                setShowDetails(
                                                                    true
                                                                )
                                                            }>
                                                            View Request
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            custom-id={
                                                                item.LeaveRequestID
                                                            }
                                                            onClick={
                                                                editHandler
                                                            }>
                                                            Edit Request
                                                        </button>
                                                    </li>

                                                    <li>
                                                        <button
                                                            custom-id={
                                                                item.LeaveRequestID
                                                            }
                                                            onClick={
                                                                cancelHandler
                                                            }>
                                                            Cancel Request
                                                        </button>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        Leave Note : {"  "}
                                        <span>{item.LeaveNote}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                        <div className={requestClasses["pagination"]}>
                            <button
                                onClick={handlePrevPageClick}
                                disabled={currentPage === 0}>
                                {/* |&lt; */}
                                <BiFirstPage />
                            </button>
                            <p>
                                Page {currentPage + 1} of{" "}
                                {Math.ceil(pending.length / ITEMS_PER_PAGE)}
                            </p>
                            <button
                                onClick={handleNextPageClick}
                                disabled={
                                    currentPage ===
                                    Math.ceil(pending.length / ITEMS_PER_PAGE) -
                                        1
                                }>
                                {/* &gt;| */}
                                <BiLastPage />
                            </button>
                        </div>
                    </div>
                )}
                <div className={requestClasses["apply_leave"]}>
                    <button onClick={() => setModal(true)}>
                        Request Leave
                    </button>
                    <p>Leave Policy Explanation</p>
                </div>
            </div>
        </div>
    );
};

export default Request;
