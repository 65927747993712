import { useState, useEffect } from "react";
import NotificationClass from "./Notifications.module.scss";
import Layout from "Reusable/Tutor/Layout";
import { Services } from "Service/service";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import { AiOutlineNotification } from "react-icons/ai";
import { setLocalStorageWithExpiration } from "Reusable/utils/expiryLocalData";
import useStore from "store";
function Notifications() {
    const [loading, setLoading] = useState(false);
    const Navigate = useNavigate();
    const [data, setData] = useState([]);
    const { NotificationCount, setNotificationCount } = useStore(
        (item: any) => item
    );
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const Reset_NotificationCount = () => {
        Services.Reset_Notification_count("POST", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setNotificationCount(0);
                    setLocalStorageWithExpiration("Notification", 0);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    //Fetching  Notifications
    async function Tutor_Notifications() {
        setLoading(true);
        try {
            const AllClasses = await Services.Notifications("GET", null, token);
            setTimeout(() => {
                setLoading(false);
            }, 200);
            setData(AllClasses.Notifications);
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    useEffect(() => {
        Tutor_Notifications();
        if (NotificationCount > 0) {
            Reset_NotificationCount();
        }
    }, []);
    return (
        <Layout Active={"Notifications"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Notifications</title>
            </Helmet>
            <div className={NotificationClass["Container"]}>
                <div className={NotificationClass["wrapper"]}>
                    <h3>Notifications</h3>
                    {loading ? (
                        <>
                            <p style={{ margin: "0.1rem" }}>
                                <Skeleton height={"10px"} />
                            </p>
                            <p style={{ margin: "0.1rem" }}>
                                <Skeleton height={"10px"} />
                            </p>
                            <p style={{ margin: "0.1rem" }}>
                                <Skeleton height={"10px"} />
                            </p>
                        </>
                    ) : (
                        <>
                            {data.length > 0 ? (
                                <ul>
                                    {data.map((item: any) => {
                                        let link = "";
                                        if (item.ContentType === 10) {
                                            link = `/Tutor/Classes/details?id=${item.ContentID}`;
                                        }
                                        if (item.ContentType === 7) {
                                            link = `/Tutor/Batch-request/Details?batchid=${item.ContentID}&requestId=${item.RequestID}`;
                                        }
                                        if (item.ContentType === 8) {
                                            link = `/Tutor/Courses`;
                                        }

                                        if (
                                            item.ContentType === 1 ||
                                            item.ContentType === 2 ||
                                            item.ContentType === 3 ||
                                            item.ContentType === 4 ||
                                            item.ContentType === 5 ||
                                            item.ContentType === 6 ||
                                            item.ContentType === 9
                                        ) {
                                            return (
                                                <li>
                                                    {" "}
                                                    <AiOutlineNotification
                                                        size={30}
                                                    />
                                                    <div
                                                        className={
                                                            NotificationClass[
                                                                "right"
                                                            ]
                                                        }>
                                                        <h5>
                                                            <b>{item.Title}</b>
                                                        </h5>
                                                        <p>{item.Message}</p>
                                                        <p>
                                                            <Moment fromNow>
                                                                {item.CreatedAt}
                                                            </Moment>
                                                        </p>
                                                    </div>
                                                </li>
                                            );
                                        }
                                        if (link.length > 0) {
                                            return (
                                                <li
                                                    onClick={() =>
                                                        Navigate(link)
                                                    }>
                                                    <AiOutlineNotification
                                                        size={30}
                                                    />
                                                    <div
                                                        className={
                                                            NotificationClass[
                                                                "right"
                                                            ]
                                                        }>
                                                        <h5>
                                                            <b>{item.Title}</b>
                                                        </h5>
                                                        <p>{item.Message}</p>
                                                        <p>
                                                            <Moment fromNow>
                                                                {item.CreatedAt}
                                                            </Moment>
                                                        </p>
                                                    </div>
                                                </li>
                                            );
                                        }
                                        return "-";
                                    })}
                                </ul>
                            ) : (
                                <p>No New Notification</p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Notifications;
