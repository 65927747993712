import React, { useEffect, useState } from "react";
import SuccessVector from "Assets/successvector.png";
//Parent/home?StudentId=2804374
import Styles from "./Styles.module.scss";
import loadSuccess from "Assets/success.png";
import { useNavigate } from "react-router";
import useStore from "store";
function Success() {
    const { Tab, setTab } = useStore((item: any) => item);

    const setPaymentModal = useStore((state: any) => state.setPaymentModal);
    const [timer, setTimer] = useState(5);
    const Navigate = useNavigate();
    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            setTab("WALLET TRANSACTIONS");
            setPaymentModal(false);
            window.close();
            console.log("11");
            /* if (localStorage.getItem("student")) {
                Navigate(
                    `/Parent/home?StudentId=${localStorage.getItem("student")}`
                );
            } */
        }, 5000); // 10 seconds in milliseconds
        const countdownInterval = setInterval(() => {
            setTimer((prevCountdown) => prevCountdown - 1);
        }, 1000); // Update countdown every second

        return () => {
            clearTimeout(redirectTimeout);
            clearInterval(countdownInterval);
            localStorage.removeItem("paymentLink");
        };
    }, []);
    console.log(timer);
    return (
        <div className={Styles["container"]}>
            <div className={Styles["wrapper"]}>
                <div className={Styles["Success"]}>
                    <img src={loadSuccess} alt="loadSuccess" />
                    <p>Transaction Success</p>
                    <p>The Amount will Reflect in Your Account Shortly</p>

                    <p>will Redirect in {timer} </p>
                </div>
                {/* <img src={SuccessVector} alt="SuccessVector" /> */}
            </div>
        </div>
    );
}

export default Success;
