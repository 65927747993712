import React from "react";
import Logo from "../../../../Assets/logo.svg";
import Styles from "./styles.module.scss";
const index: React.FC = () => {
    return (
        <div className={Styles["Container"]}>
            <div>
                <img src={Logo} alt="" />
            </div>
        </div>
    );
};

export default index;
