import react, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { ApplyLeaveTypes } from "./types";
import { IoClose } from "react-icons/io5";
import Moment from "react-moment";
import { BiTimeFive } from "react-icons/bi";
import { useForm, SubmitHandler } from "react-hook-form";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import applyClasses from "./apply.module.scss";
import { Services } from "../../../../Service/service";
import { Success } from "../../../../Reusable/utils/Toast";
const Apply: react.FC<any> = ({
    toggleModal,
    setShowModal,
    fetchAll,
    setLoad,
    setEdit,
    edit,
}) => {
    const {
        register,
        handleSubmit,
        watch,
        setError,
        reset,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm<ApplyLeaveTypes>({
        defaultValues: { FromDayType: "1", ToDayType: "2" },
    });
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const formData = watch();
    const [dateDifferece, setDateDifference] = useState(0);
    const [dayType, setDayType] = useState("0");

    useEffect(() => {
        if (formData.FromDayType && formData.ToDayType) {
            let difference = getDateDifferenceInDays(
                formData.FromDate,
                formData.ToDate
            );
            if (formData.FromDayType === "1" && formData.ToDayType === "2") {
                setDateDifference(difference);
            } else if (
                (formData.FromDayType === "1" && formData.ToDayType === "1") ||
                (formData.FromDayType === "2" && formData.ToDayType === "2")
            ) {
                setDateDifference(difference - 0.5);
            } else if (
                formData.FromDayType === "2" &&
                formData.ToDayType === "1"
            ) {
                setDateDifference(difference - 1);
            }
        }
        // eslint-disable-next-line
    }, [formData.FromDayType, formData.ToDayType]);

    useEffect(() => {
        if (edit) {
            reset({
                ToDate: String(edit.ToDate).split("T")[0],
                FromDate: String(edit.FromDate).split("T")[0],
                LeaveType: edit.LeaveType,
                LeaveNote: edit.LeaveNote,
            });
            setDateDifference(edit.NoOfDays);
            if (edit.IsCustomLeave === 0) {
                setDayType("0");
            } else if (edit.IsCustomLeave === 1) {
                setDayType("2");
                setValue("FromDayType", edit.CustomDates[0].FromDayType);
                setValue("ToDayType", edit.CustomDates[0].ToDayType);
            } else {
                setDayType(edit.CustomDates[0].FromDayType === 1 ? "1" : "3");
            }
        }
    }, []);

    function getDateDifferenceInDays(date1: string, date2: string) {
        if (date1 && date2 && date1 < date2) {
            const date1Obj = new Date(date1);
            const date2Obj = new Date(date2);

            if (isNaN(date1Obj.getTime()) || isNaN(date2Obj.getTime())) {
                throw new Error("Invalid date format");
            }

            // Calculate the time difference in milliseconds
            const timeDifference = Math.abs(
                date2Obj.getTime() - date1Obj.getTime()
            );

            const daysDifference = Math.ceil(
                timeDifference / (1000 * 60 * 60 * 24)
            );
            clearErrors("ToDate");
            return daysDifference + 1;
        } else if (date1 === date2) {
            clearErrors("ToDate");
            return 1;
        } else if (date1 && date2 && date1 > date2) {
            setError("ToDate", {
                type: "manual",
                message: "Invalid Date Range",
            });
            return 0;
        } else {
            return 0;
        }
    }
    const dayChangeHandler = (date1: string, date2: string) => {
        setDayType("0");
        let diff = getDateDifferenceInDays(date1, date2);

        setDateDifference(diff);
    };

    const SuccessHandler = (message: string) => {
        setEdit(null);
        fetchAll();
        Success(message);
    };
    const onSubmit: SubmitHandler<ApplyLeaveTypes> = async (data) => {
        let loginDetails: any = localStorage.getItem("LoginDetails");
        if (loginDetails) {
            loginDetails = JSON.parse(loginDetails);
            let body: any = {
                ToDate: data.ToDate,
                LeaveType: formData.LeaveType,
                LeaveNote: formData.LeaveNote,
                FromDate: formData.FromDate,
            };

            if (dayType === "0") {
                body = { ...body, IsCustomLeave: 0 };
            } else if (dayType === "2") {
                body = {
                    ...body,
                    IsCustomLeave: 1,
                    CustomDates: [
                        {
                            FromDayType: formData.FromDayType,
                            ToDayType: formData.ToDayType,
                        },
                    ],
                };
            } else {
                body = {
                    ...body,
                    IsCustomLeave: 2,
                    CustomDates: [
                        {
                            FromDayType: dayType === "1" ? 1 : 2,
                        },
                    ],
                };
            }

            setLoad(true);

            try {
                if (!edit) {
                    const AllClasses = await Services.ApplyLeave(
                        "POST",
                        JSON.stringify({
                            ...body,
                            NoOfDays: dateDifferece,
                            RequestedBy: loginDetails?.TutorID,
                        }),
                        token
                    );
                    if (AllClasses.Status === 1) {
                        SuccessHandler("Leave Applied Successfully");
                    }
                } else {
                    const AllClasses = await Services.updateLeave(
                        "PUT",
                        JSON.stringify({
                            ...body,
                            NoOfDays: dateDifferece,
                            RequestedBy: loginDetails?.TutorID,
                        }),
                        token,
                        edit.LeaveRequestID
                    );
                    if (AllClasses.Status === 1) {
                        SuccessHandler("Leave Updated Successfully");
                    }
                }
            } catch (err: any) {
                console.log(err);
                Error("something went wrong please try again");
            } finally {
                setShowModal(false);
            }
        }
    };
    const typeHandle = (type: string) => {
        setDayType(type);

        setDateDifference(
            getDateDifferenceInDays(formData.FromDate, formData.ToDate) - 0.5
        );
    };
    return (
        <div className={applyClasses["modal"]} onClick={toggleModal}>
            <div
                className={applyClasses["modal-content"]}
                onClick={(e) => e.stopPropagation()}>
                <div className={applyClasses["modal-content-header"]}>
                    <h3>{edit ? "Update" : "Apply"} Leave</h3>

                    <button
                        className={applyClasses["close-btn"]}
                        onClick={toggleModal}>
                        <IoClose size={22} color={"black"} />
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={applyClasses["form-contrl-date"]}>
                        <div>
                            <label htmlFor="">From</label>
                            <input
                                type="Date"
                                autoFocus
                                {...register("FromDate", {
                                    required: "This is required",
                                    onChange: (e) => {
                                        dayChangeHandler(
                                            e.target.value,
                                            formData.ToDate
                                        );
                                    },
                                })}
                            />
                        </div>
                        <p> {dateDifferece} Days</p>
                        <div>
                            <label htmlFor="">To</label>
                            <input
                                type="Date"
                                {...register("ToDate", {
                                    required: "This is required",
                                    onChange: (e) => {
                                        dayChangeHandler(
                                            formData.FromDate,
                                            e.target.value
                                        );
                                    },
                                })}
                            />
                        </div>
                    </div>
                    {errors.ToDate && (
                        <span style={{ color: "red" }}>
                            {errors.ToDate.message}
                        </span>
                    )}

                    <div className={applyClasses["form-control"]}>
                        <label htmlFor="type">
                            Select Type of leave You want to apply
                        </label>
                        <select
                            {...register("LeaveType", { required: "true" })}
                            defaultValue={""}>
                            <option value="" disabled>
                                Select
                            </option>
                            <option value="1">Casual Leave</option>
                            <option value="2">Marriage Leave</option>
                            <option value="3">Sick Leave</option>
                            <option value="4">unpaid Leave</option>
                        </select>
                        {errors.LeaveType && (
                            <span style={{ color: "red" }}>
                                This is required
                            </span>
                        )}
                    </div>
                    {formData.FromDate && formData.ToDate && (
                        <div className={applyClasses["Day-types"]}>
                            <div className={applyClasses["daytype"]}>
                                <button
                                    className={
                                        dayType === "0"
                                            ? applyClasses["active"]
                                            : ""
                                    }
                                    type="button"
                                    onClick={() => {
                                        setDayType("0");
                                        setDateDifference(
                                            getDateDifferenceInDays(
                                                formData.FromDate,
                                                formData.ToDate
                                            )
                                        );
                                    }}>
                                    Full Day
                                </button>
                                {formData.FromDate !== formData.ToDate ? (
                                    <button
                                        className={
                                            dayType === "2"
                                                ? applyClasses["active"]
                                                : ""
                                        }
                                        type="button"
                                        onClick={() => setDayType("2")}>
                                        Custom
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className={
                                                dayType === "1"
                                                    ? applyClasses["active"]
                                                    : ""
                                            }
                                            type="button"
                                            onClick={() => typeHandle("1")}>
                                            First Half
                                        </button>
                                        <button
                                            className={
                                                dayType === "3"
                                                    ? applyClasses["active"]
                                                    : ""
                                            }
                                            type="button"
                                            onClick={() => typeHandle("3")}>
                                            Second Half
                                        </button>
                                    </>
                                )}
                            </div>
                            {dayType === "2" && (
                                <div className={applyClasses["dayType_input"]}>
                                    <div
                                        className={
                                            applyClasses["daytype_formControl"]
                                        }>
                                        <label htmlFor="From">
                                            From{" "}
                                            <Moment format="DD MMM,YYYY">
                                                {new Date(formData.FromDate)}
                                            </Moment>
                                        </label>
                                        <select
                                            defaultValue={"1"}
                                            {...register("FromDayType")}>
                                            <option value="1">
                                                First Half
                                            </option>
                                            <option value="2">
                                                Second Half
                                            </option>
                                        </select>
                                    </div>
                                    <p>━</p>
                                    <div
                                        className={
                                            applyClasses["daytype_formControl"]
                                        }>
                                        <label htmlFor="From">
                                            To{" "}
                                            <Moment format="DD MMM,YYYY">
                                                {new Date(formData.ToDate)}
                                            </Moment>
                                        </label>
                                        <select
                                            defaultValue={"2"}
                                            {...register("ToDayType")}>
                                            <option value="1">
                                                First Half
                                            </option>
                                            <option value="2">
                                                Second Half
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            )}
                            <p>
                                <BiTimeFive /> You are requesting{" "}
                                <b>{dateDifferece} Days</b> of leave
                            </p>
                        </div>
                    )}
                    <div className={applyClasses["form-control"]}>
                        <label htmlFor="Note">Note</label>
                        <textarea
                            rows={5}
                            placeholder="Note"
                            {...register("LeaveNote", {
                                required: "true",
                            })}></textarea>
                        {errors.LeaveNote && (
                            <span style={{ color: "red" }}>
                                This is required
                            </span>
                        )}
                    </div>

                    <div className={applyClasses["submit"]}>
                        <button type="button" onClick={toggleModal}>
                            Cancel
                        </button>
                        <button type="submit">Request</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Apply;
