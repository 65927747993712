import Modal from "react-modal";
import React, { useState } from "react";
import TopupClasses from "./topup.module.scss";
interface props {
    modal: any;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    getDetails?: any;
}

const Payment: React.FC<props> = ({ modal, setModal, getDetails }) => {
    return (
        <>
            <Modal
                isOpen={modal}
                contentLabel="Modal"
                className={TopupClasses["modal-payment"]}
                // onRequestClose={closeModal}
                overlayClassName={TopupClasses["overlay"]}>
                <iframe
                    src={localStorage.getItem("paymentLink") || ""}
                    title="External Website"
                    width={600}
                    height={600}></iframe>
            </Modal>
        </>
    );
};

export default Payment;
