import swal from "sweetalert";

// UTC TO ITC CONVERSION
export const UTC_TO_IST_TIME_CONVERSION = (utcTimeStr: any) => {
    const utcTime = new Date(`1970-01-01T${utcTimeStr}Z`);

    // Add the time difference for IST (5 hours and 30 minutes)
    utcTime.setHours(utcTime.getHours() + 5);
    utcTime.setMinutes(utcTime.getMinutes() + 30);

    // Format the IST time in HH:mm:ss format
    const istTimeStr = utcTime.toISOString().substr(11, 8);
    return istTimeStr;
};

export const ExtractDateDetails = (date: string) => {
    const CurrentDate = new Date(date);
    const month = CurrentDate.toLocaleString("en-US", { month: "short" });
    const day = CurrentDate.getDate();
    const dayOfWeek = CurrentDate.toLocaleString("en-US", { weekday: "long" });
    return { day, month, dayOfWeek };
};

export function extractInitials(name: string) {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");
    return initials;
}

export const ConfirmationDiaolog = (
    Message: string,
    buttonText: string,
    isConfirmed: (props?: any) => void,
    props?: any
) => {
    swal(Message, {
        icon: "warning",
        dangerMode: true,
        buttons: {
            // cancel: "Cancel",
            catch: {
                text: buttonText,
                value: "Complete",
            },
            defeat: false,
        },
    }).then((value: any) => {
        switch (value) {
            case "Complete":
                isConfirmed(props);
                break;
        }
    });
};
