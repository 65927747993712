import React, { useState, useRef, useEffect } from "react";
import NotificationClasses from "./topNav.module.css";
import Moment from "react-moment";
import { AiOutlineNotification } from "react-icons/ai";

type props = {
    setIsNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
    isNotificationVisible: boolean;
};

const Notifications: React.FC<props> = ({ setIsNotificationVisible }) => {
    const NotificationRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (
            NotificationRef.current &&
            !NotificationRef.current.contains(event.target as Node)
        ) {
            setIsNotificationVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div
            ref={NotificationRef}
            className={NotificationClasses["notification_container"]}>
            <ul>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
                <li className={NotificationClasses["notification_item"]}>
                    <AiOutlineNotification />
                    <div className={NotificationClasses["right"]}>
                        <p>
                            <b>Learn2read Tutor</b> Accepted Batch request
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                        </p>
                        <p>10 min ago</p>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Notifications;
