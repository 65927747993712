import { useLayoutEffect, useState } from "react";
import PPtClasses from "./ppt.module.scss";
import LogoLoad from "Reusable/Common/LoadingComponents/logoload";
function PPT() {
    const [url, seturl] = useState<any>(null);
    const [load, setLoad] = useState(false);
    const handleIframeLoad = () => {
        setTimeout(() => {
            setLoad(true);
        }, 200);
    };
    /*   useLayoutEffect(() => {
        const fullUrl = `https://learn2readapp-my.sharepoint.com/:p:/g/personal/nitin_learn2readapp_onmicrosoft_com/Efz3Bb0LBXhLruCeWzl2XiwBomnwiN_9ptnDuhbALxz0IA?sourcedoc={''}}&amp;action=embedview&amp;wdAr=1.7777777777777777`;

        seturl(` <iframe src=${fullUrl} style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;">
        This is an embedded{" "}
        <a target="_blank" href="https://office.com">
            Microsoft Office
        </a>{" "}
        presentation, powered by{" "}
        <a target="_blank" href="https://office.com/webapps">
            Office
        </a>
        .
    </iframe>`);
    }, []); */

    return (
        <>
            {/* {!load && <LogoLoad />} */}
            <div className={PPtClasses["Container"]}>
                {/* {url && <div dangerouslySetInnerHTML={{ __html: url }} />} */}

                <iframe
                    id="ppt"
                    src="https://learn2readapp-my.sharepoint.com/:p:/g/personal/nitin_learn2readapp_onmicrosoft_com/EUfuWqSemVdCnAcxePvNClMBrTlbncmn5hMm0yJSeQAAPw"
                    style={{
                        position: "fixed",
                        width: "100%",
                        height: "100%",
                        border: "none",
                        margin: "0",
                        top: "0",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        padding: "0",
                        overflow: "hidden",
                        display: !load ? "hidden" : "block",
                    }}
                    onLoad={handleIframeLoad}></iframe>
            </div>
        </>
    );
}

export default PPT;
