import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import profileClasses from "./profile.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { profileUploaTypes } from "../types";
import Picture from "./Picture";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import { update_Kid_Details, get_All_Kids, add_kid } from "Service/parent";
import { SuccessSwal, warningSwal } from "Reusable/utils/Toast";
import { useCookies } from "react-cookie";
import useStore from "store";
import { useNavigate } from "react-router";
type props = {
    showModal: boolean;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    Data?: any;
    setProfileData?: any;
    EditMode: boolean;
};

const Report: React.FC<props> = ({
    setModal,
    showModal,
    Data,
    EditMode,
    setProfileData,
}) => {
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const { setTab, setActiveKid, ActiveKid } = useStore((store: any) => store);
    const {
        register,
        setValue,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<profileUploaTypes>();
    const [cookie, setCookie] = useCookies(["StudentDetails"]);
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setModal(false);
        reset();
    };
    const getKids = async () => {
        try {
            const [profileData] = await Promise.all([
                get_All_Kids("GET", null, token),
            ]);
            if (profileData?.Status === 1) {
                const kidDetails = profileData?.Students?.map((item: any) => ({
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    StudentID: item.StudentID,
                    DOB: item.DOB,
                }));
                setCookie("StudentDetails", kidDetails);
                if (!EditMode && profileData?.Students?.length > 0) {
                    setTab("HOME");
                    setActiveKid(
                        `${profileData?.Students[0]?.FirstName} ${profileData?.Students[0]?.LastName}`
                    );
                    Navigate(
                        `/Parent/home?StudentId=${profileData?.Students[0].StudentID}`
                    );
                    localStorage.setItem(
                        "student",
                        profileData?.Students[0].StudentID
                    );
                }
            }
            setTimeout(() => {
                setLoading(false);
            }, 200);
        } catch {
            setLoading(false);
            Error("something Went wrong try again");
        }
    };

    useEffect(() => {
        if (EditMode) {
            reset({
                FirstName: Data.FirstName,
                LastName: Data.LastName,
                EmailID: Data.EmailID,
                Gender: Data.Gender,
                DOB: moment(Data.DOB).format("YYYY-MM-DD"),
                RelationWithParent: Data?.RelationWithParent,
            });
        }
    }, []);
    const submit: SubmitHandler<profileUploaTypes> = async (formData) => {
        setLoading(true);
        console.log(token);
        if (EditMode) {
            try {
                const [profileData] = await Promise.all([
                    update_Kid_Details(
                        "PUT",
                        JSON.stringify(formData),
                        token,
                        localStorage.getItem("student")
                    ),
                ]);
                if (profileData?.Status === 1) {
                    SuccessSwal("Success", "Kid Update Success");
                    setProfileData((prev: any) => ({ ...prev, ...formData }));
                    await getKids();
                    closeModal();
                    reset();
                } else {
                    warningSwal("Warning", profileData.Message);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            } catch {
                setLoading(false);
                Error("something Went wrong try again");
            }
        } else {
            try {
                const [profileData] = await Promise.all([
                    add_kid("POST", JSON.stringify(formData), token),
                ]);
                if (profileData?.Status === 1) {
                    SuccessSwal("Success", "Kid Added Successfully");

                    await getKids();
                    closeModal();
                    reset();
                }

                setTimeout(() => {
                    setLoading(false);
                }, 200);
            } catch {
                setLoading(false);
                Error("something Went wrong try again");
            }
        }
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                contentLabel="Modal"
                className={profileClasses["modal"]}
                onRequestClose={closeModal}
                overlayClassName={profileClasses["overlay"]}>
                <div className={profileClasses["Container"]}>
                    {/* <div className={}></div> */}
                    <header>
                        <h3>
                            {EditMode ? `Update ${Data.FirstName}` : "Add"}{" "}
                            Profile
                        </h3>
                        <button onClick={closeModal}>
                            <IoMdClose />
                        </button>
                    </header>
                    <form onSubmit={handleSubmit(submit)}>
                        <Picture setValue={setValue} data={Data} />
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                First Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("FirstName", { required: true })}
                                placeholder="Enter First Name"
                            />
                            {errors?.FirstName && (
                                <span>First Name is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Last Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("LastName", { required: true })}
                                placeholder="Enter Last Name"
                            />
                            {errors?.LastName && (
                                <span>Last Name is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Email <span>*</span>
                            </label>

                            <input
                                type="email"
                                {...register("EmailID", { required: true })}
                                placeholder="Enter Email Address"
                            />
                            {errors?.EmailID && (
                                <span>Email Address is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Gender <span>*</span>
                            </label>

                            <select
                                {...register("Gender", { required: true })}
                                defaultValue={"NA"}>
                                <option value="NA" disabled>
                                    Select Gender
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            {errors?.Gender && <span>Gender is required</span>}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Relation with Parent <span>*</span>
                            </label>

                            <select
                                {...register("RelationWithParent", {
                                    required: true,
                                })}
                                defaultValue={"NA"}>
                                <option value="NA" disabled>
                                    Relation with Parent
                                </option>
                                <option value="Father">Father</option>
                                <option value="Mother">Mother</option>
                            </select>
                            {errors?.RelationWithParent && (
                                <span>This is required Field</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Date of Birth <span>*</span>
                            </label>

                            <input
                                type="date"
                                {...register("DOB", { required: true })}
                                min={
                                    (new Date().getFullYear() - 12).toString() +
                                    "-01-01"
                                }
                                max={
                                    (new Date().getFullYear() - 2).toString() +
                                    "-12-31"
                                }
                            />
                            {errors?.DOB && <span>DOB is required</span>}
                        </div>
                        <div className={profileClasses["Action-btns"]}>
                            <button type="submit" disabled={loading}>
                                {!loading
                                    ? EditMode
                                        ? "Update Profile"
                                        : "Add Profile"
                                    : "...Please wait"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Report;
