import users from "Assets/user.svg";
import { useState } from "react";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import profileClasses from "./profile.module.scss";
import { config } from "S3Bucket/config";
import ReactS3Client from "react-aws-s3-typescript";
window.Buffer = window.Buffer || require("buffer").Buffer;
const Profilepicture: React.FC<any> = ({ setValue, data }) => {
    const [profilePic, setprofilePic] = useState("");

    const profileupload = (
        <div className={profileClasses["profile-img"]}>
            <img
                src={profilePic.length == 0 ? data?.ProfilePicture : profilePic}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = users;
                }}
                alt={"profilepic"}
            />
        </div>
    );
    const profilePicUpload = (files: any) => {
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            const timestamp = Date.now();
            let fileName = `${timestamp}.${String(files.type).split("/")[1]}`;
            const newfile = new File([files], fileName);
            const s3 = new ReactS3Client({
                ...config,
                dirName: "Tutors/ProfilePictures",
            });
            s3.uploadFile(newfile)
                .then((res: any) => {
                    // console.log();
                    setValue("ProfilePicture", String(res.key).split("/")[2]);
                    setprofilePic(res.location);
                })
                .catch((err: any) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    return (
        <div className={profileClasses["profile-handler"]}>
            <FileUploader
                classes={profileClasses["profile-upload-input"]}
                multiple={false}
                handleChange={(files: any) => {
                    profilePicUpload(files);
                }}
                name="file"
                children={profileupload}
                // types={fileTypes}
            />
        </div>
    );
};

export default Profilepicture;
