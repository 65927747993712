import React, { useState, useEffect } from "react";
import Layout from "../../../Reusable/Tutor/Layout/index";
import CourseClass from "./courses.module.scss";
import { Helmet } from "react-helmet";
import { Error } from "../../../Reusable/utils/Toast";
import { Services } from "../../../Service/service";
import TableLoad from "./TableLoad/lessonPlanLoad";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PPt from "../../../Assets/ppt.svg";
import { MdArrowForwardIos } from "react-icons/md";
import { useLocation } from "react-router-dom";
function Earning() {
    const Navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [Loading, setLoading] = useState<boolean>(true);
    const [Courses, set_Courses] = useState<any>([]);
    const location = useLocation();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        Course_Lessons();
        // eslint-disable-next-line
    }, []);
    const BackButton = () => {
        Navigate(-1);
    };
    //Fetching  Earnings
    async function Course_Lessons() {
        setLoading(true);
        try {
            const AllClasses = await Services.LessonPlanByCourseId(
                "GET",
                null,
                token,
                searchParams.get("CourseId")
            );
            setTimeout(() => {
                setLoading(false);
            }, 200);
            set_Courses(AllClasses.LessonPlans);
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    const pptHandler = (e: any) => {
        let url = e.currentTarget.getAttribute("custom-drive");
        // localStorage.setItem("PPT", url);
        // Navigate(`/Tutor/PPT`);
        window.open(url, "_blank");
    };
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Lesson Plan</title>
            </Helmet>
            <div className={CourseClass["Container"]}>
                <button onClick={BackButton} className={CourseClass["back"]}>
                    Back
                </button>
                <div className={CourseClass["Tutor"]}>
                    <h3>Lesson Plan</h3>
                    <ol className={CourseClass["breadcrumb"]}>
                        <li>
                            <span>{location?.state?.Name}</span>
                        </li>
                        <li className={CourseClass["current"]}>
                            <span>Lesson Plan </span>
                        </li>
                        {/* <p> {location?.state?.Name}</p>
                    <span>&rsaquo;</span>
                    <p>Lesson Plan</p> */}
                    </ol>
                    {Loading ? (
                        <TableLoad />
                    ) : (
                        <div className={CourseClass["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Plan Number</th>
                                        <th>Topic</th>

                                        <th>Lesson PPT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Courses.length > 0 ? (
                                        Courses?.map((item: any) => {
                                            return (
                                                <tr>
                                                    <td>{item.PlanNumber}</td>
                                                    <td>{item.Topic}</td>

                                                    <td>
                                                        {" "}
                                                        {item.OneDrivelink &&
                                                        item.OneDrivelink !==
                                                            "NA" ? (
                                                            <button
                                                                custom-drive={
                                                                    item.OneDrivelink
                                                                }
                                                                className={
                                                                    CourseClass[
                                                                        "PPT"
                                                                    ]
                                                                }
                                                                onClick={
                                                                    pptHandler
                                                                }>
                                                                <img
                                                                    src={PPt}
                                                                    alt="ppt"
                                                                />
                                                            </button>
                                                        ) : (
                                                            "NA"
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={3}
                                                style={{ textAlign: "center" }}>
                                                No Record Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {/* <TableLoad /> */}
                </div>
            </div>
        </Layout>
    );
}

export default Earning;
