import React from "react";
import ReactLoading from "react-loading";
import LoadClasses from "./loader.module.css";
function Loader() {
    return (
        <div className={LoadClasses["load"]}>
            <ReactLoading
                color="#0d3075"
                type="spokes"
                height={30}
                width={30}
            />
        </div>
    );
}

export default Loader;
