import { useState } from "react";
import Tabs from "./Tabs/tabs";
import Homepage from "./Homepage";
import useStore from "../../../store";
import Courses from "../AllCourse/Courses";
import HomeClasses from "./main.module.scss";
import Layout from "../../../Reusable/Parent/layout";
import Resources from "Pages/Parent/Home/Homepage/Resources/resource";
import Profile from "Pages/Parent/Profile";
import Transactions from "../Transactions/Transactions";
import ContactUs from "../Home/Homepage/ContactUs/Contact";
const Main = () => {
    const { Tab, setTab } = useStore((item: any) => item);

    return (
        <Layout>
            <div className={HomeClasses["Container"]}>
                <Tabs Active={Tab} setActive={setTab} />
                <div className={HomeClasses["Tab-Content"]}>
                    {Tab === "HOME" && <Homepage />}
                    {Tab === "ALL COURSES" && <Courses />}
                    {/* {Tab === "RESOURCES" && <Resources />} */}
                    {Tab === "PROFILE" && <Profile setTab={setTab} />}
                    {Tab === "WALLET TRANSACTIONS" && <Transactions />}
                    {Tab === "ContactUs" && <ContactUs />}
                </div>
            </div>
        </Layout>
    );
};

export default Main;
