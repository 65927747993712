import Modal from "react-modal";
import React from "react";
import Styles from "./cancel.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { Inputs, props } from "./types";
import { AiOutlineClose } from "react-icons/ai";
import ReactLoading from "react-loading";
Modal.setAppElement("#root");

const DemoForm: React.FC<props> = ({ modal, setModal, getDetails, id }) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Inputs>();

    // CLOSE FORM
    const closeModal = () => {
        setModal(false);
        reset();
    };

    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        let body = JSON.stringify({
            Scheduled_StartTime: getDetails.data.Scheduled_StartTime,
            Scheduled_StartDate: String(
                getDetails.data.Scheduled_StartDate
            ).substring(0, 10),
        });
        getDetails.setCancelLoad(true);
        getDetails.Cancel_Class_API(body, formdata.ReasonForCancellation);
    };

    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Cancel Class</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">Cancellation Reason</label>
                        <div className={Styles["form-control-one"]}>
                            <textarea
                                placeholder="Cancellation Reason"
                                {...register("ReasonForCancellation", {
                                    required: true,
                                })}
                            />
                            {errors.ReasonForCancellation && (
                                <span className={Styles["error"]}>
                                    this is required
                                </span>
                            )}
                        </div>
                    </div>
                    {getDetails.cancelLoad ? (
                        <div className={Styles["buttons"]}>
                            <ReactLoading
                                color="#0d3075"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        </div>
                    ) : (
                        <div className={Styles["buttons"]}>
                            <button type="submit" className={Styles["submit"]}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={closeModal}
                                className={Styles["close"]}>
                                Close
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
