import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { BsDownload } from "react-icons/bs";
import { HiMusicNote } from "react-icons/hi";
import reportClasses from "./report.module.scss";
import moment from "moment-timezone";
import { AiFillPlayCircle, AiOutlineLink } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
import { TfiBook } from "react-icons/tfi";
import { LuJoystick } from "react-icons/lu";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Tick from "Assets/tick.png";
import noTick from "Assets/notick.webp";
import { getResourcesByPlanNumber } from "Service/parent";
import useStore from "store";
import { Error } from "Reusable/utils/Toast";
import Moment from "react-moment";
import Loader from "Reusable/Common/LoadingComponents/pencilLoader/Loader";
type props = {
    showModal: boolean;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    Data?: any;
};

const Report: React.FC<props> = ({ setModal, showModal, Data }) => {
    const { setTab, timeZone } = useStore((item: any) => item);

    const [show, setShow] = useState(false);
    const [showResource, setshowResource] = useState<any>("");
    const [load, setLoad] = useState(true);
    const [planDetails, setplanDetails] = useState<any>();
    const closeModal = () => {
        setModal(false);
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const viewHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        let type = e.currentTarget.getAttribute("custom-type");

        if (showResource != type) {
            setshowResource(type);
        } else {
            setshowResource("");
        }
    };

    useEffect(() => {
        const planDetails = async () => {
            try {
                const data = await getResourcesByPlanNumber(
                    "GET",
                    null,
                    token,
                    Data.enrollCourseId,
                    Data.planNumber,
                    Data.planId
                );
                if (data.Status === 1) {
                    setplanDetails({
                        LessonPlans: data.LessonPlans,
                        ScheduledClasses: data.ScheduledClasses,
                    });
                }
            } catch {
                Error("Something went wrong Try again");
            } finally {
                setLoad(false);
            }
        };
        planDetails();
    }, []);
    const hasScheduledTimePassed = (
        scheduledStartDate: string,
        scheduledEndTime: string
    ): boolean => {
        console.log(scheduledStartDate);
        console.log(scheduledEndTime);
        // if()
        const scheduledDate = new Date(scheduledStartDate);
        const [hours, minutes] = scheduledEndTime.split(":").map(Number);
        scheduledDate.setHours(hours, minutes, 0, 0);

        const currentDate = new Date();

        return scheduledDate < currentDate;
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                contentLabel="Modal"
                className={reportClasses["modal"]}
                onRequestClose={closeModal}
                overlayClassName={reportClasses["overlay"]}>
                {load ? (
                    <Loader />
                ) : (
                    <div className={reportClasses["Container"]}>
                        <div className={reportClasses["Action-btns"]}>
                            {planDetails?.LessonPlans?.SongURLs?.length > 0 && (
                                <button
                                    onClick={viewHandler}
                                    custom-type={"Music"}>
                                    <div className={reportClasses["icon"]}>
                                        <HiMusicNote size={20} />
                                    </div>
                                    Music
                                </button>
                            )}
                            <div
                                className={`${reportClasses["hide"]} ${
                                    showResource === "Music"
                                        ? reportClasses["show"]
                                        : ""
                                }`}>
                                <ul>
                                    {planDetails?.LessonPlans?.SongURLs?.map(
                                        (item: any) => (
                                            <li>
                                                <a
                                                    href={item?.ResourceUrl}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {item?.ResourceName}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                            <button
                                onClick={() =>
                                    window.open(
                                        planDetails?.LessonPlans?.HomeworkURL,
                                        "_blank",
                                        "noreferrer"
                                    )
                                }>
                                <div className={reportClasses["icon"]}>
                                    <TfiBook size={20} />
                                </div>
                                Homework
                            </button>
                            {planDetails?.LessonPlans?.StoryURLs?.length >
                                0 && (
                                <button
                                    onClick={viewHandler}
                                    custom-type={"Story"}>
                                    <div className={reportClasses["icon"]}>
                                        <AiOutlineLink size={20} />
                                    </div>
                                    Story
                                </button>
                            )}
                            <div
                                className={`${reportClasses["hide"]} ${
                                    showResource === "Story"
                                        ? reportClasses["show"]
                                        : ""
                                }`}>
                                <ul>
                                    {planDetails?.LessonPlans?.StoryURLs?.map(
                                        (item: any) => (
                                            <li>
                                                <a
                                                    href={item.ResourceUrl}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {item.ResourceName}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                            {planDetails?.LessonPlans?.GamesURL?.length > 0 && (
                                <button
                                    onClick={viewHandler}
                                    custom-type={"Games"}>
                                    <div className={reportClasses["icon"]}>
                                        <LuJoystick size={20} />
                                    </div>
                                    Games
                                </button>
                            )}
                            <div
                                className={`${reportClasses["hide"]} ${
                                    showResource === "Games"
                                        ? reportClasses["show"]
                                        : ""
                                }`}>
                                <ul>
                                    {planDetails?.LessonPlans?.GamesURL?.map(
                                        (item: any) => (
                                            <li>
                                                <a
                                                    href={item.ResourceUrl}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {item.ResourceName}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                        {planDetails?.ScheduledClasses.length > 0 &&
                            planDetails?.ScheduledClasses?.map(
                                (item: any, index: any) => {
                                    let Data = String(
                                        item?.Scheduled_StartDate
                                    ).split("T");
                                    let formattedDate = moment(
                                        `${Data[0]}T${item?.Scheduled_StartTime}+05:30`
                                    )
                                        .tz(timeZone || moment.tz.guess())
                                        .format("DD MMM YYYY hh:mm A");
                                    return (
                                        <>
                                            <div
                                                className={
                                                    reportClasses[
                                                        "header_wrapper"
                                                    ]
                                                }>
                                                <header>
                                                    <p>{index + 1}</p>
                                                    <div
                                                        className={
                                                            reportClasses[
                                                                "Content"
                                                            ]
                                                        }>
                                                        <h3>
                                                            {
                                                                item?.ClassLessonName
                                                            }
                                                        </h3>
                                                        {/* <h4>{}</h4> */}
                                                        <p>
                                                            {item?.Scheduled_StartDate
                                                                ? formattedDate
                                                                : "NA"}
                                                        </p>
                                                        <p>
                                                            <RiErrorWarningFill color="#6752A4" />
                                                            <span>
                                                                Recorded on :
                                                            </span>
                                                            <span>
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.Scheduled_StartDate
                                                                        ? formattedDate
                                                                        : "NA"}
                                                                </Moment>
                                                            </span>
                                                        </p>
                                                        {/*     {item?.ZoomRestartData &&
                                                            item
                                                                ?.ZoomRestartData
                                                                .length > 0 && (
                                                                <div
                                                                    className={
                                                                        reportClasses[
                                                                            "video-download"
                                                                        ]
                                                                    }>
                                                                    <button
                                                                        onClick={() =>
                                                                            window.open(
                                                                                item?.PlayURL,
                                                                                "_blank",
                                                                                "noreferrer"
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            item?.PlayURL ===
                                                                            "NA"
                                                                        }>
                                                                        <AiFillPlayCircle
                                                                            size={
                                                                                21
                                                                            }
                                                                        />
                                                                        Play
                                                                    </button>
                                                                    <button
                                                                        onClick={() =>
                                                                            window.open(
                                                                                item?.DownloadUrl,
                                                                                "_blank",
                                                                                "noreferrer"
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            item?.DownloadUrl ===
                                                                            "NA"
                                                                        }>
                                                                        <BsDownload
                                                                            size={
                                                                                21
                                                                            }
                                                                        />
                                                                        Download
                                                                    </button>
                                                                </div>
                                                            )} */}
                                                        {/* {item?.PlayURL != "NA" &&
                                                    hasScheduledTimePassed(
                                                        item.ZoomLinkExpireDate,
                                                        item?.ZoomLinkExpireTime
                                                    ) ? (
                                                        <div
                                                            className={
                                                                reportClasses[
                                                                    "video-download"
                                                                ]
                                                            }>
                                                            <button
                                                                onClick={() =>
                                                                    window.open(
                                                                        planDetails
                                                                            ?.ScheduledClasses
                                                                            ?.PlayURL,
                                                                        "_blank",
                                                                        "noreferrer"
                                                                    )
                                                                }
                                                                disabled={
                                                                    planDetails
                                                                        ?.ScheduledClasses
                                                                        ?.PlayURL ===
                                                                    "NA"
                                                                }>
                                                                <AiFillPlayCircle
                                                                    size={21}
                                                                />
                                                                Play
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    window.open(
                                                                        planDetails
                                                                            ?.ScheduledClasses
                                                                            ?.DownloadUrl,
                                                                        "_blank",
                                                                        "noreferrer"
                                                                    )
                                                                }
                                                                disabled={
                                                                    planDetails
                                                                        ?.ScheduledClasses
                                                                        ?.DownloadUrl ===
                                                                    "NA"
                                                                }>
                                                                <BsDownload
                                                                    size={21}
                                                                />
                                                                Download
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.8rem",
                                                            }}>
                                                            Class Recording Expired
                                                        </span>
                                                    )} */}
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            setShow(
                                                                (prev) => !prev
                                                            )
                                                        }>
                                                        Report Card{" "}
                                                        <MdOutlineKeyboardArrowDown
                                                            size={21}
                                                            className={
                                                                reportClasses[
                                                                    "down-icon"
                                                                ]
                                                            }
                                                        />
                                                    </button>
                                                </header>
                                                <div
                                                    className={
                                                        reportClasses[
                                                            "report-card"
                                                        ]
                                                    }>
                                                    <div
                                                        className={`${
                                                            reportClasses[
                                                                "report-content"
                                                            ]
                                                        } ${
                                                            show
                                                                ? reportClasses[
                                                                      "show"
                                                                  ]
                                                                : ""
                                                        }`}>
                                                        <div>
                                                            <h3>Revision </h3>
                                                            <span>:</span>
                                                            <p>
                                                                <img
                                                                    src={
                                                                        item?.RevisionDone ===
                                                                        1
                                                                            ? Tick
                                                                            : noTick
                                                                    }
                                                                    alt="tick"
                                                                />
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h3>Homework </h3>
                                                            <span>:</span>
                                                            <p>
                                                                <img
                                                                    src={
                                                                        item?.HomeWorkCompleted ===
                                                                        1
                                                                            ? Tick
                                                                            : noTick
                                                                    }
                                                                    alt="tick"
                                                                />
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h3>New Topic </h3>
                                                            <span>:</span>
                                                            <p>
                                                                {
                                                                    item.NewTopicDone
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h3>Comments</h3>
                                                            <span>:</span>
                                                            <p>
                                                                {item.Comments}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {item?.ZoomRestartData &&
                                                item?.ZoomRestartData?.length >
                                                    0 &&
                                                item?.ZoomRestartData?.map(
                                                    (
                                                        zoomData: any,
                                                        secondIndex: any
                                                    ) => (
                                                        <div
                                                            className={
                                                                reportClasses[
                                                                    "header_wrapper"
                                                                ]
                                                            }
                                                            style={{
                                                                marginLeft:
                                                                    "3rem",
                                                            }}>
                                                            <header>
                                                                <div
                                                                    className={
                                                                        reportClasses[
                                                                            "Content"
                                                                        ]
                                                                    }>
                                                                    {/* <h4>{}</h4> */}
                                                                    <p>
                                                                        {item?.Scheduled_StartDate
                                                                            ? formattedDate
                                                                            : "NA"}
                                                                    </p>

                                                                    <div
                                                                        className={
                                                                            reportClasses[
                                                                                "video-download"
                                                                            ]
                                                                        }>
                                                                        {zoomData.PlayURL && (
                                                                            <button
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        zoomData?.PlayURL,
                                                                                        "_blank",
                                                                                        "noreferrer"
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    zoomData?.PlayURL ===
                                                                                    "NA"
                                                                                }>
                                                                                <AiFillPlayCircle
                                                                                    size={
                                                                                        21
                                                                                    }
                                                                                />
                                                                                Play
                                                                            </button>
                                                                        )}
                                                                        {zoomData?.DownloadUrl && (
                                                                            <button
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        zoomData?.DownloadUrl,
                                                                                        "_blank",
                                                                                        "noreferrer"
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    zoomData?.DownloadUrl ===
                                                                                    "NA"
                                                                                }>
                                                                                <BsDownload
                                                                                    size={
                                                                                        21
                                                                                    }
                                                                                />
                                                                                Download
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                    {/* {item?.PlayURL != "NA" &&
                                                    hasScheduledTimePassed(
                                                        item.ZoomLinkExpireDate,
                                                        item?.ZoomLinkExpireTime
                                                    ) ? (
                                                        <div
                                                            className={
                                                                reportClasses[
                                                                    "video-download"
                                                                ]
                                                            }>
                                                            <button
                                                                onClick={() =>
                                                                    window.open(
                                                                        planDetails
                                                                            ?.ScheduledClasses
                                                                            ?.PlayURL,
                                                                        "_blank",
                                                                        "noreferrer"
                                                                    )
                                                                }
                                                                disabled={
                                                                    planDetails
                                                                        ?.ScheduledClasses
                                                                        ?.PlayURL ===
                                                                    "NA"
                                                                }>
                                                                <AiFillPlayCircle
                                                                    size={21}
                                                                />
                                                                Play
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    window.open(
                                                                        planDetails
                                                                            ?.ScheduledClasses
                                                                            ?.DownloadUrl,
                                                                        "_blank",
                                                                        "noreferrer"
                                                                    )
                                                                }
                                                                disabled={
                                                                    planDetails
                                                                        ?.ScheduledClasses
                                                                        ?.DownloadUrl ===
                                                                    "NA"
                                                                }>
                                                                <BsDownload
                                                                    size={21}
                                                                />
                                                                Download
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "0.8rem",
                                                            }}>
                                                            Class Recording Expired
                                                        </span>
                                                    )} */}
                                                                </div>
                                                            </header>
                                                        </div>
                                                    )
                                                )}

                                            {item?.ZoomRestartData.length ==
                                                0 && (
                                                <div
                                                    className={
                                                        reportClasses[
                                                            "video-download"
                                                        ]
                                                    }>
                                                    {item.PlayURL && (
                                                        <button
                                                            onClick={() =>
                                                                window.open(
                                                                    item?.PlayURL,
                                                                    "_blank",
                                                                    "noreferrer"
                                                                )
                                                            }
                                                            disabled={
                                                                item?.PlayURL ===
                                                                "NA"
                                                            }>
                                                            <AiFillPlayCircle
                                                                size={21}
                                                            />
                                                            Play
                                                        </button>
                                                    )}
                                                    {item?.DownloadUrl && (
                                                        <button
                                                            onClick={() =>
                                                                window.open(
                                                                    item?.DownloadUrl,
                                                                    "_blank",
                                                                    "noreferrer"
                                                                )
                                                            }
                                                            disabled={
                                                                item?.DownloadUrl ===
                                                                "NA"
                                                            }>
                                                            <BsDownload
                                                                size={21}
                                                            />
                                                            Download
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    );
                                }
                            )}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Report;
