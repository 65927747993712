import React, { useState, useEffect, useLayoutEffect } from "react";
import navbarClasses from "./navbar.module.scss";
import { useNavigate, NavLink } from "react-router-dom";
import { menuItems } from "./config";
import { GiHamburgerMenu } from "react-icons/gi";
import swal from "sweetalert";
import Logo from "../../../Assets/logo.webp";
import { HiOutlineLogout } from "react-icons/hi";
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import useStore from "../../../store";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Services } from "Service/service";
const Sidenav: React.FC<any> = ({ Active, show, setShow }) => {
    const Navigate = useNavigate();
    const [NavItems, setNavItems] = useState<any>([]);

    const dropDown = useStore((item: any) => item.dropdown);
    const setDropdown = useStore((item: any) => item.setDropdown);

    const shouldAnimate = useStore((item: any) => item.shouldAnimate);
    const setShouldAnimate = useStore((item: any) => item.setShouldAnimate);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    async function Tutor_Logout() {
        try {
            const Logout = await Services.Logout(
                "POST",
                JSON.stringify({ Action: "Logged_Out" }),
                token
            );
        } catch (err: any) {
            console.log(err);
            Error("something went wrong please try again");
        }
    }
    const clickhandler = () => {
        setDropdown("");

        setShow((prev: any) => !prev);
    };
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    const closeHnadler = () => {
        if (window.innerWidth <= 900 && show) {
            setShow(false);
            setDropdown("");
        }
    };
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        closeHnadler();
    };

    useEffect(() => {
        window.addEventListener("resize", setDimension);

        return () => {
            window.removeEventListener("resize", setDimension);
        };
        // eslint-disable-next-line
    }, [screenSize]);
    useLayoutEffect(() => {
        setNavItems(menuItems);
    }, []);

    const logoutHandler = () => {
        swal("Are You sure you want to Sign out?", {
            icon: "warning",
            className: navbarClasses["logout-popup"],
            dangerMode: true,
            buttons: {
                // cancel: "Cancel",
                catch: {
                    text: "Logout",
                    value: "Logout",
                    className: navbarClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value: any) => {
            switch (value) {
                case "Logout":
                    Tutor_Logout();
                    Navigate("/");
                    localStorage.clear();
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    return (
        <div>
            <div
                className={`${show ? navbarClasses["backdrop"] : ""}`}
                onClick={() => {
                    setShow(false);
                    setDropdown("");
                }}></div>
            <div
                className={`${navbarClasses["Container"]} ${
                    !show ? navbarClasses["hide"] : ""
                }`}>
                <div
                    className={`${navbarClasses["hamburger"]} ${
                        !show ? navbarClasses["hamburgershow"] : ""
                    }`}>
                    <img
                        className={!show ? navbarClasses["img-size"] : ""}
                        src={Logo}
                        alt="logo"
                        onClick={() => {
                            window.open("/", "_blank", "noopener,noreferrer");
                        }}
                    />
                    <button
                        className={navbarClasses["hamburger-icon"]}
                        onClick={clickhandler}>
                        <GiHamburgerMenu size={22} color="white" />
                    </button>
                </div>

                <div className={navbarClasses["navlinks"]}>
                    {NavItems.map((item: any) => {
                        if (!item.multiple) {
                            return (
                                <>
                                    <NavLink
                                        key={item.id}
                                        id={item.id}
                                        to={item.menuLink}
                                        data-tooltip-id={item.id}
                                        data-tooltip-content={item.name}
                                        data-tooltip-position-strategy={"fixed"}
                                        className={`${
                                            Active === item.active
                                                ? navbarClasses["navActive"]
                                                : navbarClasses["navInactive"]
                                        } ${
                                            show
                                                ? navbarClasses["link-show"]
                                                : navbarClasses["link-hide"]
                                        }`}>
                                        <item.icon
                                            className={
                                                !show
                                                    ? navbarClasses["nav-icon"]
                                                    : navbarClasses[
                                                          "nav-icon-small"
                                                      ]
                                            }
                                        />
                                        {show && <span>{item.name}</span>}
                                    </NavLink>{" "}
                                    <ReactTooltip
                                        id={item.id}
                                        place="left"
                                        variant="info"
                                        hidden={show}
                                    />
                                </>
                            );
                        } else {
                            return (
                                <div className={navbarClasses["seo"]}>
                                    <div
                                        data-tooltip-id={item.id}
                                        data-tooltip-content={item.name}
                                        data-tooltip-position-strategy={"fixed"}
                                        className={navbarClasses["seo-item"]}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (dropDown === item.name) {
                                                setDropdown("");
                                            } else {
                                                if (!show) {
                                                    setShow(true);
                                                }
                                                setDropdown(item.name);
                                            }
                                        }}>
                                        <item.icon
                                            data-tooltip-id={item.id}
                                            data-tooltip-content={item.name}
                                            data-tooltip-position-strategy={
                                                "fixed"
                                            }
                                            className={
                                                navbarClasses["nav-icon"]
                                            }
                                        />
                                        <ReactTooltip
                                            id={item.id}
                                            place="left"
                                            variant="info"
                                            hidden={show}
                                        />
                                        {show && (
                                            <p>
                                                {item.name}{" "}
                                                {dropDown === item.name ? (
                                                    <RiArrowDropUpFill
                                                        size={30}
                                                    />
                                                ) : (
                                                    <RiArrowDropDownFill
                                                        size={30}
                                                    />
                                                )}{" "}
                                            </p>
                                        )}
                                    </div>
                                    <AnimatePresence
                                        initial={!(dropDown === item.name)}>
                                        {dropDown === item.name && (
                                            <motion.div
                                                key={dropDown}
                                                initial={{ height: 0 }}
                                                animate={{ height: "auto" }}
                                                exit={{ height: 0 }}
                                                transition={{ duration: 0.5 }}
                                                onAnimationComplete={() => {
                                                    setShouldAnimate(false);
                                                }}
                                                className={`${
                                                    navbarClasses[
                                                        "seo-item-nav"
                                                    ]
                                                } ${
                                                    shouldAnimate
                                                        ? "animate-class"
                                                        : ""
                                                }`}>
                                                {item.items.map(
                                                    (links: any, id: any) => (
                                                        <NavLink
                                                            key={id}
                                                            id={"1"}
                                                            to={links.menuLink}
                                                            className={`${
                                                                Active ===
                                                                links.active
                                                                    ? navbarClasses[
                                                                          "navActive"
                                                                      ]
                                                                    : navbarClasses[
                                                                          "navInactive"
                                                                      ]
                                                            } ${
                                                                !show
                                                                    ? navbarClasses[
                                                                          "sub-link-hide"
                                                                      ]
                                                                    : ""
                                                            }`}>
                                                            {show && (
                                                                <span
                                                                    title={
                                                                        links.name
                                                                    }>
                                                                    {links.name}
                                                                </span>
                                                            )}
                                                        </NavLink>
                                                    )
                                                )}
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            );
                        }
                    })}
                </div>
                <button
                    className={navbarClasses["logout"]}
                    onClick={logoutHandler}>
                    <HiOutlineLogout size={23} title="Layout" />
                    {show && <p>Logout</p>}
                </button>
            </div>
        </div>
    );
};

export default Sidenav;
