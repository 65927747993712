import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import profileClasses from "../profileDetailsModal/profile.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { ADD_Complaint } from "Service/parent";
import { warningSwal, SuccessSwal } from "Reusable/utils/Toast";
import { useCookies } from "react-cookie";
import { get_Enrolled_Course_List_By_Student } from "Service/parent";
import Select from "react-select";
import {
    setLocalStorageWithExpiration,
    getLocalStorageWithExpiration,
} from "Reusable/utils/expiryLocalData";
type props = {
    showModal: boolean;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    Data?: any;
};

const Report: React.FC<props> = ({ setModal, showModal, Data }) => {
    const {
        register,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<any>();
    const [cookie] = useCookies(["parentDetails", "StudentDetails"]);
    const [Courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setModal(false);
        reset();
    };
    const [Nature, setNature] = useState([]);
    const NatureOfComplaint = [
        "Instructor was unprepared",
        "Instructor did not cover the material adequately",
        "Instructor did not provide enough feedback",
        "Course Material was not clearly present ",
        "Others(please specify)",
    ];
    const get_courses = async () => {
        try {
            const allCourses = await get_Enrolled_Course_List_By_Student(
                "GET",
                null,
                token,
                localStorage.getItem("student"),
                "ALL"
            );
            if (allCourses.Status === 1) {
                setCourses(allCourses.Courses);
                setLocalStorageWithExpiration("allCourses", allCourses.Courses);
            }
        } catch (err: any) {
            console.error(err);
            warningSwal("Error", "Something went wrong try again later");
        }
    };
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    useEffect(() => {
        reset({
            FirstName: cookie?.parentDetails.FirstName,
            LastName: cookie?.parentDetails.LastName,
            EmailID: cookie?.parentDetails.EmailID,
            MobileNumber: cookie?.parentDetails.MobileNumber,
        });
        get_courses();
        // if (showModal && !getLocalStorageWithExpiration("allCourses")) {
        //     console.log(showModal, "1");
        //     get_courses();
        // } else {
        //     console.log(showModal);
        //     setCourses(getLocalStorageWithExpiration("allCourses"));
        // }
    }, []);
    const submit: SubmitHandler<any> = async (formData) => {
        if (Nature.length === 0) {
            setError("NatureOfComplaint", {
                message: "this is required field",
            });
            console.log(Nature);

            return;
        }

        setLoading(true);
        let url = ADD_Complaint;

        try {
            const [profileData] = await Promise.all([
                url(
                    "POST",
                    JSON.stringify({ ...formData, NatureOfComplaint: Nature }),
                    token
                ),
            ]);
            if (profileData?.Status === 1) {
                SuccessSwal(
                    "Form Submitted",
                    "Your Complaint on Class has sent Successfully to your Assigned relationship Manager.He will respond to you as soon as possible"
                );

                closeModal();
                reset();
            } else {
                warningSwal("warning", profileData.Message);
            }
            setTimeout(() => {
                setLoading(false);
            }, 200);
        } catch {
            setLoading(false);
            warningSwal("warning", "something Went wrong try again");
        }
    };
    const changeHandler = (value: any) => {
        if (value && value?.length > 0) {
            clearErrors("NatureOfComplaint");
            setNature(value?.map((item: any) => item.label));
        } else {
            setNature([]);
        }
        // setNature(value)
    };
    return (
        <>
            <Modal
                isOpen={showModal}
                contentLabel="Modal"
                className={profileClasses["modal"]}
                onRequestClose={closeModal}
                overlayClassName={profileClasses["overlay"]}>
                <div className={profileClasses["Container"]}>
                    {/* <div className={}></div> */}
                    <header>
                        <h3>Complaint Form</h3>
                        <button onClick={closeModal}>
                            <IoMdClose />
                        </button>
                    </header>

                    <form onSubmit={handleSubmit(submit)}>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Complaint Against <span>*</span>
                            </label>

                            <select
                                {...register("ComplaintAgainst", {
                                    required: true,
                                })}>
                                <option value="">Complaint Against</option>
                                <option value="Complaint on Class">
                                    Complaint on Class
                                </option>
                                <option value="Complaint on Teacher">
                                    Complaint on Teacher
                                </option>
                                <option value="others">others</option>
                            </select>
                            {errors?.ComplaintAgainst && (
                                <span>this is required Field</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">Student</label>
                            <select
                                {...register("StudentID", {
                                    required: true,
                                })}>
                                <option value="">Select Student</option>
                                {cookie?.StudentDetails &&
                                    cookie?.StudentDetails?.map((item: any) => (
                                        <option value={item.StudentID}>
                                            {item.FirstName}{" "}
                                            {item.LastName &&
                                                item.LastName != "NA" &&
                                                item.LastName}
                                        </option>
                                    ))}
                            </select>
                            {errors?.StudentID && (
                                <span>this is required Field</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Email <span>*</span>
                            </label>

                            <input
                                type="email"
                                {...register("EmailID", { required: false })}
                                disabled
                                placeholder="Enter Email Address"
                            />
                            {errors?.EmailID && (
                                <span>Email Address is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Mobile Number <span>*</span>
                            </label>

                            <input
                                type="number"
                                {...register("MobileNumber", {
                                    required: false,
                                })}
                                disabled
                                placeholder="Enter Mobile Number"
                            />
                            {errors?.MobileNumber && (
                                <span>Mobile Number is required</span>
                            )}
                        </div>
                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Course Instructor Name <span>*</span>
                            </label>

                            <input
                                type="text"
                                {...register("CourseInstructorName", {
                                    required: true,
                                })}
                                placeholder="Course Instructor Name"
                            />
                            {errors?.CourseInstructorName && (
                                <span>this is required</span>
                            )}
                        </div>

                        <div className={profileClasses["form_control"]}>
                            <label htmlFor="">
                                {" "}
                                Course <span>*</span>
                            </label>
                            <select
                                {...register("CourseID", {
                                    required: true,
                                })}>
                                <option value="" disabled>
                                    Select Course
                                </option>
                                {Courses?.map((item: any) => (
                                    <option value={item.CourseID}>
                                        {item.CourseName}
                                    </option>
                                ))}
                            </select>

                            {errors?.CourseID && (
                                <span>Course Name is Required</span>
                            )}
                        </div>
                        <div
                            className={profileClasses["form_control"]}
                            style={{ gridColumn: "1/-1" }}>
                            <label htmlFor="">
                                {" "}
                                Nature of Complaint <span>*</span>
                            </label>
                            <Select
                                options={NatureOfComplaint.map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                                isMulti
                                placeholder={"Nature of Complaint"}
                                onChange={changeHandler}
                                closeMenuOnSelect={false}
                                className={profileClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                                isSearchable={false}
                                isClearable
                            />
                            {errors?.NatureOfComplaint && (
                                <span>Nature Of Complaint is Required</span>
                            )}
                        </div>

                        <div
                            className={profileClasses["form_control"]}
                            style={{ gridColumn: "1/-1" }}>
                            <label htmlFor="">Summary</label>
                            <textarea
                                rows={7}
                                placeholder="Please provide a brief summary of you complaint"
                                {...register("Summary", { required: true })}
                            />
                            {errors?.Summary && (
                                <span>Summary is Required</span>
                            )}
                        </div>
                        <div
                            className={profileClasses["Action-btns"]}
                            style={{ paddingBottom: "1rem" }}>
                            <button type="submit" disabled={loading}>
                                {!loading ? "Submit" : "...Please wait"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Report;
