import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SkeletonClass from "./skeleton.module.scss";

function Schema() {
    return (
        <SkeletonTheme>
            <div className={SkeletonClass["Container"]}>
                <header>
                    <Skeleton height={"10rem"} />
                    <div>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </div>
                </header>
            </div>
        </SkeletonTheme>
    );
}

function Courseskeleton() {
    return (
        <div className={SkeletonClass["wrapper"]}>
            {[1, 2, 3, 1, 1, 1].map((item) => (
                <Schema />
            ))}
        </div>
    );
}

export default Courseskeleton;
