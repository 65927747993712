import { Route, Routes } from "react-router-dom";

import Home from "Pages/Parent/Home";
import EnrolledCourseDetails from "Pages/Parent/EnrolledCourseDetail/enrolledCourse";
import CourseDetails from "Pages/Parent/AllCourse/CourseDetails/details";
import Notifications from "Pages/Parent/Notifications/Notifications";
import Success from "Pages/Parent/SuccessFailurePartials/Success";
import Failure from "Pages/Parent/SuccessFailurePartials/Failure";
const ParentRoutes = () => {
    return (
        <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/EnrolledCourse" element={<EnrolledCourseDetails />} />
            <Route path="/Course-details" element={<CourseDetails />} />
            <Route path="/Notifications" element={<Notifications />} />
            <Route path="/Success" element={<Success />} />
            <Route path="/Failure" element={<Failure />} />
        </Routes>
    );
};
export default ParentRoutes;
