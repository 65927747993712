import React, { useState, useEffect } from "react";
import Layout from "Reusable/Parent/layout";
import NotificationClasses from "./Notification.module.scss";
import { IoIosNotificationsOutline } from "react-icons/io";
import Loader from "Reusable/Common/LoadingComponents/pencilLoader/Loader";
import Moment from "react-moment";
import { motion, AnimatePresence } from "framer-motion";
import { getNotifications } from "Service/parent";

interface notificationypes {
    CreatedAt: string;
    Title: string;
    Message: string;
}
function Notifications() {
    const [loading, setLoading] = useState(false);
    const [notification, setNotifications] = useState<notificationypes[]>([]);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const NotificationsData = await getNotifications(
                "GET",
                null,
                token
            );
            if (NotificationsData.Status === 1) {
                setNotifications(NotificationsData.Notifications);
            }
        } catch (err: any) {
            console.error(err);
            Error("Something went wrong try again later");
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }
    };
    useEffect(() => {
        fetchNotifications();
    }, []);
    return (
        <Layout>
            <div className={NotificationClasses["Container"]}>
                <h3>Notifications</h3>
                {loading ? (
                    <div className={NotificationClasses["loader"]}>
                        <Loader />
                    </div>
                ) : (
                    <div
                        className={NotificationClasses["notification_wrapper"]}>
                        <AnimatePresence>
                            {notification.length > 0 ? (
                                notification.map((item, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, x: -100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: -100 }}
                                        transition={{
                                            duration: 0.5,
                                            delay: index * 0.2,
                                        }}
                                        className={
                                            NotificationClasses["notification"]
                                        }>
                                        <IoIosNotificationsOutline size={30} />
                                        <div>
                                            <h3>{item?.Title}</h3>
                                            <p>{item?.Message}</p>
                                            <p>
                                                <Moment
                                                    fromNow
                                                    subtract={{
                                                        hours: 5,
                                                        minutes: 30,
                                                    }}>
                                                    {item?.CreatedAt}
                                                </Moment>
                                            </p>
                                        </div>
                                    </motion.div>
                                ))
                            ) : (
                                <p>No New Notifications</p>
                            )}
                        </AnimatePresence>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default Notifications;
