import React, { useState, useEffect } from "react";
import Layout from "../../../Reusable/Tutor/Layout/index";
import CourseClass from "./courses.module.scss";
import { Helmet } from "react-helmet";
import { Error } from "../../../Reusable/utils/Toast";
import { Services } from "../../../Service/service";
import TableLoad from "./TableLoad/load";
import { useNavigate } from "react-router";
function Earning() {
    const Navigate = useNavigate();
    const [Loading, setLoading] = useState<boolean>(true);
    const [Courses, set_Courses] = useState<any>([]);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        Tutor_Courses();
        // eslint-disable-next-line
    }, []);

    //Fetching  Earnings
    async function Tutor_Courses() {
        setLoading(true);
        try {
            const AllClasses = await Services.Courses("GET", null, token);
            setTimeout(() => {
                setLoading(false);
            }, 200);
            set_Courses(AllClasses.TutorCourses);
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    const NavHandler = (e: React.MouseEvent<HTMLElement>) => {
        let CourseID = e.currentTarget.getAttribute("id");
        let courseName = e.currentTarget.getAttribute("course-name");
        Navigate(`/Tutor/Courses/LessonPlan?CourseId=${CourseID}`, {
            state: { Name: courseName },
        });
    };
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Courses</title>
            </Helmet>
            <div className={CourseClass["Container"]}>
                <div className={CourseClass["Tutor"]}>
                    <h3>Courses</h3>

                    {Loading ? (
                        <TableLoad />
                    ) : (
                        <div className={CourseClass["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Course Name</th>
                                        <th>Category</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Courses.length > 0 ? (
                                        Courses?.map((item: any) => {
                                            return (
                                                <tr>
                                                    <td>{item.CourseName}</td>
                                                    <td>{item.CategoryName}</td>

                                                    <td>
                                                        <div
                                                            className={
                                                                CourseClass[
                                                                    "Action-btns"
                                                                ]
                                                            }>
                                                            <button
                                                                id={
                                                                    item.CourseID
                                                                }
                                                                onClick={
                                                                    NavHandler
                                                                }
                                                                course-name={
                                                                    item.CourseName
                                                                }>
                                                                Lesson Plan
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={3}
                                                style={{ textAlign: "center" }}>
                                                No Record Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {/* <TableLoad /> */}
                </div>
            </div>
        </Layout>
    );
}

export default Earning;
