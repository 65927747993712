import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Request from "./leaveRequests/request";
import LeaveClasses from "./leave.module.scss";
import Balance from "./Balance";
import Layout from "../../../Reusable/Tutor/Layout";
import Histroy from "./history/history";
import Apply from "./ApplyLeave/apply";
import { Services } from "../../../Service/service";
import Loader from "../../../Reusable/Common/LoadingComponents/loader/loader";
import Details from "./requestdetail/details";
function Leave() {
    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [load, setLoad] = useState(true);
    const [edit, setEdit] = useState<any>(null);
    const [history, setLeaveHistory] = useState([]);
    const [leaveBalance, setLeaveBalance] = useState({});
    const [PendingLeaves, setPending] = useState([]);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const toggleModal = (e: any) => {
        e.stopPropagation();
        setShowModal((prev) => !prev);
        if (edit) {
            setEdit(null);
        }
    };
    const toggleDetails = (e: any) => {
        e.stopPropagation();
        setShowDetails((prev) => !prev);
    };
    const fetchAll = async () => {
        try {
            const [LeaveHistory, Balance, pending] = await Promise.all([
                Services.LeaveHistory("GET", null, token),
                Services.getLeaveBalances("GET", null, token),
                Services.PendingLeaveRequest("GET", null, token),
            ]);
            if (
                LeaveHistory.Status === 1 &&
                Balance.Status === 1 &&
                pending.Status === 1
            ) {
                setLeaveHistory(LeaveHistory.LeaveRequests);
                setLeaveBalance(Balance.LeaveBalances[0]);
                setPending(pending.PendingLeaves);
            }
            console.log(LeaveHistory);
        } catch (err) {
            alert("something went wrong try again");
        } finally {
            setTimeout(() => {
                setLoad(false);
            }, 400);
        }
    };
    useEffect(() => {
        fetchAll();
        // eslint-disable-next-line
    }, []);
    return (
        <Layout Active={"Leave"}>
            {load && <Loader />}
            {showModal && (
                <Apply
                    setLoad={setLoad}
                    toggleModal={toggleModal}
                    setShowModal={setShowModal}
                    fetchAll={fetchAll}
                    edit={edit}
                    setEdit={setEdit}
                />
            )}
            {showDetails && (
                <Details
                    setLoad={setLoad}
                    toggleModal={toggleDetails}
                    setShowModal={setShowDetails}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Leave</title>
            </Helmet>
            <div className={LeaveClasses["Container"]}>
                <Request
                    setModal={setShowModal}
                    pending={PendingLeaves}
                    setShowDetails={setShowDetails}
                    setEdit={setEdit}
                />
                <Balance leaveBalance={leaveBalance} />
                <Histroy history={history} />
            </div>
        </Layout>
    );
}

export default Leave;
