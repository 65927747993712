import React, { useState } from "react";
import TimezoneClasses from "./Timezone.module.scss";
import Modal from "react-modal";
import Moment from "react-moment";
import Select from "react-select";
import moment from "moment-timezone";
import { Update_Parent_Profile, get_parent_Details } from "Service/parent";
import { SuccessSwal, warningSwal } from "Reusable/utils/Toast";
import useStore from "store";
import "moment-timezone";

interface CustomModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TimeZoneOption {
    value: string;
    label: string;
}

interface TimeZoneConversionProps {
    startDate: string;
    startTime: string;
}

// List of time zones
const timeZoneOptions: TimeZoneOption[] = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
}));
console.log(timeZoneOptions);
const TimeZone: React.FC<CustomModalProps> = ({ isOpen, setIsOpen }) => {
    const [loading, setLoading] = useState(false);
    const { timeZone, setTimeZone } = useStore((item: any) => item);
    const [selectedTimeZone, setSelectedTimeZone] =
        useState<TimeZoneOption | null>({ value: timeZone, label: timeZone });

    console.log(selectedTimeZone);
    const handleChange = (selectedOption: any) => {
        setSelectedTimeZone(selectedOption as TimeZoneOption);
    };
    const submitHandler = async () => {
        const token = {
            Authorization: `token ${localStorage.getItem("learn2readToken")}`,
        };
        setLoading(true);
        const formData = { WebTimeZone: selectedTimeZone?.value };
        try {
            const [profileData] = await Promise.all([
                Update_Parent_Profile("PUT", JSON.stringify(formData), token),
            ]);
            if (profileData?.Status === 1) {
                SuccessSwal("success", "Profile updated Successfully");
            } else {
                warningSwal("warning", profileData.Message);
            }
            setTimeout(() => {
                setLoading(false);
                setTimeZone(selectedTimeZone?.value);
                localStorage.setItem("TimeZone", selectedTimeZone?.value || "");
                setIsOpen(false);
            }, 200);
        } catch {
            setLoading(false);
            Error("something Went wrong try again");
        }
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Modal"
            className={TimezoneClasses["modal"]}
            overlayClassName={TimezoneClasses["overlay"]}>
            <div className={TimezoneClasses["Container"]}>
                <h3>TimeZone</h3>
                <div>
                    <Select
                        options={timeZoneOptions}
                        onChange={handleChange}
                        autoFocus
                        value={selectedTimeZone}
                        placeholder="Select Time Zone"
                        classNames={{
                            menuList: () => TimezoneClasses["menuList"],
                        }}
                    />
                    <p className={TimezoneClasses["error"]}>
                        Note: Changing the time zone will apply the selected
                        time zone to the entire application.
                    </p>
                    {/* <button >Submit</button> */}
                    <button onClick={submitHandler} disabled={loading}>
                        {!loading ? "Submit" : "...Please wait"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default TimeZone;
