import Layout from "../../../Reusable/Tutor/Layout";
import { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Error } from "../../../Reusable/utils/Toast";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Services } from "../../../Service/service";
import EventDetails from "./Events";
import eventClasses from "./events.module.scss";
import { CalenderDataGenerator } from "./data";
import Select from "react-select";
import { requestBody, studentProps, Event } from "./types";
import MainLoad from "../../../Reusable/Common/LoadingComponents/loader/loader";
const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

function Calender() {
    const [events, setEvents] = useState([]);
    const [currentDate, setCurrentDate] = useState(null);
    const popupRef = useRef<HTMLDivElement>(null);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [selectedId, setSelectedId] = useState({
        tutor: null,
        student: null,
    });
    const [load, setLoad] = useState(true);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [StudentSearchString, setStudentSearchString] = useState(null);
    const [StudentSearchLoad, setStudentSearchLoad] = useState(false);
    const [student, setStudent] = useState([]);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    // GET scheduled Classes
    async function Get_Scheduled_Classes(body: any) {
        try {
            const response = await Services.get_all_scheduledClasses(
                "POST",
                JSON.stringify(body),
                token
            );
            if (response.Status === 1) {
                setEvents(CalenderDataGenerator(response.ScheduleClasess));
            }
        } catch (err) {
            console.log(err);
            Error("something went wrong try again");
        } finally {
            setLoad(false);
        }
    }

    useEffect(() => {
        let tutorDetails: any = localStorage.getItem("LoginDetails");
        if (tutorDetails) {
            tutorDetails = JSON.parse(tutorDetails);
        }
        let body: any = {
            Scheduled_StartDate: new Date(),
            localizer: "",
            TutorID: tutorDetails?.TutorID,
        };
        Get_Scheduled_Classes(body);
        const handleOutsideClick = (e: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current?.contains(e.target as Node)
            ) {
                setSelectedEvent(null);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (typeof StudentSearchString == "string" && StudentSearchString) {
                setStudentSearchLoad(true);

                try {
                    const data = await Services.searchActiveStudent(
                        "POST",
                        JSON.stringify({ SearchString: StudentSearchString }),
                        token
                    );
                    const formattedOptions = data.Students.map(
                        (student: studentProps) => ({
                            label: `${student.FirstName} ${student.LastName}`,
                            value: student.StudentID,
                        })
                    );
                    setStudent(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setStudentSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
        // eslint-disable-next-line
    }, [StudentSearchString]);

    useEffect(() => {
        let tutorDetails: any = localStorage.getItem("LoginDetails");
        if (tutorDetails) {
            tutorDetails = JSON.parse(tutorDetails);
        }
        let body: requestBody = {
            Scheduled_StartDate: currentDate ? currentDate : new Date(),
            TutorID: tutorDetails.TutorID,
        };

        if (selectedId.student) {
            body = {
                ...body,
                StudentID: selectedId.student,
                IsStudentSearch: 1,
            };
        }
        if (currentDate) {
            Get_Scheduled_Classes(body);
        }
        // eslint-disable-next-line
    }, [currentDate]);

    const handleChangeStudent = (selectedOption: any) => {
        setStudentSearchString(selectedOption);
    };

    const onStudentSelect = (option: any) => {
        if (option) {
            setSelectedId((prev) => {
                return { ...prev, student: option.value };
            });
            console.log("2");
            setSelectedStudent(option);
            let tutorDetails: any = localStorage.getItem("LoginDetails");
            if (tutorDetails) {
                tutorDetails = JSON.parse(tutorDetails);
            }
            let body: requestBody = {
                IsStudentSearch: 1,
                StudentID: option.value,
                TutorID: tutorDetails.TutorID,
                Scheduled_StartDate: currentDate ? currentDate : new Date(),
            };

            Get_Scheduled_Classes(body);
        }
    };

    const StudentClearHandler = () => {
        setStudentSearchLoad(false);
        setSelectedStudent(null);

        setSelectedId((prev) => {
            return { ...prev, student: null };
        });
        let tutorDetails: any = localStorage.getItem("LoginDetails");
        if (tutorDetails) {
            tutorDetails = JSON.parse(tutorDetails);
        }
        let body: requestBody = {
            TutorID: tutorDetails.TutorID,
            Scheduled_StartDate: currentDate ? currentDate : new Date(),
        };

        Get_Scheduled_Classes(body);
    };
    const eventStyleGetter = (event: any) => {
        const style = {
            backgroundColor: event.color,
            fontWeight: "550",
            fontSize: "12px",
            color: "white",
        };
        return {
            style,
        };
    };
    function handleNavigate(newDate: any) {
        setCurrentDate(newDate);
    }

    //Detailed View
    function handleEventSelect(event: any, e: any) {
        setSelectedEvent(event);

        const bounds = e.target.getBoundingClientRect();
        setTooltipPosition({
            top: bounds.top - 10, // Adjust as needed
            left: bounds.right, // Display to the right of the selected date
        });
    }

    return (
        <>
            {load && <MainLoad />}
            <Layout Active={"Calender"}>
                <div className={eventClasses["contain"]}>
                    <div className={eventClasses["calender-wrapper"]}>
                        <div className={eventClasses["calender"]}>
                            <DnDCalendar
                                defaultDate={moment().toDate()}
                                defaultView="month"
                                events={events}
                                eventPropGetter={eventStyleGetter}
                                localizer={localizer}
                                messages={{
                                    agenda: "List",
                                    previous: "Previous",
                                }}
                                resizable
                                handleDragStart={() => console.log("1")}
                                style={{ height: "90vh", width: "100%" }}
                                onSelectEvent={handleEventSelect}
                                popup
                                onNavigate={handleNavigate}
                            />

                            {selectedEvent && (
                                <div
                                    className="event-tooltip"
                                    ref={popupRef}
                                    style={{
                                        top: tooltipPosition.top,
                                        left: tooltipPosition.left,
                                    }}>
                                    <EventDetails
                                        event={selectedEvent}
                                        setSelectedEvent={setSelectedEvent}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={eventClasses["search"]}>
                            <h3>Filters</h3>

                            <div className={eventClasses["form-control"]}>
                                <label htmlFor="Tutor">Student</label>
                                <Select
                                    options={student}
                                    onInputChange={handleChangeStudent}
                                    onChange={onStudentSelect}
                                    value={selectedStudent}
                                    isLoading={StudentSearchLoad}
                                    noOptionsMessage={() => "No Student Found"}
                                    className={eventClasses["select"]}
                                    styles={{
                                        // Set the width to 100%
                                        control: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                        }),
                                    }}
                                    isClearable
                                    components={{
                                        ClearIndicator: () => (
                                            <button
                                                onClick={StudentClearHandler}>
                                                Clear
                                            </button>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Calender;
