import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./challenge.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { Inputs, props } from "./types";
import { Services } from "Service/service";
import { warningSwal } from "Reusable/utils/Toast";

import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
Modal.setAppElement("#root");

const DemoForm: React.FC<props> = ({ modal, setModal, getDetails }) => {
    const {
        handleSubmit,
        reset,
        register,

        formState: { errors },
    } = useForm<Inputs>();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setModal(null);
        reset();
    };
    console.log(getDetails.details);
    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        console.log(getDetails.detail);
        let Body: any = {
            ...formdata,
            TutorEarningID: modal,
            ScheduleClassID: getDetails.details[0]?.ScheduleClassID,
        };
        setLoading(true);

        try {
            const CompleteClass = await Services.ChallengePenalty(
                "POST",
                JSON.stringify(Body),
                token
            );
            setLoading(false);
            if (CompleteClass.Status === 1) {
                swal("Completed", CompleteClass.Message, "success");
                setModal(false);
                getDetails.Tutor_Earnings();
            } else {
                warningSwal("Warning", CompleteClass?.Message);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            warningSwal("Error", "Something went wrong please try again");
        }
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div
                className={Styles["form-wrapper"]}
                style={{ cursor: loading ? "progress" : "default" }}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Challenge</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            Reason <span>:</span>
                        </label>
                        {getDetails.details[0].IsPenalityChallenged != 0 ? (
                            <p>{getDetails.details[0].Reason}</p>
                        ) : (
                            <textarea
                                {...register("Reason", {
                                    required: "this is required",
                                })}></textarea>
                        )}
                        <p></p>
                        {errors?.Reason && (
                            <p className={Styles["error-msg"]}>
                                {errors?.Reason?.message}
                            </p>
                        )}
                    </div>

                    {/* eslint-disable-next-line */}
                    {getDetails.details[0].IsPenalityChallenged == 0 && (
                        <div className={Styles["buttons"]}>
                            <button
                                type="submit"
                                className={Styles["submit"]}
                                disabled={loading}>
                                {loading ? "Please wait..." : "Challenge"}
                            </button>

                            <button
                                type="button"
                                onClick={closeModal}
                                className={Styles["close"]}>
                                Close
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
