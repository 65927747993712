export function setLocalStorageWithExpiration(key: any, value: any) {
    const expiration = new Date().getTime() + 30 * 1000; // Calculate expiration time
    const data = { value, expiration };

    localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorageWithExpiration(key: any) {
    let item = localStorage.getItem(key);

    if (item) {
        let storedData = JSON.parse(item);

        if (storedData && storedData.expiration > new Date().getTime()) {
            return storedData.value; // Return the value if it's still valid
        } else {
            localStorage.removeItem(key); // Remove the item if it has expired
            return null; // Return null for expired data
        }
    }
}
