import { useState, useEffect, ChangeEvent } from "react";
import Layout from "../../../../Reusable/Tutor/Layout";
import DetailClasses from "./details.module.scss";
import { GrMail } from "react-icons/gr";
import { FaPhone } from "react-icons/fa";
import { Services } from "../../../../Service/service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Error, Success } from "../../../../Reusable/utils/Toast";
import Moment from "react-moment";
import Select from "react-select";
import SkeletonLoad from "./SkeletonLoad/skeleton";

import Complete from "../completeClass/completeClass";
import { FaEdit } from "react-icons/fa";
import Loader from "../../../../Reusable/Common/LoadingComponents/loader/loader";
import CancelClass from "../CancelClass/cancel";
import swal from "sweetalert";
import moment from "moment";
function Details() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [cancelLoad, setCancelLoad] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [completeData, setCompleteData] = useState<any>(null);
    const [cancel, setCancel] = useState(false);
    const [isChargable, setIsChargable] = useState<number>(1);
    const [data, setData] = useState<any>();
    const [attendanceLoad, setAttendanceLoad] = useState<boolean>(false);

    const [completeClass, setCompleteClass] = useState<any>(null);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    // INITIAL RENDER
    useEffect(() => {
        Get_Details(params.get("DemoClassID"));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "hidden") {
                // console.log("Tab is inactive");
                // Call a function when the tab is left
            } else if (document.visibilityState === "visible") {
                Get_Details(params.get("DemoClassID"));
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, []);

    // GET SCHEDULE CLASS DETAILS
    async function Get_Details(id: string | null) {
        setLoading(true);
        try {
            const AllClasses = await Services.getDemoClassesDetails(
                "GET",
                null,
                token,
                id
            );
            if (AllClasses.Status === 1) {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                setData(AllClasses.Batches);
            } else {
                Error(AllClasses.Message);
            }
        } catch (err: any) {
            console.log(err);
            if (attendanceLoad) {
                setAttendanceLoad(false);
            }
            setLoading(false);
            Error("something went wrong please try again");
        }
    }

    // COMPLETE CLASS FUNCTION
    async function Cancel_Class_API(validatebody: any, reason: string) {
        let LoginData: any = localStorage.getItem("LoginDetails");

        let body = {
            BatchSize: data.BatchSize,

            Students: data?.Students,
            TutorEmailID: data?.TutorEmailID,
            ActionType: "Others",
            ActionComments: reason,
        };
        console.log(body);
        // return;
        setCancelLoad(true);
        /*  try {
            const AllClasses = await Services.cancelClassValidate(
                "POST",
                validatebody,
                token
            );
            if (AllClasses.Status === 1) {
                const CompleteClass = await Services.Cancel_Class(
                    "PUT",
                    JSON.stringify({
                        ...body,
                        IsHourBefore: AllClasses.IsHourBefore,
                    }),
                    token,
                    data?.ScheduleClassID
                );
                if (CompleteClass.Status === 1) {
                    swal("Cancelled", CompleteClass.Message, "success");
                    Get_Details(params.get("id"));
                    setCancel(false);
                } else {
                    Error(CompleteClass?.Message);
                    setCancelLoad(false);
                }
            }
        } catch (err: any) {
            console.log(err);
            setCancelLoad(false);
            Error("something went wrong please try again");
        } */
        try {
            const CompleteClass = await Services.CancelClassDemo(
                "PUT",
                JSON.stringify({
                    ...body,
                }),
                token,
                params.get("DemoClassID")
            );
            if (CompleteClass.Status === 1) {
                swal("Cancelled", CompleteClass.Message, "success");
                Get_Details(params.get("DemoClassID"));
                setCancel(false);
            } else {
                Error(CompleteClass?.Message);
                setCancelLoad(false);
            }
        } catch (err: any) {
            console.log(err);
            setCancelLoad(false);
            Error("something went wrong please try again");
        }
    }
    // CANCEL CLASS
    function cancelHandler() {
        setCancel(true);
    }

    //Mark attendance
    async function attendanceHandler(event: ChangeEvent<HTMLSelectElement>) {
        const Scheduled_Classes_StudentID =
            event.currentTarget.getAttribute("attr-studentId");
        const Attendance = event.target.value;
        let body = JSON.stringify({
            Attendance,
            DemoStudentID: Scheduled_Classes_StudentID,
            BatchName: data.BatchName,
        });

        try {
            setAttendanceLoad(true);
            const Response = await Services.MarkAttendaceDemo(
                "POST",
                body,
                token
            );
            if (Response.Status === 1) {
                setAttendanceLoad(false);
                Success(Response.Message);
                let details = data;

                let index = details.ActiveStudents?.findIndex(
                    (item: any) =>
                        // eslint-disable-next-line
                        item.DemoStudentID == Scheduled_Classes_StudentID
                );
                console.log(index, details.ActiveStudents[index]);
                details.ActiveStudents[index].Attendance = Number(Attendance);
                setData(details);
            } else {
                setAttendanceLoad(false);
                Error(Response?.Message);
            }
        } catch (err) {
            console.log(err);
            console.log(data);
            setAttendanceLoad(false);
            Error("Something went wrong please try again");
        }
    }

    const BackButton = () => {
        Navigate(-1);
    };
    const startSessions = async () => {
        {
            const ScheduleClassID = params.get("DemoClassID");
            setAttendanceLoad(true);

            let body: any = {
                DemoClassID: ScheduleClassID,
                TutorID: data.TutorID,
                BatchSize: data?.BatchSize,
                DemoStartDate: data?.DemoStartDate,
                DemoStartTime: data?.DemoStartTime,
            };
            /*     if (data?.StartURL === "NA") {
                body = {
                    ...body,
                    ActualStartTime: moment().format("HH:MM"),
                    ActualStartDate: moment().format("YYYY-MM-DD"),
                };
            } */

            try {
                setAttendanceLoad(true);
                const Response = await Services.getSessionsDemo(
                    "POST",
                    JSON.stringify(body),
                    token
                );
                if (Response.Status === 1) {
                    Get_Details(ScheduleClassID);

                    setAttendanceLoad(false);
                } else {
                    setAttendanceLoad(false);
                    Error(Response?.Message);
                }
            } catch (err) {
                setAttendanceLoad(false);
                Error("Something went wrong please try again");
            }
        }
    };
    const updateActaulTime = async () => {
        {
            const ScheduleClassID = params.get("DemoClassID");

            let body: any = {
                DemoClassID: ScheduleClassID,
                TutorID: data.TutorID,
                BatchSize: data?.BatchSize,
                ActualStartTime: moment(new Date()).format("HH:mm"),
                ActualStartDate: moment(new Date()).format("YYYY-MM-DD"),
            };
            if (
                data?.ActualStartTime == "00:00:00" &&
                data?.ActualStartDate == "0000-00-00"
            ) {
                try {
                    setAttendanceLoad(true);
                    const Response = await Services.updateDateTime(
                        "POST",
                        JSON.stringify(body),
                        token
                    );
                    if (Response.Status === 1) {
                        Get_Details(ScheduleClassID);

                        setAttendanceLoad(false);
                    } else {
                        setAttendanceLoad(false);
                        Error(Response?.Message);
                    }
                } catch (err) {
                    setAttendanceLoad(false);
                    Error("Something went wrong please try again");
                }
            }
        }
    };
    const calculateAge = (birthDate: string) => {
        const birthMoment = moment(birthDate);
        const now = moment();

        const years = now.diff(birthMoment, "years");
        birthMoment.add(years, "years"); // Adjust birthMoment to the last birthday
        const months = now.diff(birthMoment, "months");

        return `${years} years and ${months} months`;
    };
    function getTimeDifferenceInMinutes(
        ActualStartDate: any,
        ActualStartTime: any,
        ActualEndDate: any,
        ActualEndTime: any
    ) {
        // Combine date and time into Date objects
        const startDateTime: any = new Date(
            `${String(ActualStartDate).split("T")[0]}T${ActualStartTime}Z`
        );

        const endDateTime: any = new Date(
            `${String(ActualEndDate).split("T")[0]}T${ActualEndTime}Z`
        );

        // Calculate the difference in milliseconds
        const timeDifferenceMs = endDateTime - startDateTime;
        console.log(startDateTime, endDateTime, timeDifferenceMs);
        // Convert milliseconds to minutes
        const minutes = Math.floor(timeDifferenceMs / (1000 * 60));

        return minutes;
    }
    return (
        <Layout Active={"Demo Classes"}>
            {attendanceLoad && (
                <div className={DetailClasses["loader"]}>
                    <Loader />
                </div>
            )}

            {completeClass && (
                <Complete
                    modal={completeClass}
                    setModal={setCompleteClass}
                    getDetails={{
                        details: Get_Details,
                        setLoading: setLoading,
                        data: data,
                    }}
                    completeData={completeData}
                    setCompleteData={setCompleteData}
                    id={params.get("DemoClassID")}
                />
            )}
            {cancel && (
                <CancelClass
                    modal={cancel}
                    setModal={setCancel}
                    getDetails={{
                        details: Get_Details,
                        setLoading: setLoading,
                        Cancel_Class_API: Cancel_Class_API,
                        isChargable,
                        data: data,
                        cancelLoad: cancelLoad,
                        setCancelLoad: setCancelLoad,
                    }}
                    id={params.get("id")}
                />
            )}

            <Helmet>
                <meta charSet="utf-8" />
                <title>Class Details</title>
            </Helmet>
            <div className={DetailClasses["Container"]}>
                <button className={DetailClasses["back"]} onClick={BackButton}>
                    Back
                </button>
                <div className={DetailClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad />
                    ) : (
                        <div style={{ width: "100%", overflow: "auto" }}>
                            <div className={DetailClasses["header"]}>
                                <h3>
                                    <span></span>Class:{data?.DemoClassName}
                                </h3>
                                <div className={DetailClasses["action-btns"]}>
                                    {data?.DemoClassStatus === 1 && (
                                        <button
                                            className={
                                                DetailClasses["complete-class"]
                                            }
                                            onClick={() =>
                                                setCompleteClass(true)
                                            }
                                            disabled={moment(
                                                `${data?.Scheduled_StartDate} ${data?.Scheduled_StartTime}`,
                                                "YYYY-MM-DD HH:mm:ss"
                                            ).isAfter(moment())}>
                                            Complete Class
                                        </button>
                                    )}
                                    {data?.DemoClassStatus === 1 && (
                                        <button
                                            className={
                                                DetailClasses["complete-class"]
                                            }
                                            disabled={moment(
                                                data?.DemoStartDate,
                                                "YYYY-MM-DD"
                                            ).isAfter(moment(), "day")}
                                            onClick={startSessions}>
                                            {data?.StartURL === "NA"
                                                ? "Start Session"
                                                : "Restart Session"}
                                        </button>
                                    )}
                                    {/* 
                                    {data?.DemoClassStatus === 1 && (
                                        <button
                                            className={
                                                DetailClasses["cancel-class"]
                                            }
                                            onClick={cancelHandler}>
                                            Cancel
                                        </button>
                                    )} */}
                                </div>
                                {/*  {data?.DemoClassStatus === 1 &&
                                    data?.ReScheduleStatus === 1 &&
                                    data?.CancelRequestStatus === 0 && (
                                        <p
                                            className={
                                                DetailClasses["rescheduled"]
                                            }>
                                            Rescheduled{" "}
                                        </p>
                                    )} */}
                                {/*  {data?.DemoClassStatus === 1 &&
                                    data?.ReScheduleStatus === 2 &&
                                    data?.CancelRequestStatus === 0 && (
                                        <p
                                            className={
                                                DetailClasses["requested"]
                                            }>
                                            Reschedule Requested
                                        </p>
                                    )} */}
                                {/*   {data?.DemoClassStatus === 1 &&
                                    data?.ReScheduleStatus === 3 &&
                                    data?.CancelRequestStatus === 0 && (
                                        <p
                                            className={
                                                DetailClasses["rejected"]
                                            }>
                                            Rescheduled Request rejected
                                        </p>
                                    )}
 */}
                                {data?.DemoClassStatus === 3 && (
                                    <p className={DetailClasses["rejected"]}>
                                        Cancelled
                                    </p>
                                )}
                                {/*  {data?.CancelRequestStatus === 2 && (
                                    <p className={DetailClasses["rejected"]}>
                                        Cancel Requested
                                    </p>
                                )}
                                {data?.CancelRequestStatus === 3 && (
                                    <p className={DetailClasses["rejected"]}>
                                        Cancel rejected
                                    </p>
                                )} */}
                                {data?.DemoClassStatus === 2 && (
                                    <p
                                        className={
                                            DetailClasses["complete-class"]
                                        }>
                                        Completed
                                    </p>
                                )}
                            </div>
                            <div className={DetailClasses["profile"]}>
                                <div className={DetailClasses["internal"]}>
                                    <h3>Meeting Details</h3>

                                    <div>
                                        <h3>Course</h3>
                                        <p>{data?.CourseName}</p>
                                    </div>
                                    <div>
                                        <h3>Batch Size</h3>
                                        <p>{data?.BatchSize}</p>
                                    </div>
                                    {data?.DemoClassStatus === 1 && (
                                        <div>
                                            <h3>Meeting Url</h3>
                                            <p>
                                                {data?.StartURL &&
                                                data?.StartURL !== "NA" &&
                                                data.StartURL?.length > 0 ? (
                                                    <a
                                                        href={data?.StartURL}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={
                                                            updateActaulTime
                                                        }>
                                                        Link
                                                    </a>
                                                ) : (
                                                    "NA"
                                                )}
                                            </p>
                                        </div>
                                    )}

                                    <div>
                                        <h3>Start Date:</h3>
                                        <p>
                                            <Moment format="DD-MM-YYYY">
                                                {new Date(data?.DemoStartDate)}
                                            </Moment>
                                        </p>
                                    </div>

                                    <div>
                                        <h3>Start Time:</h3>
                                        <p>
                                            {" "}
                                            <Moment format="hh:mm a">
                                                {moment().set({
                                                    hours: Number(
                                                        String(
                                                            data?.DemoStartTime
                                                        ).split(":")[0]
                                                    ),
                                                    minutes: Number(
                                                        String(
                                                            data?.DemoStartTime
                                                        ).split(":")[1]
                                                    ),
                                                })}
                                            </Moment>
                                        </p>
                                    </div>
                                    {/*  {data?.StartURL != "NA" && (
                                        <div>
                                            <h3>Actual Start Date:</h3>
                                            <p>
                                                <Moment format="DD-MM-YYYY">
                                                    {
                                                        new Date(
                                                            data?.ActualStartDate
                                                        )
                                                    }
                                                </Moment>
                                            </p>
                                        </div>
                                    )} */}

                                    {data.DemoClassStatus != 1 && (
                                        <div>
                                            <h3>Comments</h3>
                                            <p>
                                                {" "}
                                                {data.ActionComments}{" "}
                                                <button
                                                    onClick={() => {
                                                        setCompleteData(
                                                            data.ActionComments
                                                        );
                                                        setCompleteClass(true);
                                                    }}>
                                                    <FaEdit />
                                                </button>
                                            </p>
                                        </div>
                                    )}

                                    {/*   {data.DemoClassStatus == 2 && (
                                        <div>
                                            <h3>Topics Done</h3>
                                            <p> {data.NewTopicDone}</p>
                                        </div>
                                    )} */}
                                </div>
                                <div className={DetailClasses["internal"]}>
                                    {/*  {data?.StartURL != "NA" && (
                                        <div>
                                            <h3>Actual Start Date:</h3>
                                            <p>
                                                <Moment format="DD-MM-YYYY">
                                                    {
                                                        new Date(
                                                            data?.ActualStartDate
                                                        )
                                                    }
                                                </Moment>
                                            </p>
                                        </div>
                                    )} */}
                                    {data?.ActualStartTime != "00:00:00" && (
                                        <div>
                                            <h3>Actual Start Time:</h3>
                                            <p>
                                                {" "}
                                                <Moment format="hh:mm a">
                                                    {moment().set({
                                                        hours: Number(
                                                            String(
                                                                data?.ActualStartTime
                                                            ).split(":")[0]
                                                        ),
                                                        minutes: Number(
                                                            String(
                                                                data?.ActualStartTime
                                                            ).split(":")[1]
                                                        ),
                                                    })}
                                                </Moment>
                                            </p>
                                        </div>
                                    )}
                                    {/*  {data?.ActualEndDate != "0000-00-00" && (
                                        <div>
                                            <h3>Actual End Date:</h3>
                                            <p>
                                                <Moment format="DD-MM-YYYY">
                                                    {
                                                        new Date(
                                                            data?.ActualEndDate
                                                        )
                                                    }
                                                </Moment>
                                            </p>
                                        </div>
                                    )} */}
                                    {data?.ActualEndTime != "00:00:00" && (
                                        <div>
                                            <h3>Actual End Time:</h3>
                                            <p>
                                                {" "}
                                                <Moment format="hh:mm a">
                                                    {moment().set({
                                                        hours: Number(
                                                            String(
                                                                data?.ActualEndTime
                                                            ).split(":")[0]
                                                        ),
                                                        minutes: Number(
                                                            String(
                                                                data?.ActualEndTime
                                                            ).split(":")[1]
                                                        ),
                                                    })}
                                                </Moment>
                                            </p>
                                        </div>
                                    )}
                                    {data?.ActualStartDate != "0000-00-00" &&
                                        data?.ActualStartTime != "00:00:00" &&
                                        data?.ActualEndDate != "0000-00-00" &&
                                        data?.ActualEndTime != "00:00:00" && (
                                            <div>
                                                <h3>Actual Duration:</h3>
                                                <p>
                                                    {/* {UTC_TO_IST_TIME_CONVERSION(
                                                data.Scheduled_StartTime
                                                )} */}
                                                    {getTimeDifferenceInMinutes(
                                                        data?.ActualStartDate,
                                                        data?.ActualStartTime,
                                                        data?.ActualEndDate,
                                                        data?.ActualEndTime
                                                    )}{" "}
                                                    Min
                                                </p>
                                            </div>
                                        )}

                                    {/*   {data.DemoClassStatus == 2 && (
                                        <div>
                                            <h3>Topics Done</h3>
                                            <p> {data.NewTopicDone}</p>
                                        </div>
                                    )} */}
                                </div>
                            </div>

                            <div className={DetailClasses["Table"]}>
                                <h3>Student Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>

                                            <th>Attendance</th>
                                            <th>Age</th>
                                            <th>Created By</th>
                                            <th>Lead Owner</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.ActiveStudents?.length > 0 ? (
                                            data?.ActiveStudents?.map(
                                                (item: any) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {
                                                                    item.StudentName
                                                                }
                                                            </td>

                                                            {data?.DemoClassStatus ===
                                                                1 &&
                                                            data?.BatchSize ===
                                                                "Group" ? (
                                                                <td>
                                                                    <select
                                                                        className={
                                                                            DetailClasses[
                                                                                "select-dropdown"
                                                                            ]
                                                                        }
                                                                        defaultValue={
                                                                            item.Attendance
                                                                        }
                                                                        // disabled={
                                                                        //     item.Attendance !==
                                                                        //     0
                                                                        // }
                                                                        attr-studentId={
                                                                            item.DemoStudentID
                                                                        }
                                                                        onChange={
                                                                            attendanceHandler
                                                                        }
                                                                        disabled={
                                                                            moment(
                                                                                data?.Scheduled_StartDate
                                                                            ).isAfter(
                                                                                moment(),
                                                                                "day"
                                                                            ) ||
                                                                            data.DemoClassStatus !=
                                                                                1
                                                                        }>
                                                                        <option
                                                                            value={
                                                                                0
                                                                            }
                                                                            disabled>
                                                                            Mark
                                                                            Attendance
                                                                        </option>
                                                                        <option value="1">
                                                                            Present
                                                                        </option>
                                                                        <option value="2">
                                                                            Absent{" "}
                                                                        </option>
                                                                        {/*   <option value="3">
                                                                        Absent
                                                                        but not
                                                                        charged
                                                                    </option> */}
                                                                    </select>
                                                                </td>
                                                            ) : (
                                                                <td>
                                                                    {item.Attendance ===
                                                                        0 &&
                                                                        "NA"}
                                                                    {item.Attendance ===
                                                                        1 &&
                                                                        "Present"}
                                                                    {item.Attendance ===
                                                                        2 &&
                                                                        "Absent"}
                                                                    {/*    {item.Attendance ===
                                                                    3 &&
                                                                    "Absent but not chargable"} */}
                                                                </td>
                                                            )}
                                                            <td>
                                                                {item?.Months &&
                                                                    `${item?.Months} Months `}
                                                                {item?.Age &&
                                                                    `${item?.Age} Years `}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item?.StudentCreatedBy_Name
                                                                }
                                                            </td>
                                                            <td>
                                                                {item?.ClaimedByName
                                                                    ? item?.ClaimedByName
                                                                    : ""}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={2}>
                                                    No Student found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className={DetailClasses["Table"]}>
                                <h3>Meeting Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>PLay Url</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.ZoomRestartData?.map(
                                            (item: any) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {item?.PlayURL !=
                                                                "NA" &&
                                                            item?.PlayURL !=
                                                                "" ? (
                                                                <a
                                                                    target="_blank"
                                                                    href={
                                                                        item?.PlayURL
                                                                    }>
                                                                    Play
                                                                </a>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Details;
