import React from "react";
import { IoClose } from "react-icons/io5";
import { GoCommentDiscussion } from "react-icons/go";
import DeatailsClasses from "./details.module.scss";
import Date from "./date/date";
import { extractInitials } from "../../../../Reusable/utils/utils";
import Comment from "./comment";
const Details: React.FC<any> = ({ toggleModal, setLoad, setShowModal }) => {
    let loginDetails: any = localStorage.getItem("LoginDetails");

    if (loginDetails) {
        loginDetails = JSON.parse(loginDetails);
    }
    return (
        <div className={DeatailsClasses["modal"]} onClick={toggleModal}>
            <div
                className={DeatailsClasses["modal-content"]}
                onClick={(e) => e.stopPropagation()}>
                <div className={DeatailsClasses["modal-content-header"]}>
                    <h3>Leave request Details</h3>

                    <button
                        className={DeatailsClasses["close-btn"]}
                        onClick={toggleModal}>
                        <IoClose size={22} color={"white"} />
                    </button>
                </div>
                <div className={DeatailsClasses["Container"]}>
                    <header>
                        <div className={DeatailsClasses["profile"]}>
                            <p>
                                {" "}
                                {extractInitials(
                                    `${loginDetails.FirstName} ${loginDetails.LastName}`
                                )}
                            </p>
                        </div>
                        <div className={DeatailsClasses["employee-details"]}>
                            <h5>
                                {loginDetails.FirstName} {loginDetails.LastName}
                            </h5>
                            <p>Requested by Gopala krishna on Oct 04 ,2023</p>
                        </div>
                    </header>
                    <div className={DeatailsClasses["leave-dates"]}>
                        <Date date={"2023-10-10T00:00:00.000Z"} />
                        <b>-</b>
                        <Date date={"2023-10-10T00:00:00.000Z"} />
                        <p>
                            3 Days of Casual Leave <br />
                            <span>Leave Starting 9 days from Now</span>
                        </p>
                    </div>
                    <div className={DeatailsClasses["Comments"]}>
                        <Comment
                            DateTime="2023-10-10T00:00:00.000Z"
                            loginDetails={loginDetails}
                            comment="Casual Leave"
                            key={"1"}
                        />
                        <Comment
                            DateTime="2023-10-10T00:00:00.000Z"
                            loginDetails={loginDetails}
                            comment="Test"
                            key={"1"}
                        />
                        <Comment
                            DateTime="2023-10-10T00:00:00.000Z"
                            loginDetails={loginDetails}
                            comment="Test"
                            key={"1"}
                        />
                    </div>
                    <div className={DeatailsClasses["add-comment"]}>
                        <input type="text" placeholder="Add Comment" />
                        <button>
                            <GoCommentDiscussion color="#777777" size={25} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;
